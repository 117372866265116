import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";

@Component({
  selector: 'app-modal-frequencia',
  templateUrl: './modal-frequencia.component.html',
  styleUrls: ['./modal-frequencia.component.css']
})
export class ModalFrequenciaComponent implements OnInit {

  ativo: any;

  funcionario_uuid: any;
  data_filtrada: any;

  diario_freq: any = {};
  currentUser: any = {};

  setor: any = {};
  turno: any = {};
  gerente: any = {};
  listaSetores: any = [];
  listaTurnos: any = [];
  listaGerentes: any = [];
  loading = false;
  visualizando: any = false;
  edita: any = false;

  mostraImgPonto: boolean = false;
  urlIMGpresenca: any;
  dataHoraPresenca: any;

  openMap: boolean = false;
  localizacao: any = '';
  lat: number = 51.678418;
  long: number = 7.809007;
  srcImgPerfilDefault = '../../../../assets/img/img_perfil_default.jpg';

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
    public dialogRef: MatDialogRef<ModalFrequenciaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

      this.funcionario_uuid = data.funcionario_uuid;
      this.data_filtrada = data.data_filtrada;

    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterDiarioFrequencia();
    this.obterTurnos();
  }

  fechaModal() {
    this.dialogRef.close();
  }

  obterDiarioFrequencia() {
    this.loading = true;
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    this.apiService.Get("DiarioFrequencias/Funcionarios/" + this.funcionario_uuid + "?data=" + this.data_filtrada).then(
      result => {
        let objDiarioFreq = result;
        this.diario_freq = result;
        if (!this.diario_freq.foto_perfil){
          this.obterFotoPerfil(this.funcionario_uuid).then(
            result => {
              this.diario_freq.link_perfil = result['foto_perfil_link'];
              if (result['foto_perfil_link']) {
                if (!(this.diario_freq.link_perfil.includes(".jpg")
                  && this.diario_freq.link_perfil.includes(".png"))){
                    this.diario_freq.link_perfil = this.srcImgPerfilDefault;
                  }
              }
              else {
                this.diario_freq.link_perfil = this.srcImgPerfilDefault;
              }
              for (let escala of this.diario_freq.escalas) {
                escala.data_hora = escala.data.split('T',2)[0]+'T'+escala.hora
              }
              if (this.diario_freq.pontos.length > 0) {
                for (let ponto of this.diario_freq.pontos){
                  ponto.presenca_em = ponto.data.split('T')[0]+'T'+ponto.hora
                }
              }
              if (this.diario_freq.pontos_nao_associados.length > 0) {
                for (let ponto of this.diario_freq.pontos_nao_associados) {
                  ponto.presenca_em = ponto.data.split('T')[0] + 'T' + ponto.hora
                }
              }
              this.loading = false;
              Swal.close()
            },
            error => {
              Swal.fire('Oops', error, 'error')
            }
          )
        }
        else {
          for (let escala of this.diario_freq.escalas) {
            escala.data_hora = escala.data.split('T',2)[0]+'T'+escala.hora
          }
          if (this.diario_freq.pontos.length > 0) {
            for (let ponto of this.diario_freq.pontos){
              ponto.presenca_em = ponto.data.split('T')[0]+'T'+ponto.hora
            }
          }
          if (this.diario_freq.pontos_nao_associados.length > 0) {
            for (let ponto of this.diario_freq.pontos_nao_associados) {
              ponto.presenca_em = ponto.data.split('T')[0] + 'T' + ponto.hora
            }
          }
          this.loading = false;
          Swal.close()
        }
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterFotoPerfil = (funcionario:any) => {
    return new Promise((resolve, reject) => {
      this.apiService.Get(`Funcionarios/${funcionario}/Perfil`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )}
    )
  }

  obterTurnos() {
    this.loading = true;
    this.apiService.Get("Turno?ativo=true").then(
      result => {
        this.listaTurnos = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  exibeIMGPonto = (ponto) => {

    if (this.dataHoraPresenca) {
      this.dataHoraPresenca = '';
    }
    this.urlIMGpresenca = ponto.registro
    this.dataHoraPresenca = ponto.hora

    if (this.mostraImgPonto === true) {
      this.mostraImgPonto = false;
      this.openMap = false;
    }
    setTimeout(() => {
      this.mostraImgPonto = true;
      this.openMap = false;
    },100);
      
  }

  exibeLocalizacao = (lat:any, long:any) => {
    this.lat = lat;
    this.long = long;
    if (!this.openMap){
      this.openMap = true;
      this.mostraImgPonto = false;
    }
    setTimeout(() => {
      this.openMap = true;
      this.mostraImgPonto = false;
    },100);
  }

  fecharVisualizar = () => {
      this.openMap = false;
      this.mostraImgPonto = false;
      this.dataHoraPresenca = false;
  }

}
