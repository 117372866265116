import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { Global } from '../../global';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';


@Component({
  selector: 'app-alteracao-escala',
  templateUrl: './alteracao-escala.component.html',
  styleUrls: ['./alteracao-escala.component.css']
})
export class AlteracaoEscalaComponent implements OnInit {

  novoAlteracao: boolean = false;
  alteracao: any = {};
  alteracaoStatus: boolean = true;
  filtro_historico: any = {};
  loading = false;
  visualizando: any = false;
  listaAlteracoes: any = [];

  currentUser = {};

  //partes de testes de funcionários
  cabecalho: any = {};
  listaPaginas: any = [];
  indice_selecionado: any = 0;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }


  
  ngOnInit() {
    this.filtro_historico.mes = (new Date()).getMonth() + 1;
    this.filtro_historico.ano = (new Date()).getFullYear();
    this.buscarHistorico()
  }

  valida(alteracao) {
    if (alteracao.ativo == null) {
      alteracao.ativo = false;
    }
    if (alteracao.nome == null || alteracao.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome!'
      });
      return false;
    }
    return true;
  }

  obterAlteracoes() {
    this.loading = true;
    this.apiService.Get("Alteracoes").then(
      result => {
        this.listaAlteracoes = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
    this.listaAlteracoes = [];
    this.listaAlteracoes.push({ funcionario_nome: 'Izabely', funcionario_sobrenome: 'Furtado', setor: 'sonserina', unidade: 'hogwarts', motivo: 'sai pra matar um', ativo: true });
    this.listaAlteracoes.push({ funcionario_nome: 'Ana Beatriz', funcionario_sobrenome: 'Scarpino', setor: 'lufa lufa', unidade: 'hogwarts', motivo: 'meu cachorro passou mal', ativo: true });
    this.listaAlteracoes.push({ funcionario_nome: 'Bruno', funcionario_sobrenome: 'Cezani', setor: 'corvinal', unidade: 'hogwarts', motivo: 'estava em uma conferencia', ativo: true });
    this.listaAlteracoes.push({ funcionario_nome: 'Carlos', funcionario_sobrenome: 'Magno', setor: 'corvinal', unidade: 'hogwarts', motivo: 'fui DJ no tomorrowland', ativo: true });
    this.listaAlteracoes.push({ funcionario_nome: 'Emanuelle', funcionario_sobrenome: 'Tacla', setor: 'corvinal', unidade: 'hogwarts', motivo: 'morri de rir', ativo: true });
    this.listaAlteracoes.push({ funcionario_nome: 'Fernando', funcionario_sobrenome: 'Ewald', setor: 'grifinória', unidade: 'hogwarts', motivo: 'fui salvar o mundo', ativo: true });
  }

  rejeitar(usuario) {
    this.loading = true;
    this.apiService.desativar("Alteracoes", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterAlteracoes();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  aprovar(usuario) {
    this.loading = true;
    this.apiService.ativar("Alteracoes", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterAlteracoes();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  submit() {
    if (this.valida(this.alteracao)) {
      this.loading = true;
      this.apiService.Put("Alteracoes", this.alteracao).then(
        result => {
          this.loading = false;
          Swal.fire({
            type: 'success',
            title: 'Sucesso!',
            text: 'Alteracao salvo com sucesso!'
          });
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          else {
            this.loading = false;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
          }
        }
      );
    }
    else {
      return;
    }
  }


  dataFormat(data) {
    return this.global.dateFormater(data);
  }

  buscarHistorico() {
    this.loading = true;
    this.apiService.Get("AlteracaoEscala?mes=" + this.filtro_historico.mes + "&ano=" + this.filtro_historico.ano).then(
      result => {
        this.listaAlteracoes = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }


  aceitar(alteracao) {
    this.loading = true;
    alteracao.status = '2';
    this.apiService.Put("AlteracaoEscala", alteracao).then(
      result => {
        this.loading = false;
        this.buscarHistorico();
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  finalizaJustificativa(alteracao) {
    this.loading = true;
    if (alteracao.justificativa == null || alteracao.justificativa == "") {
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira uma justificativa!'
      });
      return;
    }
    alteracao.status = '3';
    this.apiService.Put("AlteracaoEscala", alteracao).then(
      result => {
        this.loading = false;
        alteracao.justificado = false;
        this.buscarHistorico();
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
    
  }


}
