import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Event as NavigationEvent } from "@angular/router";
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-template-escala',
  templateUrl: './template-escala.component.html',
  styleUrls: ['./template-escala.component.css']
})
export class TemplateEscalaComponent implements OnInit {

  novoTemplate: boolean = false;
  loading = false;
  edicaoTemplate: boolean = false;
  listaTemplates: any = [];
  listaTemplateMontada: any = [];
  currentUser = {};
  template: any = {};
  listaTemplateEscala: any = {}

  diasSemana = ["DOMINGO", "SEGUNDA", "TERÇA", "QUARTA", "QUINTA", "SEXTA", "SÁBADO"];

  criarHorarioVazio = (dia_semana, index) => ({
    dia_semana: this.diasSemana.indexOf(dia_semana),
    semana: dia_semana,
    uuid: null,
    ordem: index,
    hora_inicial: "",
    hora_final: "",
    ativo: false
  });

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterListaTemplate();
  }

  obterListaTemplate() {
      this.loading = true;
      this.apiService.Get("TemplateEscala").then(
        result => {
          this.listaTemplates = result;
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  editarTemplate(template) {
    this.novoTemplate = true;
    this.edicaoTemplate = true;
    this.obterTemplate(template);
  }

  obterTemplate(escala) {
    this.loading = true;
    this.apiService.GetOne("TemplateEscala", escala.uuid).then(
      result => {
        let templateOriginal: any = result
        this.listaTemplateEscala = templateOriginal;

        this.formatarTemplateTabela(result);
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  formatarTemplateTabela(templateOriginal) {
    templateOriginal.template_escala_dia.forEach((item) => {
      item.template_escala_horario.sort((a, b) => {
        return a.ordem - b.ordem;
      })
    });

    let horariosTabela = this.gerarSemanas(templateOriginal);

    this.listaTemplateMontada = this.formatarDadosabelaFinal(horariosTabela)
  }

  criarTemplate() {
    this.listaTemplateMontada = [];
    this.listaTemplateEscala = { template_escala_dia: [] };

    for (let i = 0; i < 3; i++) {
      this.listaTemplateMontada[i] = { horarios: [] };
      for (let j = 0; j < this.diasSemana.length; j++) {
        this.listaTemplateMontada[i].horarios.push({
          "dia_semana": j,
          "ordem": i+1,
          "hora_inicial": "",
          "hora_final": "",
          "ativo": true,
        })
      }
    }
    this.novoTemplate = true;
  }

  formatarDadosabelaFinal(jsonFormatoArray) {
    let jsonTabela = [];
    jsonFormatoArray.forEach(() => {
      jsonTabela.push({horarios: [] })
    })
    jsonFormatoArray.forEach((item, index) => {
      item.forEach((dado, i) => {
        jsonTabela[index].horarios[i] = dado;
        jsonTabela[index].horarios[i].ordem = index+1;
      })
    });

    return jsonTabela;
  }

  voltarTemplate() {
    this.novoTemplate = false;
    this.edicaoTemplate = false;
    this.listaTemplateMontada = [];
    this.listaTemplateEscala = [];
    this.obterListaTemplate();
  }

  adicionarEscalaHora() {
    let linha = { "horarios": [] }
    for (let i = 0; i < this.diasSemana.length; i++) {
      linha.horarios.push({
        "dia_semana": i,
        "ordem": this.listaTemplateMontada.length + 1,
        "hora_inicial": "",
        "hora_final": "",
        "ativo": true
      })
    }

    this.listaTemplateMontada.push(linha)
  }

  excluirLinhaEscala(linha, index) {
    if(index > -1){
      this.listaTemplateMontada.splice(index,1);
    }
  }

  salvarTemplateEscala() {
    if(this.listaTemplateEscala.nome == null || this.listaTemplateEscala.nome == ''){
      Swal.fire('Erro!', 'Informe o nome do template', 'error');
      return
    }
    
    if(this.listaTemplateEscala.sigla == null || this.listaTemplateEscala.sigla == ''){
      Swal.fire('Erro!', 'Informe a sigla para o template', 'error');
      return
    }

    let semana = [
      { id: 0, dia: 'DOMINGO', horarios: []},
      { id: 1, dia: 'SEGUNDA', horarios: []},
      { id: 2, dia: 'TERCA', horarios: []},
      { id: 3, dia: 'QUARTA', horarios: []},
      { id: 4, dia: 'QUINTA', horarios: []},
      { id: 5, dia: 'SEXTA', horarios: []},
      { id: 6, dia: 'SABADO', horarios: []}
    ]

    this.listaTemplateMontada.forEach((dado, index) => {
      for (let i = 0; i < this.diasSemana.length; i++) {
        if (((dado.horarios.filter(x => x.dia_semana == i))[0] !== undefined)
          && (dado.horarios.filter(x => x.dia_semana == i))[0].hora_inicial != ""
          && (dado.horarios.filter(x => x.dia_semana == i))[0].hora_final != "") {
            semana[i].horarios.push((dado.horarios.filter(x => x.dia_semana == i))[0])
        }
      }
    });

    this.diasSemana.forEach((item, index) => {
      if (semana[index].horarios.length > 0) {
        if (this.listaTemplateEscala.template_escala_dia != undefined && this.listaTemplateEscala.template_escala_dia.filter(x => x.dia_semana == index).length > 0) {
          this.listaTemplateEscala.template_escala_dia.map((dado) => {
            if (dado.dia_semana == index) {
              dado.template_escala_horario = semana[index].horarios;
            }
          })
        } else {
          this.listaTemplateEscala.template_escala_dia.push({
            "dia_semana": index,
            "ativo": true,
            "template_escala_horario": semana[index].horarios
          });
        }
      } else {
        if (this.listaTemplateEscala.template_escala_dia.filter(x => x.dia_semana == index).length > 0) {
          this.listaTemplateEscala.template_escala_dia.map((dado) => {
            if (dado.dia_semana == index) {
              dado.template_escala_horario = [];
            }
          })
        }
      }
    });

    this.loading = true;
    
    if (this.edicaoTemplate) {
      this.apiService.Put("TemplateEscala", this.listaTemplateEscala)
        .then(result => {
          this.loading = false;
          Swal.fire({
            title: 'Sucesso',
            text: "Template salvo com sucesso!",
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
          }).then(result => {
            this.voltarTemplate();
          });
        }).catch(err => {
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            this.loading = false;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
          }
        });
    } else {
      this.apiService.Post("TemplateEscala", this.listaTemplateEscala)
        .then(result => {
          this.loading = false;
          Swal.fire({
            title: 'Sucesso',
            text: "Template salvo com sucesso!",
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
          }).then(result => {
            this.voltarTemplate();
          });
        }).catch(err => {
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            this.loading = false;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
          }
        });
    }
  }

  onlyNumber = (e) => {
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105) || charCode == 8 || charCode == 9) {      
        return true;
    }
    return false;
  }

  gerarSemanas = (originalJson) => {

    const semanas = [];
    const diasPorSemana = {}; // Para manter controle dos dias já preenchidos por semana
  
    // Inicializa as semanas com arrays vazios para cada índice
    for (let i = 0; i < 7; i++) {
      diasPorSemana[i] = [];
    }
  
    originalJson.template_escala_dia.forEach((dia) => {
      const diaSemana = dia.dia_semana;
      
      dia.template_escala_horario.forEach((horario, indice) => {
        if (!semanas[indice]) {
          // Cria uma nova semana se não existir
          semanas[indice] = this.diasSemana.map(this.criarHorarioVazio);
        }
        
        semanas[indice][diaSemana] = {
          dia_semana: diaSemana,
          uuid: horario.uuid,
          ordem: horario.ordem,
          hora_inicial: horario.hora_inicial,
          hora_final: horario.hora_final,
          ativo: true
        };
      });
    });
  
    return semanas;
  };

  desativarTemplate(template) {
    this.loading = true;
    this.apiService.desativar("TemplateEscala", template.uuid).then(
      result => {
        this.obterListaTemplate();
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  };

  ativarTemplate(template) {
    this.loading = true;
    this.apiService.ativar("TemplateEscala", template.uuid).then(
      result => {
        this.obterListaTemplate();
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  };
  
}
