import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalDetalheIndicadorComponent } from '../../components/modal-detalhe-indicador/modal-detalhe-indicador.component'
import { ModalDetalheIndicadorPontoComponent } from '../../components/modal-detalhe-indicador-ponto/modal-detalhe-indicador-ponto.component'

import { ApiService } from '../../services/api.service';
import { Global } from '../../global';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

declare var $: any;

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexStroke,
  ApexFill,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexXAxis,
  ApexTooltip,
  ApexTitleSubtitle
} from "ng-apexcharts";

export type ChartPieOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  dataLabels: ApexDataLabels;
};

export type ChartBarOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  title: ApexTitleSubtitle
};

var colors = [
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#00D9E9",
  "#FF66C3"
];

@Component({
  selector: 'app-dashboard-registros',
  templateUrl: './dashboard-registros.component.html',
  styleUrls: ['./dashboard-registros.component.css']
})

export class DashboardRegistrosComponent implements OnInit {

  loading = false;

  filtro: any = [];
  hoje: any = '';

  listaUnidades: any = [];
  listaSetores: any = [];

  gotCharts: boolean = false;

  pieData: any = [];
  columnData: any = [];
  tableData: any = [];

  noColumnData: boolean = false;
  noPieData: boolean = false;
  noTableData: boolean = false;

  //paginação da tabela
  indice_selecionado: number = 1;
  cabecalho: any = {};
  listaPaginas: any = [];
  pesquisa: any = '';
  showPaginas: boolean = false;
  indice = 1;

  //indicadores
  filtroIndicadores: any = [];

  listaUnidadesIndicadores: any = [];
  listaSetoresIndicadores: any = [];

  dataIndicadores: any = {};
  dadosTabelaIndicadores: any = [];
  possuiIndicadores: boolean = false;
  possuiTabelaIndicadores: boolean = false;

  //paginação da tabela Indicadores
  indice_selecionadoIndicadores: number = 1;
  cabecalhoIndicadores: any = {};
  listaPaginasIndicadores: any = [];
  pesquisaIndicadores: any = '';
  showPaginasIndicadores: boolean = false;
  indiceIndicadores = 1;

  highlightData: any = {};

  dadosFiltroIndicadores: any = {};

  @ViewChild("chart", {static: false}) pie: ChartComponent;
  public chartPieOptions: Partial<ChartPieOptions>;
  @ViewChild("chart", {static: false}) bar: ChartComponent;
  public chartBarOptions: Partial<ChartBarOptions>;


  // Indicadores 2
  indicadoresPonto: boolean = false;
  filtroIndicadoresPonto: any = []
  listaUnidadesIndicadoresPonto: any = []
  listaSetoresIndicadoresPonto: any = []
  dadosIndicadoresPonto: any = []

  constructor(
    private apiService: ApiService,
    public global: Global,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.defineToday();
    this.getUnidades();
    this.getUnidadesIndicadores();
  }

  defineToday = () =>{
    var today = new Date();
    this.hoje = 
    (('0'+today.getDate().toString()).slice(-2))+'/'+
    ((('0'+(today.getMonth()+1)).toString()).slice(-2))+'/'+today.getFullYear();
    this.filtro.data_inicial = this.hoje;
    this.filtro.data_final = this.hoje;
  }

  getUnidades = () => {
    this.loading = true;
    this.filtro.unidade = '';
    this.apiService.Get(`Unidades?ativo=true&gerente=true`).then(result =>{
      this.listaUnidades = result;
      this.listaUnidadesIndicadoresPonto = result;
      this.listaUnidades.unshift({
        "uuid": "0",
        "nome": "TODAS",
        "ativo": true,
      })
      this.listaUnidadesIndicadoresPonto.unshift({
        "uuid": "0",
        "nome": "TODAS",
        "ativo": true,
      })
      this.loading = false;
    })
  }

  getSetores = (uuid) => {
    this.loading = true;
    this.filtro.setor = '';
    if (uuid == 0){
      this.listaSetores = [];
      this.listaSetores.push({
        "uuid": "0",
        "nome": "TODOS",
        "ativo": true
      });
      this.loading = false;
      return
    }
    this.apiService.Get(`Unidades/${uuid}/Setores?ativo=true`).then(result =>{
      this.listaSetores = result;
      this.listaSetores.unshift({
        "uuid": "0",
        "nome": "TODOS",
        "ativo": true,
      })
      this.loading = false;
    })
  }

  dateMask = (data) => {
    var id = data.srcElement.id;
    var input = this.filtro[id];
    this.filtro[id] = input.replace(/\D/g, "");
    this.filtro[id] = this.filtro[id].replace(/(\d{2})(\d)/, "$1/$2");
    this.filtro[id] = this.filtro[id].replace(/(\d{2})(\d{1})/, "$1/$2");
    this.filtro[id] = this.filtro[id].replace(/(\d{2})(\d{4})$/, "$1/$2");
  }
  
  getData = () => {
    this.loading = true;
    this.gotCharts = false;
    if(this.filtro.unidade == '' || this.filtro.unidade == null){
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma unidade para continuar'
      })
      this.loading = false;
      return;
    }
    if(this.filtro.data_inicial){      
      if(this.filtro.data_inicial.split("/")[2] >= this.hoje.split("/")[2] &&
         this.filtro.data_inicial.split("/")[1] >= this.hoje.split("/")[1] &&
         this.filtro.data_inicial.split("/")[0] > this.hoje.split("/")[0]){
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'A data inicial não pode ser uma data no futuro.'
        })
        this.loading = false;
        return;
      }
    }
    if(this.filtro.data_final){
      if(this.filtro.data_final.split("/")[2] >= this.hoje.split("/")[2] &&
         this.filtro.data_final.split("/")[1] >= this.hoje.split("/")[1] &&
         this.filtro.data_final.split("/")[0] > this.hoje.split("/")[0]){
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'A data final não pode ser uma data no futuro.'
        })
        this.loading = false;
        return;
      }
    }
    if(this.filtro.data_inicial == '' || this.filtro.data_inicial == null){
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma data inicial válida para continuar'
      })
      this.loading = false;
      return;
    }
    if(this.filtro.data_final == '' || this.filtro.data_final == null){
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma data final válida para continuar'
      })
      this.loading = false;
      return;
    }
    if(this.filtro.setor == 0){
      this.filtro.setor = '';
    }
    if(this.filtro.unidade == 0){
      this.filtro.unidade = '';
    }
    var Obj = {
      setor: this.filtro.setor,
      unidade: this.filtro.unidade,
      data_inicio: this.filtro.data_inicial.split("/")[2]+"-"+this.filtro.data_inicial.split("/")[1]+"-"+this.filtro.data_inicial.split("/")[0],
      data_fim: this.filtro.data_final.split("/")[2]+"-"+this.filtro.data_final.split("/")[1]+"-"+this.filtro.data_final.split("/")[0]
    }
    this.generateCharts(Obj);
  }

  generateCharts = (Obj) => {
    var txt = `inicio=${Obj.data_inicio}&final=${Obj.data_fim}&unidade_uuid=${Obj.unidade}&setor_uuid=${Obj.setor}`;
    this.getPieData(txt)
     .then(result => this.getTableData(txt, this.indice_selecionado))
      .then(result => this.getBarData(txt))
      .then(result => {
        this.loading = false;
        this.gotCharts = true;
      }).catch((error)=>{
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: `Erro ao gerar ${error}`
        })
        this.loading = false;
    });
  }

  getBarData = async(txt) =>{
    return new Promise((resolve ,reject)=>{
    this.apiService.Get(`Dashboard/GraficoColunasCadastroFuncionario?${txt}`).then(result =>{
      this.columnData = result;      
      if (this.columnData){
        this.noColumnData = false;
        var series = [];
        var categories = [];
        series = this.columnData.series;
        categories = this.columnData.categorias;
        for (var i = 0; i < series.length; i++){
          delete Object.assign(series[i], {['name']: series[i]['legenda'] })['legenda'];
          delete Object.assign(series[i], {['data']: series[i]['quantidade'] })['quantidade'];
        }
        for (var j = 0; j < categories.length; j++){
          categories[j] = categories[j].split("T")[0];
          categories[j] = categories[j].split("-")[2]+"/"+categories[j].split("-")[1]+"/"+categories[j].split("-")[0]
        }        
        this.chartBarOptions = {
          series,
            chart: {
              type: "bar",
              height: 350
            },
            xaxis: {
              categories:categories,
            },
            dataLabels: {
              enabled: true
            },
          };
        }
        resolve('colunas ok');
      }).catch((error)=>{
        this.noColumnData = true;
        console.log(error)
        reject('o gráfico de colunas')
      });
    })
  }

  getPieData = async(txt) =>{
    return new Promise((resolve ,reject)=>{
    this.apiService.Get(`Dashboard/GraficoPizzaRegistroPonto?${txt}`).then(result =>{
      this.pieData = result;
      if(this.pieData.length == 0){
        this.noPieData = true;
      } else this.noPieData = false;
      if (this.pieData){
        var data = [];
        var label = [];
        for (var i = 0; i < this.pieData.length; i++){
          data[i] = this.pieData[i].quantidade;
          label[i] = (this.pieData[i].legenda).toString();
        }
        this.chartPieOptions = {
          series: data,
          labels: label,
          chart: {
            type: "pie",
            height: 350
          }
        };
      }
      resolve('pizza ok');
    }).catch((error)=>{
      reject('o gráfico de pizza')
    });
  })
    
  }

  getTableData = async(txt,indice_selecionado) =>{
    return new Promise((resolve ,reject)=>{
      this.apiService.Get(`Dashboard/TabelaRegistroPonto?${txt}&pagina=${indice_selecionado}`).then(result =>{
        this.tableData = result['conteudo'];
        this.cabecalho.total_paginas = result['total_paginas'];
        if(this.cabecalho.total_paginas > 1){
          this.showPaginas = true;
        }
        if(this.tableData.length == 0){
          this.noTableData = true;
        } else this.noTableData = false;
        for (let unidade of this.listaUnidades){
          if(unidade.uuid === this.filtro.unidade){
            for (let i of this.tableData){
              i.unidade = unidade.nome;
              i.data_registro = i.data_registro.split("-")[0]+"/"+i.data_registro.split("-")[1]+"/"+i.data_registro.split("-")[2];
              i.hora_registro = i.hora_registro.split(":")[0]+":"+i.hora_registro.split(":")[1];
            }
          }
        }
        resolve('tabela ok');
      }).catch((error)=>{
        console.log(error)
        reject('a tabela de dados')
      });
    })
  }

  //paginação  
  primeira(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  anterior(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  atual(indice, pesquisa) {
    if (this.indice_selecionado != indice) {
      this.indice_selecionado = indice;
      this.outraPagina(this.indice_selecionado);
    } else {
      this.indice_selecionado = indice;
      this.outraPagina(this.indice_selecionado);
    }
  }

  proxima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  ultima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
      this.outraPagina(this.indice_selecionado);
    }
  }

  outraPagina = (pagina) => {
    this.loading = true;
    this.gotCharts = false;

    var Obj = {
      setor: this.filtro.setor,
      unidade: this.filtro.unidade,
      data_inicio: this.filtro.data_inicial.split("/")[2]+"-"+this.filtro.data_inicial.split("/")[1]+"-"+this.filtro.data_inicial.split("/")[0],
      data_fim: this.filtro.data_final.split("/")[2]+"-"+this.filtro.data_final.split("/")[1]+"-"+this.filtro.data_final.split("/")[0]
    }
    var txt = `inicio=${Obj.data_inicio}&final=${Obj.data_fim}&unidade_uuid=${Obj.unidade}&setor_uuid=${Obj.setor}`;
    
    this.getTableData(txt, this.indice_selecionado)
      .then(result => {
        this.loading = false;
        this.gotCharts = true;
      }).catch((error)=>{
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: `Erro ao gerar ${error}`
        })
        this.loading = false;
    });
  }
  //fim paginação

  // Indicadores
  getIndicators = () => {
    this.loading = true;
    if (this.filtroIndicadores.data_busca == '' || this.filtroIndicadores.data_busca == null){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'É preciso informar uma data'
      })
      return
    }
    var obj = {
      unidade_uuid: this.filtroIndicadores.unidade,
      setor_uuid: this.filtroIndicadores.setor,
      data: this.filtroIndicadores.data_busca.split("/")[2]+"-"+this.filtroIndicadores.data_busca.split("/")[1]+"-"+this.filtroIndicadores.data_busca.split("/")[0]
    }   
    this.dadosFiltroIndicadores = obj 
    this.validaDadosFiltroIndicadores(obj)
    .then(res =>  this.obterDadosIndicadores(obj))
    .then(result => this.obterTabelaIndicadores(obj, 1))
    .then(result => {        
        this.loading = false;
      }).catch((error)=>{
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: `${error}`
        })
        this.loading = false;
    });    
  }

  validaDadosFiltroIndicadores = (filtroIndicadores) => {
    return new Promise<any>((resolve, reject) => {
      if(filtroIndicadores.unidade_uuid == '0' || filtroIndicadores.unidade_uuid == undefined){
        filtroIndicadores.unidade_uuid = '';
      }
      if(filtroIndicadores.setor_uuid == '0' || filtroIndicadores.setor_uuid == undefined){
        filtroIndicadores.setor_uuid = '';
      } 
      if (filtroIndicadores.data.split("-")[0] > this.hoje.split("/")[2]){
        reject('O ano informado não pode ser superior ao ano atual')
      }
      if (filtroIndicadores.data.split("-")[1] > 12){
        reject('O mês informado é inválido')
      }
      if (filtroIndicadores.data.split("-")[0] == this.hoje.split("/")[2] &&
          filtroIndicadores.data.split("-")[1] > this.hoje.split("/")[1]){
        reject('O mês informado não pode ser superior ao mês atual')
      }
      if (filtroIndicadores.data.split("-")[2] > 31){
        reject('O dia informado é inválido')
      }
      if (filtroIndicadores.data.split("-")[0] == this.hoje.split("/")[2] &&
          filtroIndicadores.data.split("-")[1] == this.hoje.split("/")[1] && 
          filtroIndicadores.data.split("-")[2] > this.hoje.split("/")[0]){
        reject('O dia informado não pode ser superior ao dia atual')
      }
      else (
        resolve(filtroIndicadores)
      )
    })
  }

  getUnidadesIndicadores = () => {
    this.filtroIndicadores['data_busca'] = this.hoje
    this.loading = true;
    this.filtro.unidade = '';
    this.apiService.Get(`Unidades?ativo=true&gerente=true`).then(result =>{
      this.listaUnidadesIndicadores = result;
      this.listaUnidadesIndicadores.unshift({
        "uuid": "0",
        "nome": "TODAS",
        "ativo": true,
      })
      this.loading = false;
    })
  }

  getSetoresIndicadores = (uuid) => {
    this.loading = true;
    this.filtro.setor = '';
    if (uuid == 0){
      this.listaSetoresIndicadores = [];
      this.listaSetoresIndicadores.push({
        "uuid": "0",
        "nome": "TODOS",
        "ativo": true
      });
      this.loading = false;
      return
    }
    this.apiService.Get(`Unidades/${uuid}/Setores?ativo=true`).then(result =>{
      this.listaSetoresIndicadores = result;
      this.listaSetoresIndicadores.unshift({
        "uuid": "0",
        "nome": "TODOS",
        "ativo": true,
      })
      this.loading = false;
    })
  }

  dateMaskIndicadores = (data) => {
    var id = data.srcElement.id;
    var input = this.filtroIndicadores[id];
    this.filtroIndicadores[id] = input.replace(/\D/g, "");
    this.filtroIndicadores[id] = this.filtroIndicadores[id].replace(/(\d{2})(\d)/, "$1/$2");
    this.filtroIndicadores[id] = this.filtroIndicadores[id].replace(/(\d{2})(\d{1})/, "$1/$2");
    this.filtroIndicadores[id] = this.filtroIndicadores[id].replace(/(\d{2})(\d{4})$/, "$1/$2");
  }

  setColor = (val) => {
    return colors[val]
  }

  obterDadosIndicadores = (obj) =>{
    return new Promise((res, rej) => {
      this.apiService.Get(`Indicadores?unidade_uuid=${obj.unidade_uuid}&setor_uuid=${obj.setor_uuid}&data=${obj.data}`)
        .then(result => {
          this.dataIndicadores = result;
          this.possuiIndicadores = true;
          res('ok')
        }).catch((error)=>{
          this.possuiIndicadores = false;
        rej(error)
      });
    })
  }

  obterTabelaIndicadores = (obj, pagina) => {
    return new Promise((res, rej) => {
      this.apiService.Get(`Indicadores/RegistroPontosDiarios?unidade_uuid=${obj.unidade_uuid}&setor_uuid=${obj.setor_uuid}&data=${obj.data}&pagina=${pagina}`)
      .then(result => {
        this.dadosTabelaIndicadores = result['conteudo'];
        this.cabecalhoIndicadores.total_paginas = result['total_paginas'];
        for (let dado of this.dadosTabelaIndicadores){
          dado.data_hora_registro = dado.data_registro.split("-").join("/")+" "+dado.hora_registro
        }
        for (let item of this.dadosTabelaIndicadores){
          this.global.aplicaMascaraCPF(item.cpf).then(result => {
            item.cpf = result;
          })
        }
        if(result['conteudo'].length > 0){
          this.possuiTabelaIndicadores = true;
        }
        if (result['total_paginas'] > 1){
          this.showPaginasIndicadores = true;
        }
        res('ok');
      }).catch((error)=>{
        this.possuiTabelaIndicadores = false;
        rej(error)
      });
    })
  }

  //paginação  Indicadores
  primeiraIndicadores(pesquisa) {
    if (this.indice_selecionadoIndicadores != 1) {
      this.indice_selecionadoIndicadores = 1;
      this.outraPaginaIndicadores(this.indice_selecionadoIndicadores);
    }
  }

  anteriorIndicadores(pesquisa) {
    if (this.indice_selecionadoIndicadores != 1) {
      this.indice_selecionadoIndicadores = this.indice_selecionadoIndicadores - 1;
      this.outraPaginaIndicadores(this.indice_selecionadoIndicadores);
    }
  }

  atualIndicadores(indice, pesquisa) {
    if (this.indice_selecionadoIndicadores != indice) {
      this.indice_selecionadoIndicadores = indice;
      this.outraPaginaIndicadores(this.indice_selecionadoIndicadores);
    } else {
      this.indice_selecionadoIndicadores = indice;
      this.outraPaginaIndicadores(this.indice_selecionadoIndicadores);
    }
  }

  proximaIndicadores(pesquisa) {
    if (this.indice_selecionadoIndicadores != this.cabecalhoIndicadores.total_paginas) {
      this.indice_selecionadoIndicadores = this.indice_selecionadoIndicadores + 1;
      this.outraPaginaIndicadores(this.indice_selecionadoIndicadores);
    }
  }

  ultimaIndicadores(pesquisa) {
    if (this.indice_selecionadoIndicadores != this.cabecalhoIndicadores.total_paginas) {
      this.indice_selecionadoIndicadores = this.cabecalhoIndicadores.total_paginas;
      this.outraPaginaIndicadores(this.indice_selecionadoIndicadores);
    }
  }

  outraPaginaIndicadores = (pagina) => {
    this.loading = true;
    var obj = {
      unidade_uuid: this.filtroIndicadores.unidade,
      setor_uuid: this.filtroIndicadores.setor,
      data: this.filtroIndicadores.data_busca.split("/")[2]+"-"+this.filtroIndicadores.data_busca.split("/")[1]+"-"+this.filtroIndicadores.data_busca.split("/")[0]
    }
    if (obj.setor_uuid == '' || obj.setor_uuid == null || obj.setor_uuid == undefined || obj.setor_uuid == "0"){
      obj.setor_uuid = '';
    }
    //var txt = `unidade_uuid=${obj.unidade_uuid}&setor_uuid=${obj.setor_uuid}&data=${obj.data}`;
    this.obterTabelaIndicadores(obj, pagina)
      .then(result => {
        this.loading = false;
      }).catch((error)=>{
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: `${error}`
        })
        this.loading = false;
    });
  }
  //fim paginação

  abrirDetalheIndicador = (val:any, title:string, filter:object) => {
    let dialogRef = this.dialog.open(ModalDetalheIndicadorComponent, {
      data: {
        indicador: val,
        titulo: title,
        filtro: filter
      }
    });
  }

  //Indicadores de ponto

  getUnidadesPonto = () => {
    this.filtroIndicadoresPonto.data_buscaIndicadoresPonto = this.hoje;
    this.loading = true;
    this.filtro.unidade = '';
    this.apiService.Get(`Unidades?ativo=true&gerente=true`).then(result =>{
      this.listaUnidadesIndicadoresPonto = result;
      this.listaUnidadesIndicadoresPonto.unshift({
        "uuid": "0",
        "nome": "TODAS",
        "ativo": true,
      })
      this.loading = false;
    })
  }

  getSetoresPonto = (uuid) => {
    this.loading = true;
    this.filtro.setor = '';
    if (uuid == 0){
      this.listaSetoresIndicadoresPonto = [];
      this.listaSetoresIndicadoresPonto.push({
        "uuid": "0",
        "nome": "TODOS",
        "ativo": true
      });
      this.loading = false;
      return
    }
    this.apiService.Get(`Unidades/${uuid}/Setores?ativo=true`).then(result =>{
      this.listaSetoresIndicadoresPonto = result;
      this.listaSetoresIndicadoresPonto.unshift({
        "uuid": "0",
        "nome": "TODOS",
        "ativo": true,
      })
      this.loading = false;
    })
  }

  dateMaskIndicadoresPonto = (data) => {
    var id = data.srcElement.id;
    var input = this.filtroIndicadoresPonto[id];
    this.filtroIndicadoresPonto[id] = input.replace(/\D/g, "");
    this.filtroIndicadoresPonto[id] = this.filtroIndicadoresPonto[id].replace(/(\d{2})(\d)/, "$1/$2");
    this.filtroIndicadoresPonto[id] = this.filtroIndicadoresPonto[id].replace(/(\d{2})(\d{1})/, "$1/$2");
    this.filtroIndicadoresPonto[id] = this.filtroIndicadoresPonto[id].replace(/(\d{2})(\d{4})$/, "$1/$2");
  }

  getIndicadoresPonto = () => {
    Swal.fire('Favor aguardes');
    Swal.showLoading()
    console.log(this.filtroIndicadoresPonto)
    var txt: string = ''
    if (this.filtroIndicadoresPonto.unidade){
      if (txt == '') {
          txt = '?unidade_uuid='+this.filtroIndicadoresPonto.unidade
      }
      else {
          txt += '&unidade_uuid='+this.filtroIndicadoresPonto.unidade
      }
    }
    if (this.filtroIndicadoresPonto.unidade == 0){
      txt = ''
    }
    if (this.filtroIndicadoresPonto.setor && this.filtroIndicadoresPonto.setor != 0){
      if (txt == '') {
          txt = '?setor_uuid='+this.filtroIndicadoresPonto.setor
      }
      else {
          txt += '&setor_uuid='+this.filtroIndicadoresPonto.setor
      }
    }
    if (this.filtroIndicadoresPonto.data_buscaIndicadoresPonto){
      if (txt != '') {
          txt += '&data='+this.filtroIndicadoresPonto.data_buscaIndicadoresPonto.split('/')[2]+'-'+this.filtroIndicadoresPonto.data_buscaIndicadoresPonto.split('/')[1]+'-'+this.filtroIndicadoresPonto.data_buscaIndicadoresPonto.split('/')[0]
      }
      else {
          txt = '?data='+this.filtroIndicadoresPonto.data_buscaIndicadoresPonto.split('/')[2]+'-'+this.filtroIndicadoresPonto.data_buscaIndicadoresPonto.split('/')[1]+'-'+this.filtroIndicadoresPonto.data_buscaIndicadoresPonto.split('/')[0]
      }
    }
    this.apiService.Get(`Indicadores/Escala${txt}`).then(
      result => {
        console.log(result)
        this.indicadoresPonto = true;
        this.dadosIndicadoresPonto = result;
        this.filtroIndicadoresPonto.setor = ''
        Swal.close()
      },
      error => {
        console.log(error)
        Swal.fire('Oops', error, 'error')
      }
    )
  }

  abrirDetalheIndicadorPonto = (val:any, title:string, filter:object) => {
    let dialogRef = this.dialog.open(ModalDetalheIndicadorPontoComponent, {
      data: {
        indicador: val,
        titulo: title,
        filtro: filter
      }
    });
  }

}
