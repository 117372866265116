import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";

@Component({
  selector: 'app-modal-aprova-troca',
  templateUrl: './modal-aprova-troca.component.html',
  styleUrls: ['./modal-aprova-troca.component.css']
})
export class ModalAprovaTrocaComponent implements OnInit {

  aprova: any = {};
  acao_selecionada: any;
  obs: any;

  currentUser: any = {};

  loading = false;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,    
    public dialogRef: MatDialogRef<ModalAprovaTrocaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.aprova = data;
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
  }

  fechaModal() {
    this.dialogRef.close();
  }

  aprovar() {
    if (this.acao_selecionada != 1) {
      this.acao_selecionada = 1;
      this.confirmar_acao();
    }
  }

  rejeitar() {
    this.acao_selecionada = 2;
  }

  voltar() {
    this.acao_selecionada = null;
    this.obs = null;
  }

  confirmar_acao() {
    this.loading = true;
    var novo_status = "";
    if (this.acao_selecionada == 1) {
      if (this.obs == 'undefined') {
        this.obs = "";
      }
      novo_status = 'APROVADO';
    }
    else if (this.acao_selecionada == 2) {
      //se ação == 3 definir q é obrigatoria a inserção do motivo
      if (this.obs == null || this.obs == "" || this.obs == 'undefined') {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'Insira um motivo para o cancelamento!'
        });
        this.loading = false;
        return;
      }
      novo_status = 'RECUSADO_GESTOR';
    }
    var obj_para_trocar = { uuid: this.aprova.uuid, observacao: this.obs, novo_status: novo_status };
    this.trocar_escala(obj_para_trocar);
  }

  trocar_escala(obj_para_trocar) {
    this.loading = true;
    var caminho = obj_para_trocar.novo_status + "";
    if (obj_para_trocar.observacao != null && obj_para_trocar.observacao != 'undefined') {
      caminho += "&observacao=" + obj_para_trocar.observacao
    }
    this.apiService.Patch("TrocaEscalas", obj_para_trocar.uuid, caminho).then(
      result => {
        Swal.fire({
          type: 'success',
          title: 'Sucesso!',
          text: 'Atualização de status de escala feito com sucesso!'
        });
        this.fechaModal();
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          this.loading = false;
          return;
        }
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
        this.loading = false;
      }
    );
    //this.loading = false;
  }

  dataFormat(data) {
    if (data == "0001-01-01T00:00:00") {
      return "--/--/----";
    }
    else {
      return this.global.dateFormater(data);
    }
  }



}
