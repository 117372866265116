import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MatDialog } from '@angular/material';

import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";

import { Global } from '../../global';

import { ModalApontamentoMassaComponent } from '../../components/modal-apontamento-massa/modal-apontamento-massa.component'

@Component({
  selector: 'app-apontamento-massa',
  templateUrl: './apontamento-massa.component.html',
  styleUrls: ['./apontamento-massa.component.css']
})
export class ApontamentoMassaComponent implements OnInit {
  loading: any = false;

  filtro: any = { unidade: 0 };

  listaUnidades: any = [];
  listaSetores: any = [];
  listaCargos: any = [];
  listaFuncionarios: any = [];
  listaMeses: any = [];
  quantidadePeriodos: any = [];

  imgSrc: any = '../../assets/img/default.jpg';

  funcionarioPesquisado: any = {};
  

  constructor(private apiService: ApiService,private router: Router, public global: Global,public dialog: MatDialog,) { }

  ngOnInit() {
    this.obterUnidades();
    this.obterMeses();
    this.filtro.ano = new Date().getFullYear();
  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get("Unidades?ativo=true&gerente=true").then(
      (result) => {
        this.listaUnidades = result;
        this.loading = false;
      },
      (err) => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: err.error.mensagem,
        });
      }
    );
  };

  obterSetores(unidade_id) {
    this.listaSetores = null;
    this.listaCargos = null;
    this.listaFuncionarios = null;
    this.filtro.setor = null;
    this.filtro.cargo = null;
    this.filtro.funcionario = null;
    if (unidade_id == 0) {
      return null;
    }
    this.loading = true;
    this.apiService
      .Get("Unidades/" + unidade_id + "/Setores?ativo=true&gerente=true")
      .then(
        (result) => {
          this.listaSetores = result;
          this.filtro.setor = 0;
          this.loading = false;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  };

  obterCargos(setor_uuid) {
    this.listaCargos = null;
    this.listaFuncionarios = null;
    this.filtro.cargo = null;
    this.filtro.funcionario = null;
    if (setor_uuid == 0) {
      return null;
    }
    this.loading = true;
    this.apiService
      .Get("Setor/" + setor_uuid + "/Cargos?ativo=true&gerente=true")
      .then(
        (result) => {
          this.listaCargos = result;
          this.loading = false;
          this.filtro.cargo = 0;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  };

  obterFuncionarios(setorUuid, cargoUuid) {
    this.listaFuncionarios = null;
    this.filtro.funcionario = null;
    this.loading = true;
    if (!cargoUuid) {
      this.loading = false;
      return
    }
    if (!this.filtro.ano || this.filtro.ano == '') {
      this.loading = false;
      Swal.fire('Erro', 'É preciso informar o ano', 'error')
      return
    }                                                 
    var txt = `setor=${setorUuid}&cargo=${cargoUuid}&ano=${this.filtro.ano}&mes=${this.filtro.mes}`
    this.apiService.Get(`Funcionarios?${txt}`)
      .then((result) => {
        this.listaFuncionarios = result;
        this.filtro.funcionario = 0;
        this.loading = false;
      },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  };

  obterMeses() {
    this.loading = true;
    this.listaMeses = [
      { id: 1, nome: "Janeiro" },
      { id: 2, nome: "Fevereiro" },
      { id: 3, nome: "Março" },
      { id: 4, nome: "Abril" },
      { id: 5, nome: "Maio" },
      { id: 6, nome: "Junho" },
      { id: 7, nome: "Julho" },
      { id: 8, nome: "Agosto" },
      { id: 9, nome: "Setembro" },
      { id: 10, nome: "Outubro" },
      { id: 11, nome: "Novembro" },
      { id: 12, nome: "Dezembro" },
    ];
    this.filtro.mes = new Date().getMonth() + 1;
  };

  pesquisarApontamentos = () => {
    this.loading = true;
    this.funcionarioPesquisado = {};
    this.valida_requisicao(this.filtro).then(
      result => {        
        var filtro: any;
        filtro = result;
        var text: any = '';


        // if (filtro.unidade == 0){
        //   text = `?pagina=${pagina}`
        // }
        // else {
        //   text = `?setor_uuid=${filtro.setor.uuid}&mes=${filtro.mes}&ano=${filtro.ano}&funcionario_uuid=${filtro.funcionario.uuid}`;
        //   if (filtro.cargo.uuid){
        //     text += `&cargo_uuid=${filtro.cargo.uuid}`;
        //   }
        //   if (filtro.funcionario){
        //     text+=`&funcionario_uuid=${filtro.funcionario.uuid}`;
        //   }
        // }

        text = `?setor_uuid=${filtro.setor.uuid}&mes=${filtro.mes}&ano=${filtro.ano}&funcionario_uuid=${filtro.funcionario.uuid}`;
        if (filtro.cargo.uuid) {
          text += `&cargo_uuid=${filtro.cargo.uuid}`;
        }
        if (filtro.funcionario) {
          text += `&funcionario_uuid=${filtro.funcionario.uuid}`;
        }

        this.apiService.Get(`Apontamento${text}`).then(
          result => {
            this.funcionarioPesquisado = result;
            
            if(this.funcionarioPesquisado.escalas.length == 0){
              this.loading = false;
              Swal.fire('Oops...', 'Funcionário sem escala!', 'error');
              return
            }

            this.funcionarioPesquisado.escalas.forEach((escala, index) => {
              if(escala.escala_intervalos.length == 0){
                for (let index = 0; index < this.funcionarioPesquisado.quantidade_intervalos; index++) {
                  escala.escala_intervalos.push({
                    data: escala.data,
                    descricao_apontamento: '',
                    escala_intervalo_uuid: '',
                    hora: '',
                    ordem: index+1,
                    tipo: '',
                    tipo_apontamento: 0
                  })
                }
              }
            });

            this.quantidadePeriodos = [];
            var quantidade_periodos = this.funcionarioPesquisado.quantidade_intervalos / 2;

           for (let index = 0; index < quantidade_periodos; index++) {
              this.quantidadePeriodos.push(`${index+1}`)
           }

           //Segundo caceçalho da tabela
           let cabecalho_dois = [];
           cabecalho_dois.push('Dia')
           for (let i = 0; i < this.funcionarioPesquisado.quantidade_intervalos/2; i++) {
            cabecalho_dois.push(`ENT ${i+1}`)
            cabecalho_dois.push(`SAI ${i+1}`)
           }
           cabecalho_dois.push('Apts.')
           cabecalho_dois.push('Atraso')
           cabecalho_dois.push('Falta')
           cabecalho_dois.push('Abono')
           cabecalho_dois.push('H. Trab')
           this.funcionarioPesquisado.cabecalho_dinamico = cabecalho_dois;
           
           if(this.funcionarioPesquisado.foto_perfil.includes('.png')){
             this.imgSrc = this.funcionarioPesquisado.foto_perfil
           } else {
             this.imgSrc = '../../assets/img/default.jpg';
           }

            this.loading = false;
          },
          error => {
            this.alertaErro(error.error.mensagem)
          }
        )
      },
      error => {
        this.alertaErro(error)
      }
    )
  };

  valida_requisicao = (filtro) => {
    return new Promise((resolve, reject) => {
      if (
        filtro.unidade == null ||
        filtro.unidade == undefined
      ) {
        reject("Insira a unidade")
        return
      }
      if (filtro.unidade != 0){
        if (
          filtro.setor == null ||
          filtro.setor == 0 ||
          filtro.setor == "0"
        ) {
          reject("Insira o setor")
          return
        }
      }
  
      if (
        filtro.ano == null ||
        filtro.ano == "" ||
        filtro.ano == 0 ||
        filtro.ano.length < 4
      ) {
        reject("Insira o ano!")
        return
      }
      if (filtro.mes == null) {
        reject("Insira o mes")
        return;
      }
      if(filtro.funcionario == null ||
        filtro.funcionario == 0 ||
        filtro.funcionario == "0"){
          reject("Selecionar o funcionário");
          return;
      }
      resolve(filtro)
    })
  }

  alertaErro = (erro) =>{
    this.loading = false;
    Swal.fire('Erro!', erro, 'error');
  }

  validarBuscaPesquisa() {
    if(Object.keys(this.funcionarioPesquisado).length > 0){
      if (this.funcionarioPesquisado.escalas.length == 0) {
        return false
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  formatarDataComBarraGlobal(data){
    if(data != null){
      var data_split = data.split('/')
      return `${data_split[2]}-${data_split[1]}-${data_split[0]}`
    }
  }

  iniciarApontamentos(funcionario, intervalo){
    if(intervalo.tipo_apontamento == 4){
      let dialogRef = this.dialog.open(ModalApontamentoMassaComponent, {
        data: {
          intervalo: intervalo,
          tipo_apontamento: intervalo.tipo_apontamento,
          unidade_uuid: this.filtro.unidade.uuid,
          setor_uuid: this.filtro.setor.uuid,
          funcionario: funcionario
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(
        result => {
          this.pesquisarApontamentos();
        }
      );
    }
    if(intervalo.tipo_apontamento == 1 || intervalo.tipo_apontamento == 2 || intervalo.tipo_apontamento == 3){
      let dialogRef = this.dialog.open(ModalApontamentoMassaComponent, {
        data: {
          intervalo: intervalo,
          tipo_apontamento: intervalo.tipo_apontamento,
          cargo_uuid: funcionario.cargo_uuid,
          unidade_uuid: this.filtro.unidade.uuid,
          setor_uuid: this.filtro.setor.uuid,
          funcionario: funcionario
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(
        result => {
          this.pesquisarApontamentos();
        }
      );
    }
  };

  adicionarApontamento(escala, funcionario){
    let dialogRef = this.dialog.open(ModalApontamentoMassaComponent, {
      data: {
        escala: escala,
        tipo_apontamento: 5,
        funcionario: {
          cargo_uuid: funcionario.cargo_uuid,
          funcionario_uuid: funcionario.funcionario_uuid
        }
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(
      result => {
        this.pesquisarApontamentos();
      }
    );
  }

  verificarFolga(escala){
    try {
      if(escala.escala_intervalos.length > 0 && escala.escala_intervalos[0].tipo_apontamento != null && escala.escala_intervalos[0].tipo_apontamento != 6 && escala.escala_intervalos[0].tipo_apontamento != 0){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      console.log(error)
    }
  }

}
