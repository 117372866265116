import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Global } from '../../global';
import { ApiService } from '../../services/api.service';
import { NgxViacepService, Endereco, CEPError } from '@brunoc/ngx-viacep';
import Swal from 'sweetalert2';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-novo-cliente',
  templateUrl: './novo-cliente.component.html',
  styleUrls: ['./novo-cliente.component.css']
})
export class NovoClienteComponent implements OnInit {

  addAdmin: boolean = false;
  revisar: boolean = false;

  perfil: any = {};
  funcionario: any = {};
  objetoCadastro: any = {}
  chaveAcesso: any;
  loading = false;
  visualizando: any = false;
  listaEstados: any = [];
  listaCidades: any = [];
  cidadeAtual: any;
  estadoAtual: any;

  currentUser: any = {};

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    public viaCEP: NgxViacepService
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/novo-cliente"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/novo-cliente"]);
    }
  }

  ngOnInit() {
    this.obterListaEstados();
    //this.obterPerfil();
  }

  //BUCANDO CEP
  pesquisacep(valor) {
    //Nova variável "cep" somente com dígitos.
    var cep = valor.replace(/\D/g, '');

    //Verifica se campo cep possui valor informado.
    if (cep != "") {

      //Expressão regular para validar o CEP.
      var validacep = /^[0-9]{8}$/;

      //Valida o formato do CEP.
      if (validacep.test(cep)) {

        //Preenche os campos com "..." enquanto consulta webservice.
        this.perfil.estado_uuid = null;
        this.perfil.municipio_uuid = null;
        this.perfil.endereco_bairro = "";
        this.perfil.endereco_rua = "";

        // this.viaCEP.buscarPorCep(cep).then((endereco: Endereco) => {
        //   // Endereço retornado :)
        //   this.meu_callback(endereco);
        // }).catch((error: ErroCep) => {
        //   this.listaCidades = [];
        //   // Alguma coisa deu errado :/
        // });
        this.viaCEP
          .buscarPorCep(cep)
          .pipe(
            catchError((error: CEPError) => {
              return this.listaCidades = [];
            })
          )
          .subscribe((endereco: Endereco) => {
            this.meu_callback(endereco);
          });
      }
      else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'CEP Inválido!'
        });
      }
    }
  }

  meu_callback(conteudo) {

    if (!("erro" in conteudo)) {
      //Atualiza os campos com os valores.
      this.perfil.estado_uuid = this.getIdEstado(conteudo.uf);
      this.obterListaCidades2(this.perfil.estado_uuid, conteudo.localidade);

      this.perfil.endereco_bairro = conteudo.bairro;
      this.perfil.endereco_rua = conteudo.logradouro;
    }
    else {
      //CEP não Encontrado.
      //this.limpa_formulário_cep();
      //alert("CEP não encontrado.");
    }
  }

  obterListaCidades(estado_id) {
    if (estado_id != null) {
      this.loading = true;
      this.apiService.Get("Estados/" + estado_id + "/Municipios").then(
        result => {
          this.listaCidades = result;
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/novo-cliente"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
    }
    else {
      this.perfil.municipio_uuid = null;
      this.listaCidades = [];
    }
  }

  obterListaCidades2(estado_id, cidade_nome) {
    if (estado_id != null) {
      this.loading = true;
      this.apiService.Get("Estados/" + estado_id + "/Municipios").then(
        result => {
          this.listaCidades = result;
          this.perfil.municipio_uuid = this.getIdCidade(cidade_nome);
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/novo-cliente"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
    }
    else {
      this.perfil.municipio_uuid = null;
      this.listaCidades = [];
    }
  }

  obterListaEstados() {
    this.loading = true;
    this.apiService.Get("Estados").then(
      result => {
        this.listaEstados = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/novo-cliente"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  getIdEstado(estado_sigla) {
    for (var estado in this.listaEstados) {
      if (this.listaEstados[estado].sigla == estado_sigla) {
        this.estadoAtual = this.listaEstados[estado].nome;
        this.getNomeEstado(this.estadoAtual);
        return this.listaEstados[estado].uuid;
      }
    }
  }

  getIdCidade(cidade_nome) {
    for (var cidade in this.listaCidades) {
      if (this.listaCidades[cidade].nome == cidade_nome.toUpperCase()) {
        this.cidadeAtual = this.listaCidades[cidade].nome;
        this.getNomeCidade(this.cidadeAtual);
        return this.listaCidades[cidade].uuid;
      }
    }
  }

  getNomeEstado(estado) {
    this.estadoAtual = estado;
    return this.estadoAtual;
  }

  getNomeCidade(cidade) {
    this.cidadeAtual = cidade;
    return this.cidadeAtual;
  }

  nxStep() {
    this.addAdmin = !this.addAdmin;
    this.revisar = false;
  }

  showPassword(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  review() {
    this.revisar = !this.revisar;
  }

  editInst() {
    this.addAdmin = false;
    this.revisar = false;
  }

  editAdmin() {
    this.addAdmin = true;
    this.revisar = false;
  }

  limparCampos() {
    this.perfil = {};
    this.estadoAtual = "";
    this.cidadeAtual = "";
    this.funcionario = {};
    this.objetoCadastro = {};
  }

  submit(cliente, administrador) {

    this.objetoCadastro = cliente;

    if (Object.keys(cliente).length == 0) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Nenhum dado foi inserido para cliente!'
      });
      return false;
    }

    if (Object.keys(administrador).length == 0) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Nenhum dado foi inserido para administrador!'
      });
      return false;
    }

    this.objetoCadastro.nome = administrador.nome_adm;
    this.objetoCadastro.sobrenome = administrador.sobrenome_adm;
    this.objetoCadastro.telefone_ddd = administrador.telefone_ddd;
    this.objetoCadastro.telefone_numero = administrador.telefone_numero;
    this.objetoCadastro.cpf = administrador.cpf_adm;
    this.objetoCadastro.email = administrador.email;
    this.objetoCadastro.login = administrador.login;
    this.objetoCadastro.senha = this.global.MD5(administrador.senha);

    //Validações cadastro do cliente
    if (this.objetoCadastro.nome_fantasia == null || this.objetoCadastro.nome_fantasia == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome fantasia!'
      });
      return false;
    }

    if (this.objetoCadastro.razao_social == null || this.objetoCadastro.razao_social == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a razão social!'
      });
      return false;
    }

    if (this.objetoCadastro.cnpj == null || this.objetoCadastro.cnpj == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o CNPJ!'
      });
      return false;
    }

    if (this.objetoCadastro.endereco_cep == null || this.objetoCadastro.endereco_cep == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o CEP!'
      });
      return false;
    }

    if (this.objetoCadastro.estado_uuid == null || this.objetoCadastro.estado_uuid == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Selecione um estado!'
      });
      return false;
    }

    if (this.objetoCadastro.municipio_uuid == null || this.objetoCadastro.municipio_uuid == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Selecione um município!'
      });
      return false;
    }

    if (this.objetoCadastro.endereco_bairro == null || this.objetoCadastro.endereco_bairro == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o bairro!'
      });
      return false;
    }

    if (this.objetoCadastro.endereco_rua == null || this.objetoCadastro.endereco_rua == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a rua!'
      });
      return false;
    }

    if (this.objetoCadastro.endereco_numero == null || this.objetoCadastro.endereco_numero == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o número!'
      });
      return false;
    }

    if (this.objetoCadastro.inicio_competencia == null || this.objetoCadastro.inicio_competencia == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a competencia!'
      });
      return false;
    }

    if (this.objetoCadastro.inicio_competencia < 1 || this.objetoCadastro.inicio_competencia > 31) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Competência inválida!'
      });
      return false;
    }

    if (this.objetoCadastro.fim_competencia == null || this.objetoCadastro.fim_competencia == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a competência!'
      });
      return false;
    }

    if (this.objetoCadastro.fim_competencia < 1 || this.objetoCadastro.fim_competencia > 31) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Competência inválida!'
      });
      return false;
    }

    if (this.objetoCadastro.inicio_competencia > this.objetoCadastro.fim_competencia) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Competência inicial não pode ser maior que a final!'
      });
      return false;
    }

    //Validações de cadastro do administrador
    if (this.objetoCadastro.nome == null || this.objetoCadastro.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome!'
      });
      return false;
    }

    if (this.objetoCadastro.sobrenome == null || this.objetoCadastro.sobrenome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o sobrenome!'
      });
      return false;
    }

    if (this.objetoCadastro.cpf == null || this.objetoCadastro.cpf == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o CPF!'
      });
      return false;
    }

    if (this.objetoCadastro.email == null || this.objetoCadastro.email == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o E-mail!'
      });
      return false;
    }

    if (this.objetoCadastro.login == null || this.objetoCadastro.login == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o login!'
      });
      return false;
    }

    if (this.objetoCadastro.senha == null || this.objetoCadastro.senha == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o senha!'
      });
      return false;
    }

    this.loading = true;

    this.apiService.Post("Clientes", this.objetoCadastro).then(
      result => {
        this.loading = false;
        this.chaveAcesso = result;
        Swal.fire({
          type: 'success',
          title: 'Sucesso!',
          text: `Sua chave é: ${this.chaveAcesso.uuid}`
        });
        this.limparCampos();
        this.router.navigate(["/novo-cliente"]);
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        else {
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Falha ao cadastrar cliente',
            text: err.error.mensagem
          });
        }
      }
    );
  }

}
