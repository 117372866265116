import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

import * as $ from 'jquery';

import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Event as NavigationEvent } from "@angular/router";

@Component({
  selector: 'app-modal-apontamento',
  templateUrl: './modal-apontamento.component.html',
  styleUrls: ['./modal-apontamento.component.css']
})
export class ModalApontamentoComponent implements OnInit {

 loading: boolean = false;
 fileUpload: any = {};
 semArquivo: boolean = true;

 lancamento: any = {};
 lancamentoCargos: any = [];
 listaTiposLancamento: any = [];
 listaSetoresFuncionario: any = [];
 listaCargosFuncionario: any = [];
 funcionario_uuid: any = '';
 lancamento_uuid: any = '';
 listaCargosLancamento: any = [];
 setorSelecionado: any = {};
 cargoSelecionado: any = {};

 exibeMsgListaCargos: boolean = true;

 _new: any = {};
 _old: any = {};
 atualiza: boolean = false;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    
    public dialogRef: MatDialogRef<ModalApontamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      try {
        if(Object.keys(data).length == 1){
          this.funcionario_uuid = data['funcionario'];
        }
        if(Object.keys(data).length == 2){
          this.funcionario_uuid = data['funcionario'];
          this.lancamento_uuid = data['lancamento']
        }
      } catch (err) {
        Swal.fire('Oops', err, 'error')
      }
    }

  ngOnInit() {
    //console.log(Object.keys(this.fileUpload).length);
    this.loading = true;
    this.obterTiposLancamento().then(
      result => {
        if(!this.lancamento_uuid) {
          this.atualiza = false;
          //this.obterListaSetoresFuncionario().then(res => {
            this.obterListaCargosFuncionario().then(res => {
              if(res){
                this.loading = false;
              }
           //})
          })
        }
        if(this.lancamento_uuid) {
          this.atualiza = true;
          this.apiService.Get(`Funcionarios/${this.funcionario_uuid}/Lancamento?lancamento_uuid=${this.lancamento_uuid}`).then(
            result =>{
              //console.log(result);
              Object.assign(this._old, result)
              this.listaCargosLancamento = result['lancamento_cargos']
              for (let cargo of result['lancamento_cargos']){
                this.lancamentoCargos.push(result['lancamento_cargos']['uuid'])
              }
              if(Object.keys(this.listaCargosLancamento).length > 0){
                this.exibeMsgListaCargos = false;
              }
              this.validaDatasObter().then(
                result => {
                  this.lancamento = this._old;
                  this.obterTipoApontamento().then(
                    result => {    
                      //console.log('aqui')
                      this.obterListaCargosFuncionario().then(
                        res => {              
                          //console.log('aqui')
                          this.loading=false;
                        },
                        error => {
                          this.loading=false;
                          Swal.fire('Oops', error, 'error')
                        }
                      )
                    },
                    error => {
                      this.loading=false;
                      Swal.fire('Oops', error, 'error')
                    }
                  )
                }
              )
            },
            error => {
              this.loading=false;
              Swal.fire('Oops', error, 'error').then(
                result =>{
                  if(result['value']=true) {
                    this.fechaModal();
                  }
                }
              )
            }
          )
        }
      }
    )
  }

  fechaModal() {
    this.dialogRef.close('Fechar modal');
  }

  obterTiposLancamento = () => {
    return new Promise((resolve) => {
      this.listaTiposLancamento = [
        { id: 1, nome: 'Afastamento' },
        { id: 2, nome: 'Férias' },
        { id: 3, nome: 'Falta' }
      ];
      resolve('Lista de lançamentos carregada')
    })
  }

  // obterListaSetoresFuncionario = () => {
  //   return new Promise((resolve, reject) => {
  //     this.apiService.Get(`Funcionarios/${this.funcionario_uuid}/Setor`).then(res =>{
  //       //console.log(res)
  //       this.listaSetoresFuncionario = res
  //       resolve('Carregou setores')
  //     })
  //   })
  // }

  obterListaCargosFuncionario = () => {
    return new Promise((resolve, reject) => {
      this.apiService.Get(`Funcionarios/${this.funcionario_uuid}/Cargo`).then(res =>{
        //console.log(res)
        if (res){
          var lista:any = []
          lista = res
          for (let item of lista){
            if (item['ativo']){
              this.listaCargosFuncionario.push(item)
            }
          }
          resolve('Carregou cargos')
        }
      })
    })
  }

  validaDatasObter = () => {
    return new Promise((resolve) => {
      if(this._old.data_inicio){
        this._old.data_inicio = this._old.data_inicio.split('T')[0]
        this._old.data_inicio = this._old.data_inicio.split('-')[2]+'/'+this._old.data_inicio.split('-')[1]+'/'+this._old.data_inicio.split('-')[0]
      }
      if(this._old.data_final){
        this._old.data_final = this._old.data_final.split('T')[0]
        this._old.data_final = this._old.data_final.split('-')[2]+'/'+this._old.data_final.split('-')[1]+'/'+this._old.data_final.split('-')[0]
      }
      resolve('Datas convertidas com sucesso')
    })
  }

  obterTipoApontamento = () => {
    return new Promise((resolve) => {
      console.log(this.listaTiposLancamento)
      for (let lancamento of this.listaTiposLancamento){
        if (this._old.tipo === lancamento.nome.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")){
          this.lancamento.tipo = lancamento.id;
          resolve('Lançamento obtido')
        }        
      }
    })
  }


  //Lançamentos

  salvarLancamento = () => {
    this.loading=true
    this.lancamento.funcionario_cargos = this.lancamentoCargos
    //console.log(this.lancamento)
    Object.assign(this._new, this.lancamento)
    this.validaDados().then(
      result => {
        this.validaDatasLancamento().then(
          result =>{
            //console.log(this.lancamento)
            if(!this.lancamento_uuid){
              this.manterLancamento().then(
                result => {
                  this.loading = false
                  Swal.fire('Sucesso', result.toString(), 'success').then(result => {
                    if (result['value']==true) {
                      this.lancamento = {}
                      this.lancamentoCargos = {}
                      this.listaCargosLancamento = {}
                      this.fechaModal()
                    }
                  })
                },
                error => {
                  this.loading = false
                  Swal.fire('Oops', error.toString(), 'error').then(
                    result => {
                      if (result['value']==true){
                        this.loading = false
                      }
                    }
                  )
                }
              )
            }
            if(this.lancamento_uuid){
              this.atualizarLancamento().then(
                result => {
                  this.loading = false
                  Swal.fire('Sucesso', result.toString(), 'success').then(result => {
                    if (result['value']==true) {
                      this.lancamento = {}
                      this.lancamentoCargos = {}
                      this.listaCargosLancamento = {}
                      this.fechaModal()
                    }
                  })
                },
                error => {
                  this.loading = false
                  Swal.fire('Oops', error.toString(), 'error')
                  this.lancamento = {}
                  this.lancamentoCargos = {}
                  this.listaCargosLancamento = {}
                }
              )
            }
          },
          error => {
            this.loading = false
            Swal.fire('Oops', error.toString(), 'error')
            return
          }
        )
      },
      error => {
        this.loading=false;
        Swal.fire('Oops', error.toString(), 'error')
      }
    )
  }

  addVinculo = () => {
    var mostraLancamento = {}
    mostraLancamento['uuid'] = this.cargoSelecionado.uuid
    mostraLancamento['cargo_nome'] = this.cargoSelecionado.cargo_nome
    mostraLancamento['cargo_uuid'] = this.cargoSelecionado.cargo_uuid
    mostraLancamento['vinculo_nome'] = this.cargoSelecionado.vinculo_nome
    mostraLancamento['vinculo_uuid'] = this.cargoSelecionado.vinculo_uuid
    mostraLancamento['setor_nome'] = this.setorSelecionado.setor_nome
    mostraLancamento['setor_uuid'] = this.setorSelecionado.setor_uuid
    mostraLancamento['funcionario_uuid'] = this.funcionario_uuid
    this.listaCargosLancamento.push(mostraLancamento)
    this.lancamentoCargos.push(mostraLancamento['uuid'].toString())
    this.exibeMsgListaCargos = false;
    //console.log(this.listaCargosLancamento)
  }

  removeItemListaLancamento = (index) => {
    //console.log(index)
    this.listaCargosLancamento.splice(index, 1);
    this.lancamentoCargos.splice(index, 1);
    if(Object.keys(this.listaCargosLancamento).length == 0) {
      this.exibeMsgListaCargos = true;
    }
  }

  validaDatasLancamento = () => {
    return new Promise((resolve, reject) => {
      // console.log(this._new.data_inicio)
      // console.log(this._new.data_final)
      if(this._new.data_inicio){
        this._new.data_inicio = new Date(this._new.data_inicio.split('/')[2]+'-'+this._new.data_inicio.split('/')[1]+'-'+this._new.data_inicio.split('/')[0])
        if(this._new.data_inicio.toDateString() == 'Invalid Date'){
          reject('É preciso informar uma data válida para o início do lançamento')
        }
        this._new.data_inicio =  this._new.data_inicio.toISOString();
        this._new.data_inicio =  this._new.data_inicio.split('T')[0]
      }
      if(!this._new.data_inicio){
        reject('É preciso informar uma data para o início do lançamento')
      }
      if(this._new.data_final){
        this._new.data_final = new Date(this._new.data_final.split('/')[2]+'-'+this._new.data_final.split('/')[1]+'-'+this._new.data_final.split('/')[0])
        if(this._new.data_final.toDateString() == 'Invalid Date'){
          reject('É preciso informar uma data válida para o fim do lançamento')
        }
        this._new.data_final =  this._new.data_final.toISOString();
        this._new.data_final =  this._new.data_final.split('T')[0]
      }
      if(!this._new.data_final){
        reject('É preciso informar uma data para o término do lançamento')
      }
      if (this._new.data_inicio > this._new.data_final) {
        reject('A data de início não pode ser posterior à data de fim')
      }
      resolve('Tudo certo');
    })
  }

  verificaDatas = () => {
    if (this.lancamento.data_inicio > this.lancamento.data_final) {
      Swal.fire('Oops', 'A data de início não pode ser posterior à data de fim', 'error')
    }
    else return
  }

  manterLancamento = () => {
    return new Promise((resolve, reject) => {
      var obj = this._new
      //console.log(obj)
      this.apiService.Post(`Funcionarios/${this.funcionario_uuid}/Lancamento`, obj).then(
        result => {
          if(result){
            resolve('Lançamento feito com sucesso')
          }
        },
        error => {
          reject(error.error.mensagem)
        }
      )
    })
  }

  atualizarLancamento = () => {
    return new Promise((resolve, reject) => {
      var listaCargos = []
      for (let cargo of this._new.lancamento_cargos){
        //console.log(cargo)
        if(cargo.uuid && cargo.funcionario_cargo_uuid){
          listaCargos.push(cargo.funcionario_cargo_uuid)
        }
        else if(cargo.uuid && !cargo.funcionario_cargo_uuid){
          listaCargos.push(cargo.uuid)
        }
      }
      var obj = this._new
      delete obj.funcionario_uuid;
      delete obj.funcionario_cargos;
      delete obj.lancamento_cargos;
      obj.funcionario_cargos = listaCargos;
      this.apiService.Put3(`Funcionarios/${this.funcionario_uuid}/Lancamento?lancamento_uuid=${this.lancamento_uuid}`, obj).then(
        result => {
          if(result){
            resolve('Lançamento atualizado com sucesso')
          }
        },
        error => {
          reject(error.error.mensagem)
        }
      )
    })
  }

  validaDados = () => {
    return new Promise((resolve, reject) => {
      //console.log(this._new)
      if(!this._new.tipo){
        reject('É preciso informar um tipo de apontamento para realizar o lançamento')
      }
      if(!this._new.descricao_espelho_ponto){
        reject('É preciso informar uma descrição para o espelho de ponto para realizar o lançamento')
      }
      if(Object.keys(this._new.funcionario_cargos).length == 0){
        reject('É preciso informar ao menos um cargo para realizar o lançamento')
      }
      resolve('ok')
    })
  }

  //area de upload para

  readURL = (e) =>{    
    var element = document.getElementById('progressBar');
    element.style.width = '0';
    var input = e.target.files[0];    
    var type = input.type.split('/')[1];
    var size = input.size;    
    this.convertBase64(input).then(result => {
      this.fileUpload.file = result;
      this.fileUpload.name = input.name;
      this.fileUpload.type = type;
      //console.log(Object.keys(this.fileUpload).length)
      if (Object.keys(this.fileUpload).length > 0){
        this.semArquivo = false;
      }
      this.fileUpload.size = (size / (1024*1024)).toFixed(2)+' MB';
      element.style.width = '100%'
      element.style.padding = '15px';
    }).catch(err => {
      //console.log(err)
      Swal.fire('Oops!', 'O arquivo enviado está com algum problema. Selecione outro e tente novamente', 'error');
      return
    })
    

  }

  removeUpload = () => {
    Swal.fire('Atenção', 'Deseja realmente cancelar o upload deste arquivo?', 'warning')
    .then(res => {
      if(res.value == true){
        var upload = document.getElementById('fileUpload');
        upload['value'] = '';
        this.fileUpload = {};
        var element = document.getElementById('progressBar');
        element.style.width = '0';
        element.style.padding = '0';
        this.semArquivo = true;
      }
    })    
  }

  convertBase64 = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => res(reader.result);
      reader.onerror = error => rej(error);
    })
  }

  changeParentColor = () => {
    var element = document.getElementById('progressBar');
    element.classList.toggle('bg-danger');
  }

}
