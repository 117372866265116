import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Global } from '../../global';
import { NavMenuComponent } from '../../components/nav-menu/nav-menu.component';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('NavMenuComponent', { read: ViewChild, static: true }) child: NavMenuComponent;


  atividade: boolean = false;
  constructor(private router: Router, private global: Global) { }

  ngOnInit() {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      let usuario = JSON.parse(localStorage.getItem("currentUser"));
      var data_expira = new Date(usuario.expira_em.split(" ")[0].split("/")[2] + "-" + usuario.expira_em.split(" ")[0].split("/")[1] + "-" + usuario.expira_em.split(" ")[0].split("/")[0] + "T00:00:00")
      if (data_expira < new Date()) {
        localStorage.setItem("currentUser", null);
        this.router.navigate(["/login"]);
      }

      this.global.token_acesso = usuario.token_acesso;
      this.router.navigate(["/"]);
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  feedback(clickMenu) {
    this.atividade = !this.atividade;
    $("#sidebar").toggle();
    // this.child.feedback(clickMenu);
  }
}
