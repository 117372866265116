import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../services/api.service';
import { saveAs } from "file-saver";

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";

@Component({
  selector: 'app-modal-detalhe-indicador-ponto',
  templateUrl: './modal-detalhe-indicador-ponto.component.html',
  styleUrls: ['./modal-detalhe-indicador-ponto.component.css']
})
export class ModalDetalheIndicadorPontoComponent implements OnInit {

  dadosModal: any = {};
  currentUser: any = {};
  loading = false;
  filtro: any = {};
  listaDadosDetalhe: any = [];
  txt: any = '';

  //paginação da tabela
  indice_selecionado: number = 1;
  cabecalho: any = {};
  listaPaginas: any = [];
  pesquisa: any = '';
  showPaginas: boolean = false;
  indice = 1;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,    
    public dialogRef: MatDialogRef<ModalDetalheIndicadorPontoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.dadosModal = data;
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }
  
  ngOnInit() {
    this.txt = '';
    var data = this.dadosModal.filtro.data_buscaIndicadoresPonto
    data = data.split('/')[2]+'-'+data.split('/')[1]+'-'+data.split('/')[0]
    this.txt = `?data=${data}&indicador=${this.dadosModal.indicador}`
    if (this.dadosModal.filtro.unidade){
      if (this.txt == '') {
        this.txt = '?unidade_uuid='+this.dadosModal.filtro.unidade
      }
      else {
        this.txt += '&unidade_uuid='+this.dadosModal.filtro.unidade
      }
    }
    if (this.dadosModal.filtro.unidade == 0){
      this.txt = ''
    }
    if (this.dadosModal.filtro.setor && this.dadosModal.filtro.setor != 0){
      if (this.txt == '') {
        this.txt = '?setor_uuid='+this.dadosModal.filtro.setor
      }
      else {
        this.txt += '&setor_uuid='+this.dadosModal.filtro.setor
      }
    }
    
    this.getDetails();
  }

  
  fechaModal() {
    this.listaDadosDetalhe = [];
    this.dialogRef.close();
  }

  getDetails = () => {
    this.loading = true;
    this.apiService.Get(`Indicadores/Detalhamento/Escala${this.txt}&pagina=${this.indice}`).then((result) =>{
      this.listaDadosDetalhe = result['conteudo'];
      this.cabecalho.total_paginas = result['total_paginas'];
      for (let item of this.listaDadosDetalhe) {
        this.global.aplicaMascaraCPF(item.funcionario_cpf).then(result => {
          item.funcionario_cpf = result;
        })
      }
      if(this.cabecalho.total_paginas > 1){
        this.showPaginas = true;
      }
      this.loading = false;
    })
  }

  //paginação  
  primeira(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  anterior(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  atual(indice, pesquisa) {
    if (this.indice_selecionado != indice) {
      this.indice_selecionado = indice;
      this.outraPagina(this.indice_selecionado);
    } else {
      this.indice_selecionado = indice;
      this.outraPagina(this.indice_selecionado);
    }
  }

  proxima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  ultima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
      this.outraPagina(this.indice_selecionado);
    }
  }

  outraPagina = (pagina) => {
    this.loading = true;    
    this.apiService.Get(`Indicadores/Detalhamento/Escala${this.txt}&pagina=${pagina}`)
      .then(result => {
        this.listaDadosDetalhe = result['conteudo'];
        this.loading = false;
      });
  }
  //fim paginação

  //impressão 

  imprimirIndicador = () => {
    Swal.fire('Favor aguarde')
    Swal.showLoading()
    var txt:string = this.txt+'&impresso=true'+'&pagina=0'
    this.apiService.Get(`Indicadores/Detalhamento/Escala${txt}`).then(
      result => {
        
      },
      error => {
        if (error.status == 200) {
          let dataAtual = new Date();
          let mesAtual = dataAtual.getMonth();
          let anoAtual = dataAtual.getFullYear();
          var result = saveAs(
            error["error"]["text"],
            `indicador${this.dadosModal.indicador}.pdf`
          );
          Swal.close()
          this.loading = false;
          return;
        }
        if (error.status == 401) {
          this.router.navigate(["/login"]);
          this.loading = false;
          Swal.close()
          return;
        }
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: error.error.mensagem,
        });
        this.loading = false;
      }
    )
  }

}
