import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";

@Component({
  selector: 'app-espelho-ponto-consolidado',
  templateUrl: './espelho-ponto-consolidado.component.html',
  styleUrls: ['./espelho-ponto-consolidado.component.css']
})
export class EspelhoPontoConsolidadoComponent implements OnInit {

  loading: any = false;

  filtro: any = { unidade: 0 };
  filtro_selecionado: any = {};

  listaUnidades: any = [];
  listaSetores: any = [];
  listaCargos: any = [];
  listaMeses: any = [];

  podeImprimir: boolean = false;

  constructor(
    private apiService: ApiService, private router: Router
  ) { }

  ngOnInit() {
    this.filtro.ano = new Date().getFullYear();
    this.dadosFiltro();
  }

  dadosFiltro = () => {
    this.loading = true;
    this.obterUnidades().then(
      result => {
        this.listaUnidades = result;
        this.habilitarImpressao()
        this.obterMeses().then(
          result => {
            this.listaMeses = result;
            this.habilitarImpressao()
            this.filtro.mes = new Date().getMonth() + 1;
            this.loading = false;
          },
          error => {
            this.exibeErro(error);
          }
        )
      },
      error => {
        this.exibeErro(error);
      }
    )
  }

  obterUnidades = () => {
    return new Promise((resolve, reject) => {
      this.apiService.Get("Unidades?ativo=true&gerente=true").then(
        result => {
          resolve(result);
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          reject(err)
        }
      );
    })
  }

  obterMeses = () => {
    return new Promise((resolve, reject) => {
      var meses = [
        { id: 1, nome: "Janeiro" },
        { id: 2, nome: "Fevereiro" },
        { id: 3, nome: "Março" },
        { id: 4, nome: "Abril" },
        { id: 5, nome: "Maio" },
        { id: 6, nome: "Junho" },
        { id: 7, nome: "Julho" },
        { id: 8, nome: "Agosto" },
        { id: 9, nome: "Setembro" },
        { id: 10, nome: "Outubro" },
        { id: 11, nome: "Novembro" },
        { id: 12, nome: "Dezembro" },
      ];
      resolve(meses)
    })
  }

  obterSetores = (unidade:any) => {
    this.listaSetores = null;
    this.listaCargos = null;
    this.filtro.setor = null;
    this.filtro.cargo = null;
    if (unidade == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get("Unidades/" + unidade + "/Setores?ativo=true&gerente=true").then(
        result => {
          this.listaSetores = result;
          this.filtro.setor = 0;
          this.habilitarImpressao()
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.exibeErro(err);
        }
      );
  }

  obterCargos(setor:any) {
    this.listaCargos = null;
    this.filtro.cargo = null;
    if (setor == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get("Setor/" + setor + "/Cargos?ativo=true&gerente=true").then(
      (result) => {
        this.listaCargos = result;
        this.filtro.cargo = 0;
        this.habilitarImpressao()
        this.loading = false;
      },
      (err) => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.exibeErro(err)
      }
    );
  }

  exibeErro = (erro:any) => {
    this.loading = false;
    Swal.fire({
      type: "error",
      title: "Oops...",
      text: erro
    });
  }

  habilitarImpressao = () => {
    return new Promise((resolve) => {
      if(this.filtro.unidade &&
        this.filtro.ano && this.filtro.ano != 0 &&
        this.filtro.mes && this.filtro.mes != 0){
          this.podeImprimir = true
          resolve('pode imprimir')
        }
    })
  }

  imprimir = () => {
    this.loading = true;
    this.validarFiltros().then(
      result => {
        var txt = '';
        txt = "unidade_uuid="+this.filtro.unidade.uuid;
        if (this.filtro.setor){
          txt += "&setor_uuid="+this.filtro.setor.uuid
        }
        if (this.filtro.cargo){
          txt += "&cargo_uuid="+this.filtro.cargo.uuid
        }
        txt += "&ano="+this.filtro.ano+"&mes="+this.filtro.mes;

        this.apiService.Get(`EspelhoPonto/Consolidado?${txt}`).then(
          result => {
            
          },
          error => {
            if(error.status ==200){
              let dataAtual = new Date();
              let mesAtual = dataAtual.getMonth();
              let anoAtual = dataAtual.getFullYear();
              var result = saveAs(
                error["error"]["text"],
                `espelho-de-ponto-consolidado-${mesAtual}/${anoAtual}.pdf`
              );
              this.loading = false;
              return
            }
            this.exibeErro(error);
          }
        )
      },        
      error => {
        this.exibeErro(error);
      }
    )
  }

  validarFiltros = () => {
    return new Promise((resolve, reject) => {
      if (this.filtro.unidade == null || this.filtro.unidade == 0 || this.filtro.unidade == "0") {
        reject("Informe a unidade")
      }
      if (this.filtro.ano == null || this.filtro.ano == 0 || this.filtro.ano == "0") {
        reject("Informe o ano")
      }
      if (this.filtro.mes == null || this.filtro.mes == 0 || this.filtro.mes == "0") {
        reject("Informe o mês")
      }
      resolve('Tudo certo!')
    })
  }

}
