import { Component, OnInit, NgModule, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { ApiService } from '../../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";
import { AppComponent } from '../../../app.component';
import { ModalBiometriaComponent } from '../../../components/modal-biometria/modal-biometria.component';
import { ModalApontamentoComponent } from '../../../components/modal-apontamento/modal-apontamento.component';


import { NgxViacepService, Endereco, CEPError } from '@brunoc/ngx-viacep';
import { catchError } from 'rxjs/operators';
import { resolve } from 'url';


@Component({
  selector: 'app-editar-funcionario',
  templateUrl: './editar-funcionario.component.html',
  styleUrls: ['./editar-funcionario.component.css']
})
export class EditarFuncionarioComponent implements OnInit {

  novoFuncionario: boolean = false;
  funcionario: any = {};
  cargo: any = {};
  unidade: any = {};
  setor: any = {};
  vinculo: any = {};
  carga_horaria: any = null;
  loading = false;
  visualizando: any = false;
  listaFuncionarios: any = [];
  listaCargos: any = [];
  listaVinculos: any = [];
  listaUnidades: any = [];
  listaSetores: any = [];
  listaTiposBusca: any = [];
  tipoBusca: any = { id: 0 };
  imgSrc: any = '../../../../assets/img/default.jpg';
  pesquisa: any = { unidade: 0, cargo: 0, vinculo: 0 };
  element: HTMLImageElement;

  currentUser: any = {};

  screenHeight: any;
  screenWidth: any;
  hideTextBtn: boolean = false;

  ativo: boolean = true;

  //aba de endereço do funcionário
  estados: any = [];
  enderecoFuncionario: any = {};
  listaMunicipios: any = [];

  //partes de testes de funcionários
  cabecalho: any = {};
  listaPaginas: any = [];
  indice_selecionado: any = 0;

  abaID: any = 1;

  _old: any = {};
  _new: any = {};

// dados complementares
  dados_complementares: any = {};
  _old_dados_complementares: any = {};
  _new_dados_complementares: any = {};
  
  possuiDadosComplementares: boolean = false;
  
  certidaoObrigatorio: boolean = false;
  identidadeObrigatorio: boolean = false;
  tituloEleitorObrigatorio: boolean = false;
  ctpsObrigatorio: boolean = false;
  cnhObrigatorio: boolean = false;
  passaporteObrigatorio: boolean = false;

// endereço
  endereco: any = {};
  _new_enderecoFuncionario: any = {};
  _old_enderecoFuncionario: any = {};

  possuiEndereco: boolean = false;

  enderecoObrigatorio: boolean = true;

  cepEncontrado: boolean = false;

// unidades e setor
listaSetoresFuncionario: any = [];
loadApiSetor: boolean = true;
admitido_em: any = '';
desvinculado_em: any = '';
setor_admitido_em: any = '';
setor_desvinculado_em: any = '';

// Cargos
listaCargosFuncionario: any = [];

// Lançamentos
listaLancamentosFuncionario: any = [];
exibeMsgListaLancamentos: boolean = false;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public viaCEP: NgxViacepService
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1190) {
      this.hideTextBtn = true;
    }
    else {
      this.hideTextBtn = false;
    }
  }

  ngOnInit() {
    // console.log(this.currentUser)
    // var data = new Date()
    // var hoje = data.getDate()+"/"+(data.getMonth() + 1)+"/"+data.getFullYear();
    var hoje = this.global.dateFormater(new Date());
    this.admitido_em = hoje;
    this.setor_admitido_em = hoje;
    this.getEstados();
    this.init();
  }

  init =  async () => {
    var funcionario = {};
    funcionario['uuid'] = this.route.snapshot.paramMap.get("uuid")
    if (funcionario['uuid']!=null){
      this.obterFuncionario(funcionario);
    }
    else {
      this.funcionario.ativo = this.ativo
    }
  }

  getFormatterDate(item) {
    return this.global.dateFormater(item);
  }

  formata_CPF(cpf) {
    return this.global.formataCPF(cpf);
  }

  mudaAba = (val: any) =>{
    this.loading = true;
    this.abaID = val;
    if(this.abaID == 1){
      this.obterFuncionario(this.funcionario);
    }
    if(this.abaID == 2){
      this.obterDadosComplementares(this.funcionario.uuid);
    }
    if(this.abaID == 3){
      this.obterEndereco(this.funcionario.uuid);
    }
    if(this.abaID == 4){
      this.obterUnidades(this.funcionario.uuid);
    }
    if(this.abaID == 5){
      this.obterListaCargos()
    }
    if(this.abaID == 6){
      this.obterListaLancamentos()
    }
  }

  // dados pessoais

  obterFuncionario(funcionario) {
    this.loading = true;
    this.apiService.GetOne("Funcionarios", funcionario.uuid).then(
      result => {
        this.funcionario = result;
        Object.assign(this._old, this.funcionario);
        //console.log(this._old);
        var data = new Date()
        var hoje = ("0"+data.getDate()).slice(-2)+"/"+("0"+(data.getMonth() + 1)).slice(-2)+"/"+data.getFullYear();
        this.funcionario.data_nascimento_str = this.global.dateFormater(result['data_nascimento']);
        this.funcionario.admitido_em_str = this.global.dateFormater(result['admitido_em']);
        this.setor_admitido_em = hoje;
        this.admitido_em = hoje;
        this.loading = false;
        if (this.funcionario.foto_perfil != null) {
          this.imgSrc = this.funcionario.foto_perfil_link;
          let img = document.getElementById('imgFuncionario');
          img.onerror = function () {
            document.getElementById('imgFuncionario').setAttribute('src', '../../../../assets/img/default.jpg');
          }
        } else {
          this.imgSrc = '../../../../assets/img/default.jpg';
        }
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  valida() {
    return new Promise((resolve, reject) => {
      if (this._new.ativo == null) {
        this._new.ativo = false;
      }
      if (this._new.nome == null || this._new.nome == "") {
        reject('Insira o nome!')
        return
      }
      if (this._new.sobrenome == null || this._new.sobrenome == "") {
        reject('Insira o sobrenome!')
        return
      }
      if (this._new.cpf == null || this._new.cpf == "") {
        reject('Insira o CPF!')
        return
      }
      
      if (this._new.data_nascimento_str == null) {
        reject('Insira a data de nascimento!')
        return
      }
      let data_nascimento_ = new Date(this._new.data_nascimento_str.split("/")[2] + "-" + this._new.data_nascimento_str.split("/")[1] + "-" + this._new.data_nascimento_str.split("/")[0]);
      if (data_nascimento_ > new Date()) {
        reject('A data de nascimento não pode ser uma data no futuro!')
        return
      }
      if (data_nascimento_.toDateString() == 'Invalid Date') {
        reject('Insira uma data de nascimento válida!')
        return
      }
      // if(!this._new.data_nascimento){
      //   this._new.data_nascimento = data_nascimento_
      // }
      this._new.data_nascimento = data_nascimento_.toISOString()
      this._new.data_nascimento = this._new.data_nascimento.split('.')[0];
      
      console.log(this._new.admitido_em_str)
      if (!this._new.admitido_em_str) {
        reject('Insira a data de admissão!');
        return
      }
      let data_admitido_ = new Date(this._new.admitido_em_str.split("/")[2] + "-" + this._new.admitido_em_str.split("/")[1] + "-" + this._new.admitido_em_str.split("/")[0]);
      if (data_admitido_ > new Date()) {
        reject('A data de admissão não pode ser uma data no futuro!')
        return
      }
      if (data_admitido_.toDateString() == 'Invalid Date') {
        reject('Insira uma data de admissão válida!');
        return
      }
      // if(!this._new.admitido_em){
      //   this._new.admitido_em = data_admitido_;
      // }
      this._new.admitido_em = data_admitido_.toISOString();
      this._new.admitido_em = this._new.admitido_em.split('.')[0];
      
      if ((this._new.telefone1_ddd != null && this._new.telefone1_ddd != "") && (this._new.telefone1_ddd < 10 || this._new.telefone1_ddd > 99)) {
        reject('DDD Inválido do telefone!')
        return
      }
      if ((this._new.telefone2_ddd != null && this._new.telefone2_ddd != "") && (this._new.telefone2_ddd < 10 || this._new.telefone2_ddd > 99)) {
        reject('DDD Inválido do celular!')
        return
      }
      resolve('dados validados');
    })
  }

  validaDadosAntigos = () => {
    return new Promise((resolve, reject) => {
      console.log(this._old.data_nascimento)
      if(this._old.data_nascimento){
        this._old.data_nascimento = this._old.data_nascimento.split('T')[0]
      }
      //console.log(this._old.data_nascimento);      
      if(this._old.admitido_em){
        this._old.admitido_em = this._old.admitido_em.split('T')[0];
      }
      //console.log(this._old.admitido_em);
      resolve('dados validados');
    })
  }

  validaCampos = () => {
    return new Promise((resolve, reject) => {      
      delete this._new.admitido_em_str
      delete this._new.data_nascimento_str
      // if(this._new.nis_pis_pasep == ''){
      //   delete this._new.nis_pis_pasep
      // }
      this._new.cpf = this._new.cpf.replace(/\D/g,'');
      // console.log(this._new)
      // console.log(this._old)
      if(Object.keys(this._old).length == 0){
        resolve('novo funcionário')
        return;
      }
      for (var p in this._new){
        if(this._new.hasOwnProperty(p)){
          if (this._new[p] !== this._old[p]){
            console.log(this._old[p])
            // console.log(this._new[p])
            this.loading = false;
            Swal.fire({
              title: 'Oops',
              text: 'Há alterações não salvas que podem ser perdidas, gostaria de salvá-las?', 
              type: 'warning',
              showCancelButton: true,
              cancelButtonColor: '#d33'
            })
            .then((res) => {
              if(res['value']==true){
                resolve(1)
                this.loading = true;
              }
              else{
                resolve(2)
                this.loading = true;
              }
            })
            return
          }
        }
      }
      for (var p in this._old){
        if(this._old.hasOwnProperty(p)){
          if (this._old[p] !== this._new[p]){
            // console.log(this._old[p])
            // console.log(this._new[p])
            this.loading = false;
            Swal.fire({
              title: 'Oops',
              text: 'Há alterações não salvas que podem ser perdidas, gostaria de salvá-las?', 
              type: 'warning',
              showCancelButton: true,
              cancelButtonColor: '#d33'
            })
            .then((res) => {
              if(res['value']==true){
                resolve(1)
                this.loading = true;
              }
              else{
                resolve(2)
                this.loading = true;
              }
            })
            return
          }
        }
      }
      resolve(2)
    })
  }

  salvaDados = () => {
    return new Promise((resolve, reject) => {
        this.loading = true;
        if (this._new.uuid != null) {
          var uuid = this._new.uuid
          this._new.cargos = []
          this._new.setores = []
          // console.log(this._new)
          this.apiService.Put(`Funcionarios`, this._new)
          .then(result => {
            this.loading = false;
              resolve('Funcionário atualizado com sucesso!')
            }, 
            err => {
              if (err.status == 401) {
                this.router.navigate(["/login"]);
                return;
              }
              if (err.status == 401) {
                this.router.navigate(["/login"]);
                return;
              }
              else {
                reject(err.error.mensagem)
              }
            }
          );
        } 
        else {
          if (this._new.usar_nome_social == null) {
            this._new.usar_nome_social = false
            this._new.nome_social = ''
          }
          console.log(this._new)
          this.apiService.Post("Funcionarios", this._new).then(
            result => {
              this.loading = false;
              this.funcionario = result;
              resolve('Funcionário salvo com sucesso!')
            },
            err => {
               if (err.status == 401) {
                 this.router.navigate(["/login"]);
                return;
              }
              else {
                reject(err)
              }
            }
          );
        }
      })
  }

  salvaAba = (val: any)=> {
    this.loading = true;
    Object.assign(this._new,this.funcionario);
    // this.validaDadosAntigos().then(
    //   result => {      
        this.valida().then(
          result => {
            if(this._new.uuid){
              this.validaCampos().then(
                result => {
                  if (result == 2) {
                    this.loading = false;
                    this.mudaAba(val);
                  }
                  if (result == 1){
                    this.salvaDados().then(
                      result => {
                        this.loading = false;
                        Swal.fire('Sucesso', result.toString(), 'success').then(res => {
                          if (res['value']==true){
                            this.mudaAba(val);
                          }
                        })
                      },
                      error => {
                        this.loading = false;
                        // console.log(error)
                        // console.log(error.message)
                        Swal.fire('Oops!', error.error.mensagem, 'error')
                      }
                    )
                  }
                },
                error => {
                  this.loading = false;
                  //console.log(error)
                  Swal.fire('Oops!', error.toString(), 'error')
                }
              )
            }
            else {
              this.validaCampos().then(
                result => {
                  this.salvaDados().then(
                    result => {
                      this.loading = false;
                      Swal.fire('Sucesso', result.toString(), 'success').then(res => {
                        if (res['value']==true){
                          this.mudaAba(val);
                        }
                      })
                    },
                    error => {
                      this.loading = false;
                      // console.log(error)
                      // console.log(error.message)
                      Swal.fire('Oops!', error.error.mensagem, 'error')
                    }
                  )
                }
              )
            }
          },
          error => {
            // console.log(error)
            this.loading = false;
            Swal.fire('Oops!', error.toString(), 'error')
          }
        ),
        error => {
          // console.log(error)
          this.loading = false;
          Swal.fire('Oops!', error.toString(), 'error')
        }
      // },
      // error => {
      //   // console.log(error)
      //   this.loading = false;
      //   Swal.fire('Oops!', error.toString(), 'error')
      // }
    //)
  }

  // ===>>> dados complementares

  obterDadosComplementares = (uuid) => {
    this.loading = true;
    this.apiService.Get(`Funcionarios/${uuid}/DadosComplementares`).then(
      result => {
        if(result){
          if(result['uuid']){
            this.dados_complementares = result;
            this.ajustarFormatoDataObter().then(result => {
              Object.assign(this._old_dados_complementares, this.dados_complementares);
              this.possuiDadosComplementares = true;
              this.loading = false;
            })
          }
        }
        this.loading = false;
      },
      error => {
        Swal.fire('Oops', error, 'error')
        this.loading = false;
      }
    )
  }

  validarCamposDadosComplementares = () => {
    return new Promise((resolve, reject) => {
      if((!this.dados_complementares.certidao_numero && this.dados_complementares.certidao_data && this.dados_complementares.certidao_local) ||
         (!this.dados_complementares.certidao_numero && !this.dados_complementares.certidao_data && this.dados_complementares.certidao_local) ||
         (!this.dados_complementares.certidao_numero && this.dados_complementares.certidao_data && !this.dados_complementares.certidao_local) ||
         (this.dados_complementares.certidao_numero && !this.dados_complementares.certidao_data && this.dados_complementares.certidao_local) ||
         (this.dados_complementares.certidao_numero && this.dados_complementares.certidao_data && !this.dados_complementares.certidao_local) ||
         (this.dados_complementares.certidao_numero && !this.dados_complementares.certidao_data && !this.dados_complementares.certidao_local)){
          reject('Favor complementar os dados referentes a certidão');
          return
      }

      if((!this.dados_complementares.identidade_numero && this.dados_complementares.identidade_data && this.dados_complementares.identidade_orgao_emissor) ||
         (!this.dados_complementares.identidade_numero && !this.dados_complementares.identidade_data && this.dados_complementares.identidade_orgao_emissor) ||
         (!this.dados_complementares.identidade_numero && this.dados_complementares.identidade_data && !this.dados_complementares.identidade_orgao_emissor) ||
         (this.dados_complementares.identidade_numero && !this.dados_complementares.identidade_data && this.dados_complementares.identidade_orgao_emissor) ||
         (this.dados_complementares.identidade_numero && !this.dados_complementares.identidade_data &&!this.dados_complementares.identidade_orgao_emissor) ||
         (this.dados_complementares.identidade_numero && this.dados_complementares.identidade_data && !this.dados_complementares.identidade_orgao_emissor)){
          reject('Favor complementar os dados referentes a identidade');
          return
      }

      if((!this.dados_complementares.titulo_numero && this.dados_complementares.titulo_zona && this.dados_complementares.titulo_secao) ||
         (!this.dados_complementares.titulo_numero && !this.dados_complementares.titulo_zona && this.dados_complementares.titulo_secao) ||
         (!this.dados_complementares.titulo_numero && this.dados_complementares.titulo_zona && !this.dados_complementares.titulo_secao) ||
         (this.dados_complementares.titulo_numero && !this.dados_complementares.titulo_zona && this.dados_complementares.titulo_secao) ||
         (this.dados_complementares.titulo_numero && !this.dados_complementares.titulo_zona && !this.dados_complementares.titulo_secao) ||
         (this.dados_complementares.titulo_numero && this.dados_complementares.titulo_zona && !this.dados_complementares.titulo_secao)){
        reject('Favor complementar os dados referentes ao título de eleitor');
        return
      }

      if((!this.dados_complementares.carteira_trabalho_numero && this.dados_complementares.carteira_trabalho_serie && this.dados_complementares.carteira_trabalho_data) ||
         (!this.dados_complementares.carteira_trabalho_numero && !this.dados_complementares.carteira_trabalho_serie && this.dados_complementares.carteira_trabalho_data) ||
         (!this.dados_complementares.carteira_trabalho_numero && this.dados_complementares.carteira_trabalho_serie && !this.dados_complementares.carteira_trabalho_data) ||
         (this.dados_complementares.carteira_trabalho_numero && !this.dados_complementares.carteira_trabalho_serie && this.dados_complementares.carteira_trabalho_data) ||
         (this.dados_complementares.carteira_trabalho_numero && !this.dados_complementares.carteira_trabalho_serie && !this.dados_complementares.carteira_trabalho_data) ||
         (this.dados_complementares.carteira_trabalho_numero && this.dados_complementares.carteira_trabalho_serie && !this.dados_complementares.carteira_trabalho_data)){
        reject('Favor complementar os dados referentes a carteira de trabalho');
        return
      }

      if((!this.dados_complementares.habilitacao_numero && this.dados_complementares.habilitacao_estado_uuid && this.dados_complementares.habilitacao_data) ||
         (!this.dados_complementares.habilitacao_numero && !this.dados_complementares.habilitacao_estado_uuid && this.dados_complementares.habilitacao_data) ||
         (!this.dados_complementares.habilitacao_numero && this.dados_complementares.habilitacao_estado_uuid && !this.dados_complementares.habilitacao_data) ||
         (this.dados_complementares.habilitacao_numero && !this.dados_complementares.habilitacao_estado_uuid && this.dados_complementares.habilitacao_data) ||
         (this.dados_complementares.habilitacao_numero && !this.dados_complementares.habilitacao_estado_uuid && !this.dados_complementares.habilitacao_data) ||
         (this.dados_complementares.habilitacao_numero && this.dados_complementares.habilitacao_estado_uuid && !this.dados_complementares.habilitacao_data)){
         reject('Favor complementar os dados referentes a carteira de habilitação');
         return
      }

      if((!this.dados_complementares.passaporte_numero && this.dados_complementares.passaporte_data_emissao && this.dados_complementares.passaporte_data_validade) ||
         (!this.dados_complementares.passaporte_numero && !this.dados_complementares.passaporte_data_emissao && this.dados_complementares.passaporte_data_validade) ||
         (!this.dados_complementares.passaporte_numero && this.dados_complementares.passaporte_data_emissao && !this.dados_complementares.passaporte_data_validade) ||
         (this.dados_complementares.passaporte_numero && !this.dados_complementares.passaporte_data_emissao && this.dados_complementares.passaporte_data_validade) ||
         (this.dados_complementares.passaporte_numero && !this.dados_complementares.passaporte_data_emissao && !this.dados_complementares.passaporte_data_validade) ||
         (this.dados_complementares.passaporte_numero && this.dados_complementares.passaporte_data_emissao && !this.dados_complementares.passaporte_data_validade)){
         reject('Favor complementar os dados referentes ao passaporte');
         return
      }

      resolve('Dados complementares foram devidamente informados')
    })
  }

  salvarCamposDadosComplementares = (val: any) => {
    this.loading = true;
    this._new_dados_complementares = {};
    Object.assign(this._new_dados_complementares, this.dados_complementares);
    // console.log(this._new_dados_complementares)
    // console.log(this._old_dados_complementares)
    this.ajustaFormatoDataComparar().then(
      result => {
        this.ajustarFormatoDataSalvar().then(
          result => {
            this.validarCamposDadosComplementares().then(
                result => {
                  if(Object.keys(this.dados_complementares).length == 0){
                    this.mudaAba(val);
                    return;
                  }
                  else if(!this.possuiDadosComplementares) {
                    this.manterDadosComplementares().then(
                      result=>{
                        // console.log(result)
                        this.mudaAba(val);
                        Swal.fire('Sucesso', result.toString(), 'success');
                        this.loading = false;
                      },
                      error =>{
                        //console.log(error)
                        Swal.fire('Oops', error.error.mensagem, 'error');
                        this.loading = false;            
                    });
                  }
                  else if(this.possuiDadosComplementares) {                
                    if (val != this.abaID){
                      for (var p in this._new_dados_complementares){
                        if(this._new_dados_complementares.hasOwnProperty(p)){
                          // console.log(this._new_dados_complementares)
                          // console.log(this._old_dados_complementares)
                          if (this._new_dados_complementares[p] !== this._old_dados_complementares[p]){
                            // console.log(this._new_dados_complementares[p])
                            // console.log(this._old_dados_complementares[p])
                            this.loading = false;
                            Swal.fire({
                              title: 'Oops',
                              text: 'Há alterações não salvas que podem ser perdidas, gostaria de salvá-las?', 
                              type: 'warning',
                              showCancelButton: true,
                              cancelButtonColor: '#d33'
                            })
                            .then((res) => {
                              if(res['value']==true){
                                this.loading=true;
                                this.atualizarDadosComplementares().then(
                                  result=>{
                                    //console.log(result)
                                    this.loading = false;
                                    Swal.fire('Sucesso', result.toString(), 'success').then(res => {
                                      this.mudaAba(val);
                                      //this.obterDadosComplementares(this.funcionario.uuid)
                                    })
                                  },
                                  error =>{
                                    this.loading = false;            
                                    //console.log(error)
                                    Swal.fire('Oops', error.error.mensagem, 'error');
                                  }
                                );
                              }
                              else{
                                this.mudaAba(val);
                              }
                            })
                            return
                          }
                        }
                      }
                      for (var p in this._old_dados_complementares){
                        if(this._old_dados_complementares.hasOwnProperty(p)){
                          if (this._old_dados_complementares[p] !== this._new_dados_complementares[p]){
                            // console.log(this._old_dados_complementares[p])
                            // console.log(this._new_dados_complementares[p])
                            this.loading = false;
                            Swal.fire({
                              title: 'Oops',
                              text: 'Há alterações não salvas que podem ser perdidas, gostaria de salvá-las?', 
                              type: 'warning',
                              showCancelButton: true,
                              cancelButtonColor: '#d33'
                            })
                            .then((res) => {
                              if(res['value']==true){
                                this.atualizarDadosComplementares().then(
                                  result=>{
                                    // console.log(result)
                                    this.loading = false;
                                    Swal.fire('Sucesso', result.toString(), 'success').then(res => {
                                      this.mudaAba(val);
                                      // this.obterDadosComplementares(this.funcionario.uuid)
                                    })
                                  },
                                  error =>{
                                    this.loading = false;            
                                    //console.log(error)
                                    Swal.fire('Oops', error.error.mensagem, 'error');
                                  }
                                );
                              }
                              else{
                                this.mudaAba(val);
                              }
                            })
                            return
                          }
                        }
                      }
                    }
                    else{
                      this.atualizarDadosComplementares().then(
                        res => {
                          this.loading = false;
                          Swal.fire('Sucesso', res.toString(), 'success').then(res => {
                            if (res['value']==true){
                              this.mudaAba(val);
                            }
                          })
                        }
                      )
                      return
                    }
                    this.mudaAba(val);
                  }
                },
                error => {
                  this.loading = false;
                  //console.log(error)
                  Swal.fire('Oops', error.toString(), 'error');
                }
              )
          },
          error => {
            //console.log(error)
            this.loading = false;
            Swal.fire('Oops', error.toString(), 'error');
          }
        )            
      },
      error => {
        // console.log(error)
        this.loading = false;
        Swal.fire('Oops', error.toString(), 'error');
      }
    )
  }

  manterDadosComplementares = () => {
    var uuid = this.funcionario.uuid;
    return new Promise((resolve, reject) => {
      this.apiService.Post(`Funcionarios/${uuid}/DadosComplementares`, this._new_dados_complementares).then(
        result => 
          resolve('Dados complementares foram salvos'),
        error => {
          //console.log(error.error.mensagem);
          reject(error)
        }
      )
    })
  }

  atualizarDadosComplementares = () => {
    var uuid = this.funcionario.uuid;
    return new Promise((resolve, reject) => {
      this.apiService.Put3(`Funcionarios/${uuid}/DadosComplementares`, this._new_dados_complementares).then(
        result => 
          resolve('Dados complementares foram atualizados'),
        error => {
          //console.log(error.error.mensagem);
          reject(error)
        }
      )
    })
  }

  addObrigatorioDadosComplementaresCertidao = () => {
    if(!this.dados_complementares.certidao_numero && !this.dados_complementares.certidao_data && !this.dados_complementares.certidao_local){
        this.certidaoObrigatorio = false;
      }
    else this.certidaoObrigatorio = true;
  }

  addObrigatorioDadosComplementaresId = () => {
    if(!this.dados_complementares.identidade_numero && !this.dados_complementares.identidade_data && !this.dados_complementares.identidade_orgao_emissor){
        this.identidadeObrigatorio = false;
      }
    else this.identidadeObrigatorio = true;
  }

  addObrigatorioDadosComplementaresTituloEleitor = () => {
    if(!this.dados_complementares.titulo_numero && !this.dados_complementares.titulo_zona && !this.dados_complementares.titulo_secao){
        this.tituloEleitorObrigatorio = false;
      }
    else this.tituloEleitorObrigatorio = true;
  }

  addObrigatorioDadosComplementaresCtps = () => {
    if(!this.dados_complementares.carteira_trabalho_numero && !this.dados_complementares.carteira_trabalho_serie && !this.dados_complementares.carteira_trabalho_data){
        this.ctpsObrigatorio = false;
      }
    else this.ctpsObrigatorio = true;
  }

  addObrigatorioDadosComplementaresCnh = () => {
    if(!this.dados_complementares.habilitacao_numero && !this.dados_complementares.habilitacao_estado_uuid && !this.dados_complementares.habilitacao_data){
        this.cnhObrigatorio = false;
      }
    else this.cnhObrigatorio = true;
  }

  addObrigatorioDadosComplementaresPassaporte = () => {
    if(!this.dados_complementares.passaporte_numero && !this.dados_complementares.passaporte_data_emissao && !this.dados_complementares.passaporte_data_validade){
        this.passaporteObrigatorio = false;
      }
    else this.passaporteObrigatorio = true;
  }

  ajustarFormatoDataSalvar = () => {
    return new Promise<any>((resolve, reject) => {
      //console.log(this._new_dados_complementares)
      if(this._new_dados_complementares.certidao_data){
        if(this._new_dados_complementares.certidao_data && this._new_dados_complementares.certidao_data!=undefined){ 
          this._new_dados_complementares.certidao_data = new Date(this._new_dados_complementares.certidao_data.split('/')[2]+'-'+this._new_dados_complementares.certidao_data.split('/')[1]+'-'+this._new_dados_complementares.certidao_data.split('/')[0]);
        }
        if (this._new_dados_complementares.certidao_data > new Date()) {
          reject('A data de emissão da certidão não pode ser no futuro')
          return
        }
        if (this._new_dados_complementares.certidao_data.toDateString() == 'Invalid Date') {
          reject('Insira uma data de registro válida para a certidão!')
          return
        }
        this._new_dados_complementares.certidao_data = this._new_dados_complementares.certidao_data.toISOString()
        this._new_dados_complementares.certidao_data = this._new_dados_complementares.certidao_data.split('T')[0]
      }
      if(this._new_dados_complementares.identidade_data){
        if(this._new_dados_complementares.identidade_data && this._new_dados_complementares.identidade_data != undefined){
          this._new_dados_complementares.identidade_data = new Date(this._new_dados_complementares.identidade_data.split('/')[2]+'-'+this._new_dados_complementares.identidade_data.split('/')[1]+'-'+this._new_dados_complementares.identidade_data.split('/')[0]);
        }
        if (this._new_dados_complementares.identidade_data > new Date()) {
          reject('A data de emissão da identidade não pode ser uma data no futuro')
          return
        }
        if (this._new_dados_complementares.identidade_data.toDateString() == 'Invalid Date') {
          reject('Insira uma data de emissão válida para a identidade!')
          return
        }
        this._new_dados_complementares.identidade_data = this._new_dados_complementares.identidade_data.toISOString()
        this._new_dados_complementares.identidade_data = this._new_dados_complementares.identidade_data.split('T')[0]
      }
      if(this._new_dados_complementares.carteira_trabalho_data){
        if(this._new_dados_complementares.carteira_trabalho_data && this._new_dados_complementares.carteira_trabalho_data!=undefined){
          this._new_dados_complementares.carteira_trabalho_data = new Date(this._new_dados_complementares.carteira_trabalho_data.split('/')[2]+'-'+this._new_dados_complementares.carteira_trabalho_data.split('/')[1]+'-'+this._new_dados_complementares.carteira_trabalho_data.split('/')[0]);
        }
        if (this._new_dados_complementares.carteira_trabalho_data > new Date()) {
          reject('A data de emissão da carteira de trabalho não pode ser no futuro')
          return
        }
        if (this._new_dados_complementares.carteira_trabalho_data.toDateString() == 'Invalid Date') {
          reject('Insira uma data de emissão válida para a carteira de trabalho!')
          return
        }
        this._new_dados_complementares.carteira_trabalho_data = this._new_dados_complementares.carteira_trabalho_data.toISOString()
        this._new_dados_complementares.carteira_trabalho_data = this._new_dados_complementares.carteira_trabalho_data.split('T')[0]
      }
      if(this._new_dados_complementares.habilitacao_data){
        if(this._new_dados_complementares.habilitacao_data && this._new_dados_complementares.habilitacao_data!=undefined){
          this._new_dados_complementares.habilitacao_data = new Date(this._new_dados_complementares.habilitacao_data.split('/')[2]+'-'+this._new_dados_complementares.habilitacao_data.split('/')[1]+'-'+this._new_dados_complementares.habilitacao_data.split('/')[0]);
        }
        if (this._new_dados_complementares.habilitacao_data > new Date()) {
          reject('A data de emissão da habilitação não pode ser no futuro')
          return
        }
        if (this._new_dados_complementares.habilitacao_data.toDateString() == 'Invalid Date') {
          reject('Insira uma data de emissão válida para a carteira de habilitação!')
          return
        }
        this._new_dados_complementares.habilitacao_data = this._new_dados_complementares.habilitacao_data.toISOString()
        this._new_dados_complementares.habilitacao_data = this._new_dados_complementares.habilitacao_data.split('T')[0]
      }
      if(this._new_dados_complementares.passaporte_data_emissao){
        if(this._new_dados_complementares.passaporte_data_emissao && this._new_dados_complementares.passaporte_data_emissao!=undefined){
          this._new_dados_complementares.passaporte_data_emissao = new Date(this._new_dados_complementares.passaporte_data_emissao.split('/')[2]+'-'+this._new_dados_complementares.passaporte_data_emissao.split('/')[1]+'-'+this._new_dados_complementares.passaporte_data_emissao.split('/')[0]);
        }
        if (this._new_dados_complementares.passaporte_data_emissao > new Date()) {
          reject('A data de emissão do passaporte não pode ser no futuro')
          return
        }
        if (this._new_dados_complementares.passaporte_data_emissao.toDateString() == 'Invalid Date') {
          reject('Insira uma data de emissão válida para o passaporte!')
          return
        }
        this._new_dados_complementares.passaporte_data_emissao = this._new_dados_complementares.passaporte_data_emissao.toISOString()
        this._new_dados_complementares.passaporte_data_emissao = this._new_dados_complementares.passaporte_data_emissao.split('T')[0]
      }
      if(this._new_dados_complementares.passaporte_data_validade){
        if(this._new_dados_complementares.passaporte_data_validade && this._new_dados_complementares.passaporte_data_validade!=undefined){
          this._new_dados_complementares.passaporte_data_validade = new Date(this._new_dados_complementares.passaporte_data_validade.split('/')[2]+'-'+this._new_dados_complementares.passaporte_data_validade.split('/')[1]+'-'+this._new_dados_complementares.passaporte_data_validade.split('/')[0]);
        }
        if (this._new_dados_complementares.passaporte_data_validade < new Date()) {
          reject('A data de validade do passaporte não pode ser uma data passada')
          return
        }
        if (this._new_dados_complementares.passaporte_data_validade.toDateString() == 'Invalid Date') {
          reject('Insira uma data de validade válida para o passaporte!')
          return
        }
        this._new_dados_complementares.passaporte_data_validade = this._new_dados_complementares.passaporte_data_validade.toISOString()
        this._new_dados_complementares.passaporte_data_validade = this._new_dados_complementares.passaporte_data_validade.split('T')[0]
      }
      if(this._new_dados_complementares.passaporte_data_emissao && this._new_dados_complementares.passaporte_data_validade){
        if(this._new_dados_complementares.passaporte_data_validade < this._new_dados_complementares.passaporte_data_emissao){
          reject('A data de emissão do passaporte não pode ser posterior a sua data de validade')
        }
      }
      resolve('Ajuste de data feito corretamente');
    })
  }

  ajustarFormatoDataObter = () => {
    return new Promise((resolve) => {
      if(this.dados_complementares.certidao_data){
        var data = this.dados_complementares.certidao_data.split('T')[0]
        this.dados_complementares.certidao_data = data.split('-')[2]+'/'+data.split('-')[1]+'/'+data.split('-')[0];
      }
      if(this.dados_complementares.identidade_data){
        var data = this.dados_complementares.identidade_data.split('T')[0]
        this.dados_complementares.identidade_data = data.split('-')[2]+'/'+data.split('-')[1]+'/'+data.split('-')[0];
      }
      if(this.dados_complementares.carteira_trabalho_data){
        var data = this.dados_complementares.carteira_trabalho_data.split('T')[0]
        this.dados_complementares.carteira_trabalho_data = data.split('-')[2]+'/'+data.split('-')[1]+'/'+data.split('-')[0];
      }
      if(this.dados_complementares.habilitacao_data){
        var data = this.dados_complementares.habilitacao_data.split('T')[0]
        this.dados_complementares.habilitacao_data = data.split('-')[2]+'/'+data.split('-')[1]+'/'+data.split('-')[0];
      }
      if(this.dados_complementares.passaporte_data_emissao){
        var data = this.dados_complementares.passaporte_data_emissao.split('T')[0]
        this.dados_complementares.passaporte_data_emissao = data.split('-')[2]+'/'+data.split('-')[1]+'/'+data.split('-')[0];
      }
      if(this.dados_complementares.passaporte_data_validade){
        var data = this.dados_complementares.passaporte_data_validade.split('T')[0]
        this.dados_complementares.passaporte_data_validade = data.split('-')[2]+'/'+data.split('-')[1]+'/'+data.split('-')[0];
      }
      resolve('Conversão ocorreu corretamente')
    })
  }

  ajustaFormatoDataComparar = () => {
    return new Promise<any>((resolve, reject) => {
      //console.log(this._old_dados_complementares)
      if(this._old_dados_complementares.certidao_data){
        this._old_dados_complementares.certidao_data = new Date(this._old_dados_complementares.certidao_data.split('/')[2]+'-'+this._old_dados_complementares.certidao_data.split('/')[1]+'-'+this._old_dados_complementares.certidao_data.split('/')[0]);
        this._old_dados_complementares.certidao_data = this._old_dados_complementares.certidao_data.toISOString()
        this._old_dados_complementares.certidao_data = this._old_dados_complementares.certidao_data.split('T')[0]
      }
      
      if(this._old_dados_complementares.identidade_data){
        this._old_dados_complementares.identidade_data = new Date(this._old_dados_complementares.identidade_data.split('/')[2]+'-'+this._old_dados_complementares.identidade_data.split('/')[1]+'-'+this._old_dados_complementares.identidade_data.split('/')[0]);
        this._old_dados_complementares.identidade_data = this._old_dados_complementares.identidade_data.toISOString();
        this._old_dados_complementares.identidade_data = this._old_dados_complementares.identidade_data.split('T')[0]
      }

      if(this._old_dados_complementares.carteira_trabalho_data){
        this._old_dados_complementares.carteira_trabalho_data = new Date(this._old_dados_complementares.carteira_trabalho_data.split('/')[2]+'-'+this._old_dados_complementares.carteira_trabalho_data.split('/')[1]+'-'+this._old_dados_complementares.carteira_trabalho_data.split('/')[0]);
        this._old_dados_complementares.carteira_trabalho_data = this._old_dados_complementares.carteira_trabalho_data.toISOString()
        this._old_dados_complementares.carteira_trabalho_data = this._old_dados_complementares.carteira_trabalho_data.split('T')[0]
      }

      if(this._old_dados_complementares.habilitacao_data){
        this._old_dados_complementares.habilitacao_data = new Date(this._old_dados_complementares.habilitacao_data.split('/')[2]+'-'+this._old_dados_complementares.habilitacao_data.split('/')[1]+'-'+this._old_dados_complementares.habilitacao_data.split('/')[0]);
        this._old_dados_complementares.habilitacao_data = this._old_dados_complementares.habilitacao_data.toISOString()
        this._old_dados_complementares.habilitacao_data = this._old_dados_complementares.habilitacao_data.split('T')[0]
      }

      if(this._old_dados_complementares.passaporte_data_emissao){
        this._old_dados_complementares.passaporte_data_emissao = new Date(this._old_dados_complementares.passaporte_data_emissao.split('/')[2]+'-'+this._old_dados_complementares.passaporte_data_emissao.split('/')[1]+'-'+this._old_dados_complementares.passaporte_data_emissao.split('/')[0]);
        this._old_dados_complementares.passaporte_data_emissao = this._old_dados_complementares.passaporte_data_emissao.toISOString()
        this._old_dados_complementares.passaporte_data_emissao = this._old_dados_complementares.passaporte_data_emissao.split('T')[0]
      }

      if(this._old_dados_complementares.passaporte_data_validade){
        this._old_dados_complementares.passaporte_data_validade = new Date(this._old_dados_complementares.passaporte_data_validade.split('/')[2]+'-'+this._old_dados_complementares.passaporte_data_validade.split('/')[1]+'-'+this._old_dados_complementares.passaporte_data_validade.split('/')[0]);
        this._old_dados_complementares.passaporte_data_validade = this._old_dados_complementares.passaporte_data_validade.toISOString()
        this._old_dados_complementares.passaporte_data_validade = this._old_dados_complementares.passaporte_data_validade.split('T')[0]
      }
      
      resolve('Ajuste de data feito corretamente');
    })
  }

  ///===>> endereços <<<=== \\\

  obterEndereco = (uuid) => {
    this.enderecoFuncionario = {}
    this.cepEncontrado = false;
    this.possuiEndereco = false
    this.apiService.Get(`Funcionarios/${uuid}/Endereco`).then(
      result => {
        // console.log(result)
        if(result){
          this.enderecoFuncionario = result;
          Object.assign(this._old_enderecoFuncionario, result)
          //console.log(this.enderecoFuncionario)
          this.loading = false
          this.possuiEndereco = true
          if (this.possuiEndereco) {
            this.cepEncontrado = true;
          }
          // console.log(this.possuiEndereco)
        }
        this.loading = false;
      },
      error => {
        Swal.fire('Oops', error, 'error')
        this.loading = false;
      }
    )
  }

  validarCamposEndereco = () => {
    return new Promise((resolve, reject) => {
      if(this._new_enderecoFuncionario.municipio_nome == '') {
        this._new_enderecoFuncionario.municipio_uuid = '';
        this._new_enderecoFuncionario.localidade = '';
      }
      if(this._new_enderecoFuncionario.cep == ''){
        delete this._new_enderecoFuncionario.cep; 
      }
      if(this._new_enderecoFuncionario.estado_uuid == ''){
        delete this._new_enderecoFuncionario.estado_uuid;
      }
      if(this._new_enderecoFuncionario.bairro == ''){
        delete this._new_enderecoFuncionario.bairro;
      }
      if(this._new_enderecoFuncionario.municipio_nome == ''){
        delete this._new_enderecoFuncionario.municipio_nome;
      }
      if(this._new_enderecoFuncionario.logradouro == ''){
        delete this._new_enderecoFuncionario.logradouro;
      }
      if(this._new_enderecoFuncionario.numero == ''){
        delete this._new_enderecoFuncionario.numero;
      }
      if(this._new_enderecoFuncionario.complemento == ''){
        delete this._new_enderecoFuncionario.complemento;
      }
      if((this._new_enderecoFuncionario.cep && 
          this._new_enderecoFuncionario.estado_uuid && 
          this._new_enderecoFuncionario.bairro &&
          this._new_enderecoFuncionario.municipio_nome &&
          this._new_enderecoFuncionario.logradouro) ||
          (!this._new_enderecoFuncionario.cep && 
          !this._new_enderecoFuncionario.estado_uuid && 
          !this._new_enderecoFuncionario.bairro &&
          !this._new_enderecoFuncionario.municipio_nome &&
          !this._new_enderecoFuncionario.logradouro &&
          !this._new_enderecoFuncionario.numero &&
          !this._new_enderecoFuncionario.complemento)){
            resolve('Endereço foi corretamente informado')
      }      
      else reject('Favor informar os dado de endereço completo');
    })
  }

  salvarCamposEndereco = (val: any) => {
    Object.assign(this._new_enderecoFuncionario, this.enderecoFuncionario);
    // console.log(this._new_enderecoFuncionario)
    // console.log(this.enderecoFuncionario)
    // console.log(this._old_enderecoFuncionario)
    // console.log(this.possuiEndereco)
    if(this.possuiEndereco){
      this.validarCamposEndereco().then(
        result => {
          this.comparaDadosEndereco().then(
            result => {
              if (result == 1){
                this.atualizarDadosEndereco().then(
                  result=>{
                    this.loading = false;
                    //console.log(result)
                    Swal.fire('Sucesso', result.toString(), 'success').then(res => {
                      if(res['value']==true){
                        this.mudaAba(val);
                      }
                    })
                  },
                  error =>{
                    this.loading = false;            
                    Swal.fire('Oops', error, 'error');
                  }
                );
              }
              if (result == 2) {
                this.mudaAba(val);
              }
            },
            error => {
              //console.log(result)
              this.mudaAba(val);
            }
          )},
        error => {
          Swal.fire('Oops', error, 'error');
          this.loading = false;
        }
      )
    }
    if(!this.possuiEndereco && Object.keys(this.enderecoFuncionario).length == 0) {
      if (val == this.abaID){
        Swal.fire('Atenção', 'Não há dados de endereço para salvar', 'warning')
        return
      }
      else {
        this.mudaAba(val);
        return
      }
    }
    if(!this.possuiEndereco && Object.keys(this.enderecoFuncionario).length > 0){
      console.log('aqui')
      if(val != this.abaID){
        Swal.fire({
          title:'Atenção',
          text: 'Há dados que não foram salvos. Gostaria de mudar de aba assim mesmo?',
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Salvar os dados',
          cancelButtonText: 'Mudar de aba',
          reverseButtons: true
        }).then(response => {
          console.log(response)
          if (response['dismiss']){
            this.mudaAba(val);
            this.loading = false;
            return
          }
          if (response['value']==true){
            this.validarCamposEndereco().then(
              result => {
                this.manterDadosEndereco().then(
                  result=>{
                    //console.log(result)
                    this.loading = false;
                    Swal.fire('Sucesso', result.toString(), 'success').then(response =>{
                      if(response['value']==true)
                        this.mudaAba(val);
                      return
                    })
                  },
                  error =>{
                    this.loading = false;            
                    Swal.fire('Oops', error.toString(), 'error');
                  }
                );
              },
              error => {
                this.loading = false;    
                Swal.fire('Oops', error.toString(), 'error');
              }
            )
          }
        })
      }
      else{
        this.validarCamposEndereco().then(
          result => {
            this.manterDadosEndereco().then(
              result=>{
                //console.log(result)
                this.loading = false;
                Swal.fire('Sucesso', result.toString(), 'success').then(response => {
                  if(response['value']==true)
                    this.mudaAba(val);
                  return
                })
              },
              error =>{
                this.loading = false;            
                Swal.fire('Oops', error.toString(), 'error');
              }
            );
          },
          error => {
            this.loading = false;    
            Swal.fire('Oops', error.toString(), 'error');
          }
        )
      }
    }
  }

  manterDadosEndereco = () => {
    this.loading = true;
    var uuid = this.funcionario.uuid;
    return new Promise((resolve, reject) => {
        this.apiService.Post(`Funcionarios/${uuid}/Endereco`, this._new_enderecoFuncionario).then(
          result => {
            resolve('O endereço foi cadastrado com sucesso'),
            this.obterEndereco(uuid)
          },
          error => 
            reject(error.error.mensagem)
        )
    })
  }

  atualizarDadosEndereco = () => {
    this.loading = true;
    var uuid = this.funcionario.uuid;
    return new Promise((resolve, reject) => {
        this.apiService.Put3(`Funcionarios/${uuid}/Endereco`, this._new_enderecoFuncionario).then(
          result => {
            resolve('O endereço foi atualizado com sucesso'),
            this.obterEndereco(uuid)
          },
          error => 
            reject(error)
        )
    })
  }

  addObrigatorioEndereco = () => {
    if(!this.enderecoFuncionario.cep && 
      !this.enderecoFuncionario.logradouro &&
      !this.enderecoFuncionario.numero &&
      !this.enderecoFuncionario.complemento &&
      !this.enderecoFuncionario.bairro &&
      !this.enderecoFuncionario.municipio_nome &&
      !this.enderecoFuncionario.municipio_uuid &&
      !this.enderecoFuncionario.estado_nome &&
      !this.enderecoFuncionario.estado_uuid){
        this.enderecoObrigatorio = false;
      }
    else this.enderecoObrigatorio = true;
  }

  comparaDadosEndereco = () => {
    return new Promise((resolve) => {
      console.log(this._new_enderecoFuncionario)
      console.log(this._old_enderecoFuncionario)
      delete this._new_enderecoFuncionario.localidade;
      delete this._new_enderecoFuncionario.ddd;
      delete this._new_enderecoFuncionario.gia;
      delete this._new_enderecoFuncionario.ibge;
      delete this._new_enderecoFuncionario.siafi;
      delete this._new_enderecoFuncionario.uf;
      for (var p in this._new_enderecoFuncionario){
        if(this._new_enderecoFuncionario.hasOwnProperty(p)){
          if (this._new_enderecoFuncionario[p] !== this._old_enderecoFuncionario[p]){            
            console.log(this._new_enderecoFuncionario[p])
            console.log(this._old_enderecoFuncionario[p])
            Swal.fire({
              title: 'Oops',
              text: 'Há alterações não salvas que podem ser perdidas, gostaria de salvá-las?', 
              type: 'warning',
              confirmButtonText: 'Salvar alterações',
              showCancelButton: true,
              cancelButtonText: 'Não salvar',
              cancelButtonColor: '#d33'
            })
            .then((res) => {
              if(res['value']==true){
                resolve(1)
              }
              else{
                resolve(2)
              }
            })
            return
          }
        }
      }
      for (var p in this._old_enderecoFuncionario){
        if(this._old_enderecoFuncionario.hasOwnProperty(p)){
          if (this._old_enderecoFuncionario[p] !== this._new_enderecoFuncionario[p]){            
            console.log(this._new_enderecoFuncionario[p])
            console.log(this._old_enderecoFuncionario[p])
            Swal.fire({
              title: 'Oops',
              text: 'Há alterações não salvas que podem ser perdidas, gostaria de salvá-las?', 
              type: 'warning',
              confirmButtonText: 'Salvar alterações',
              showCancelButton: true,
              cancelButtonText: 'Não salvar',
              cancelButtonColor: '#d33'
            })
            .then((res) => {
              if(res['value']==true){
                resolve(1)
              }
              else{
                resolve(2)
              }
            })
            return
          }
        }
      }
      resolve(2)
    })
  }

  getEstados = () => {
    this.apiService.Get(`Estados`).then(res =>{
      this.estados = res;
    })
  }
  
  getMunicipios = (e) => {
    //console.log(e)
    this.apiService.Get(`Estados/${e.uuid}/Municipios`).then(res => {
      this.listaMunicipios = res;
      //console.log(this.listaMunicipios)
      for (let m of this.listaMunicipios){
        if (m.nome.toUpperCase() === this.enderecoFuncionario.municipio_nome.toUpperCase()){
          this.enderecoFuncionario.municipio_uuid = m.uuid
          this.enderecoFuncionario.municipio_nome = m.nome
          //console.log(this.enderecoFuncionario)
        }
      }
    })
  }

  estadoSelecionado = () => {
    //console.log(this.enderecoFuncionario)
    this.addObrigatorioEndereco();
  }

  validaEndereco = (cep) => {
    this.loading = true;
    this.buscaCep(cep).then(res => {
      //console.log(res)  
      if (Object.keys(res).length > 1){
        this.cepEncontrado = true;
        this.enderecoFuncionario = res;
        this.enderecoFuncionario.municipio_nome = res['localidade'];
        for (let e of this.estados){
          if (e.sigla === res['uf']){
            this.enderecoFuncionario.estado_uuid = e.uuid
            this.getMunicipios(e)
          }
        }
        this.loading = false;
      }
      else{
        this.cepEncontrado = false;
        this.enderecoFuncionario = [];
        this.enderecoFuncionario.cep = cep;
        this.loading = false;
        Swal.fire('Oops!', 'CEP não encontrado', 'error');
      }
    })
  }

  buscaCep = (data) =>{    
    var cep = data.replace(/\D/g,'');
    return new Promise((res, rej) => {
      this.viaCEP
      .buscarPorCep(cep)
      .pipe(catchError((error: CEPError) => {  
              return error.message
        }))
      .subscribe((endereco: Endereco) => {
        res(endereco);
      });
    })    
  }

  /// ===>>> unidades & setores <<<=== \\\

  obterUnidades = (uuid) => {
    this.listaUnidades = []
    this.apiService.Get(`Unidades?ativo=true`).then(
      res => {
        if (res){
          this.listaUnidades = res;
          this.loading = false;
          this.loadApiSetor = false;
          this.obterSetoresFuncionario();
        }
        else
        error => {
          this.loading = false;
          Swal.fire('Oops', error, 'error')
        }
      },
      error => {
        this.loading = false;
        if(error.mensagem){
          Swal.fire('Oops', error.mensagem, 'error')
        }
        if(error.error.mensagem){
          Swal.fire('Oops', error.error.mensagem, 'error')
        }
        Swal.fire('Oops', 'Não foi possível carregar as unidades', 'error')
      }
    )
  }
  
  obterSetores = (uuid) => {
    this.loading = true;
    this.apiService.Get(`Unidades/${uuid}/Setores?ativo=true`).then(
      res => {
        if (res){
          this.listaSetores = res;
          this.loading = false;
        }
        else
        error => {
          this.loading = false;
          Swal.fire('Oops', error, 'error')
        }
      },
      error => {
        this.loading = false;
        if(error.mensagem){
          Swal.fire('Oops', error.mensagem, 'error')
        }
        if(error.error.mensagem){
          Swal.fire('Oops', error.error.mensagem, 'error')
        }
        Swal.fire('Oops', 'Não foi possível carregar os setores', 'error')
      }
    )
  }
  
  obterSetoresFuncionario = () => {
    this.loading = true;
    var uuid = this.funcionario.uuid;
    this.apiService.Get(`Funcionarios/${uuid}/Setor`).then(
      res =>{
        if (res){
          this.listaSetoresFuncionario = res;
          this.loading = false;
        }
        else
          error => {
            Swal.fire('Oops', error, 'error')
            this.loading = false;
          }
      },
      error => {
        this.loading = false;
        if(error.mensagem){
          Swal.fire('Oops', error.mensagem, 'error')
        }
        if(error.error.mensagem){
          Swal.fire('Oops', error.error.mensagem, 'error')
        }
        Swal.fire('Oops', 'Não foi possível carregar os setores deste funcionário', 'error')
      }
    )
  }

  manterSetoresFuncionario = (unidade, setor,setor_admitido_em,setor_desvinculado_em) => {
    this.loading = true;
    this.validaCampoSetor(unidade, setor,setor_admitido_em,setor_desvinculado_em).then(
      result => {
        var obj = {
          'unidade_uuid': result['u'],
          'setor_uuid': result['s'],
          'admitido_em': result['a'],
          'desvinculado_em': result['d'],
          'ativo': true
        }
        var uuid = this.funcionario.uuid
        this.apiService.Post(`Funcionarios/${uuid}/Setor`, obj).then(res =>{
            if (res) {
              Swal.fire('Sucesso', 'Setor cadastrado com sucesso', 'success')
            this.obterSetoresFuncionario();
            this.loading = false;
            }
          },
          err => {
            //console.log(err)
            this.loading = false;
            if(err.error.mensagem){
              Swal.fire('Oops', err.error.mensagem, 'error');
            }
            else{
              Swal.fire('Oops', 'Não foi possível realizar o cadastro', 'error');
            }
          })
        }
    )
  }

  removeSetor = (setor) => {
    var atualizar: any = []

    Object.assign(atualizar, setor)

    Swal.fire({
      title: 'Atenção',
      text: 'Deseja remover o setor? Esta ação é IRREVERSÍVEL!', 
      type: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#d33'
    }).then(res => {
      if(res['value']==true){
        this.loading=true;
        var funcionario = this.funcionario.uuid;
        var today = new Date();
        // atualizar.desvinculado_em = 
        // today.getFullYear()+"-"+(("0"+(today.getMonth())).slice(-2))+"-"+(("0"+(today.getDate())).slice(-2));
        let dia = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
        let mes = today.getMonth()+1 < 10 ? `0${today.getMonth()+1}` : today.getMonth();
        let ano = today.getFullYear();
        atualizar.desvinculado_em = `${ano}-${mes}-${dia}`;
        var obj = {
          setor_uuid: atualizar.setor_uuid,
          ativo: false,
          admitido_em: atualizar.admitido_em,
          desvinculado_em: atualizar.desvinculado_em,
        }
        this.apiService.Put3(`Funcionarios/${funcionario}/Setor?funcionario_setor_uuid=${atualizar.uuid}`, obj).then(res => {
          if (res) {
            this.loading=false;
            Swal.fire('Sucesso', 'Desvinculação realizada com sucesso', 'success').then(res => {
              if(res['value']==true){
                this.obterSetoresFuncionario()
              }
            })
          }
          else
            error => {
              Swal.fire('Oops', error, 'error')
              this.loading = false;
              this.obterSetoresFuncionario()
            }
        },
        err => {
          Swal.fire('Oops', err, 'error')
              this.loading = false;
              this.obterSetoresFuncionario()
        }
        )
      }
    })
  }

  navegaAbas = (val) => {
    this.listaSetores = []
    this.mudaAba(val);
  }

  validaCampoSetor = (u, s, a, d) => {
    return new Promise((resolve, reject) => {
      var ret: any = {}
      if (!u.uuid || u.uuid === undefined || u.uuid === '') {
        reject('É preciso informar uma unidade')
        return
      }
      else {
        ret['u'] = u.uuid
      }
      if (!s.uuid || s.uuid === undefined || s.uuid === '') {
        reject('É preciso informar um setor')
        return
      }
      else {
        ret['s'] = s.uuid
      }
      if (!a || a === '') {
        reject('É preciso informar uma data de admissão')
        return
      }

      if (a) {
        a = new Date(a.split('/')[2]+'-'+a.split('/')[1]+'-'+a.split('/')[0]);
        if(a.toDateString() == 'Invalid Date'){
          reject('É preciso informar uma data de admissão válida')
        }
        if(a > new Date()){          
          reject('Não é possível que a data de admissão seja uma data no futuro')
        }
        else {
          a = a.toISOString()
          a = a.split('T')[0]
          ret['a'] = a
        }
      }

      if (a){
        a = new Date(a.split("/")[2]+"-"+a.split("/")[1]+"-"+a.split("/")[0]);
        if(a.toDateString() == 'Invalid Date'){
          reject('É preciso informar uma data de admissão válida')
        }
        if(a > new Date()){          
          reject('Não é possível que a data de admissão seja uma data no futuro')
        }
        else {
          a = a.toISOString()
          a = a.split('T')[0]
          ret['a'] = a
        }
      }
      if (d) {
        d = new Date(d.split('/')[2]+'-'+d.split('/')[1]+'-'+d.split('/')[0]);
        if(new Date(a) > new Date(d)){
          reject('A data de desvinculação não pode ser anterior a data de admissão')
          return
        }
        else{
          if(d.toDateString() == 'Invalid Date'){
            reject('É preciso informar uma data de desvinculação válida')
          }
          if(d > new Date()){          
            reject('Não é possível que a data de desvinculação seja uma data no futuro')
          }
          else {
            d = d.toISOString()
            d = d.split('T')[0]
            //console.log(d)
            ret['d'] = d
          }
        }
      }
      resolve(ret)
    })
  }

  /// ===>>> cargo <<<=== \\\

  obterListaCargos = () => {
    this.listaCargos = [];
    this.listaVinculos = [];
    this.desvinculado_em = '';
    this.carga_horaria = '';
    this.apiService.Get(`Cargos?ativo=true`).then(
      res => {
        //console.log(res)
        if (res) {
          this.listaCargos = res;
          this.obterVinculos().then(
            res => {
              this.obterListaFuncionarioCargos().then(
                res => {
                  this.loading = false;
                }
              )
            }
          );
        }
        else
        error => {
          this.loading = false;
          Swal.fire('Oops', error.error.mensagem, 'error')
        }
      },
      error => {
        this.loading = false;
        if(error.mensagem){
          Swal.fire('Oops', error.mensagem, 'error')
        }
        if(error.error.mensagem){
          Swal.fire('Oops', error.error.mensagem, 'error')
        }
        Swal.fire('Oops', 'Não foi possível carregar os cargos', 'error')
      }
    )
  }

  obterListaFuncionarioCargos = () =>{
    return new Promise((resolve, reject) => {
      this.apiService.Get(`Funcionarios/${this.funcionario.uuid}/Cargo`).then(
        res => {
          if (res) {
            this.listaCargosFuncionario = res
              resolve('Lista de funcionários carregada')
          }
          else
          error => {
            reject(error)
          }
        },
        error => {
          this.loading = false;
          if(error.mensagem){
            Swal.fire('Oops', error.mensagem, 'error')
          }
          if(error.error.mensagem){
            Swal.fire('Oops', error.error.mensagem, 'error')
          }
          Swal.fire('Oops', 'Não foi possível carregar a lista de cargos deste funcionário', 'error')
        }
      )
    })
  }

  obterVinculos = () => {
    return new Promise((resolve, reject) => {
      this.apiService.Get(`Vinculos?ativo=true`).then(
        res => {
          if (res) {
            this.listaVinculos = res;
            resolve('Lista de vínculos carregada')
          }
          else
          error => {
            reject(error)
          }
        },
        error => {
          this.loading = false;
          if(error.mensagem){
            Swal.fire('Oops', error.mensagem, 'error')
          }
          if(error.error.mensagem){
            Swal.fire('Oops', error.error.mensagem, 'error')
          }
          Swal.fire('Oops', 'Não foi possível carregar os vínculos deste funcionário', 'error')
        }
      )
    })
  }

  manterVinculo = (cargo, vinculo, admitido_em, carga_horaria, desvinculado_em) => {
    this.loading = true;
    //console.log(admitido_em)
    this.validaCamposCargo(cargo, vinculo, admitido_em, carga_horaria,desvinculado_em).then(
      res => {
        var status = true;
        var dataDesvinculo = '';
        if (desvinculado_em){
          status = false;
          dataDesvinculo = res['d']
        }
        var obj = {
          "cargo_uuid": res['c'],
          "vinculo_uuid": res['v'],
          "carga_horaria": res['h'],
          "ativo": status,
          "admitido_em": res['a'],
          "desvinculado_em": dataDesvinculo
        }
        //console.log(obj)
        if (obj.desvinculado_em == ''){
          delete obj['desvinculado_em']
        }
        //console.log(obj)
        this.apiService.Post(`Funcionarios/${this.funcionario.uuid}/Cargo`, obj).then(
          res => {
            if (res) {
              this.obterListaFuncionarioCargos().then(res => {
                this.loading = false;
                Swal.fire('Sucesso', 'Vínculo cadastrado com sucesso', 'success');
              })
            }
          },
          err => {
            //console.log(err)
            this.loading = false;
            if(err.error.mensagem){
              Swal.fire('Oops', err.error.mensagem, 'error');
            }
            else{
              Swal.fire('Oops', 'Não foi possível realizar o cadastro', 'error');
            }
          }
        )
      },
      error => {
        Swal.fire('Oops', error.toString(), 'error');
        this.loading = false;
      })
  }

  validaCamposCargo = (c, v, a, h, d) => {
    return new Promise((resolve, reject) => {
      var ret = {}
      if (!c.uuid || c.uuid === undefined || c.uuid == null) {
        reject('Cargo não informado');
        return
      }
      else {
        ret['c'] = c.uuid
      }
      if (!v.uuid || v.uuid === undefined || v.uuid == null) {
        reject('Vínculo não informado');
        return
      }
      else {
        ret['v'] = v.uuid
      }
      if (!a) {
        reject('A data de admissão informada não está correta')
        return
      }
      if (a) {
        a = new Date(a.split('/')[2]+'-'+a.split('/')[1]+'-'+a.split('/')[0]);
        if(a.toDateString() == 'Invalid Date'){
          reject('É preciso informar uma data de admissão válida')
        }
        if(a > new Date()){          
          reject('Não é possível que a data de admissão seja uma data no futuro')
        }
        else {
          a = a.toISOString()
          a = a.split('T')[0]
          ret['a'] = a
        }
      }
      if (!h) {
        reject('Carga horária não foi informada')
        return
      }
      else {
        ret['h'] = h
      }
      if (d) {
        d = new Date(d.split('/')[2]+'-'+d.split('/')[1]+'-'+d.split('/')[0]);
        if(a > d){
          reject('A data de admissão não pode ser posterior a data de desvinculação')
          return
        }
        else{
          if(d.toDateString() == 'Invalid Date'){
            reject('É preciso informar uma data de desvinculação válida')
          }
          if(d > new Date()){          
            reject('Não é possível que a data de desvinculação seja uma data no futuro')
          }
          else {
            d = d.toISOString()
            d = d.split('T')[0]
            //console.log(d)
            ret['d'] = d
          }
        }
      }
      resolve(ret)
    })
  }

  removeCargoVinculo = (cargo) => {
      Swal.fire({
        title: 'Atenção',
        text: 'Deseja desvincular o cargo? A data de desvinculação será a data de hoje. Esta ação é IRREVERSÍVEL!', 
        type: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#d33'
      }).then(res => {
      if(res['value']==true){
        this.loading=true;
        var funcionario = this.funcionario.uuid;
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var today = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
        today = today.split('T')[0];
        var txt = `funcionario_cargo_uuid=${cargo.uuid}&desvinculado_em=${today}`;
        var obj = {
          //funcionario_cargo_uuid: cargo.uuid,
          cargo_uuid: cargo.cargo_uuid,
          vinculo_uuid: cargo.vinculo_uuid,
          carga_horaria: cargo.carga_horaria,
          ativo: false,
          admitido_em: cargo.admitido_em,
        }
        // console.log(obj)
        this.apiService.Put3(`Funcionarios/${funcionario}/Cargo?funcionario_cargo_uuid=${cargo.uuid}`, obj).then(
        // this.apiService.Put3(`Funcionarios/${funcionario}/Cargo?${txt}`, '').then(
          res => {
            if (res) {
              this.loading=false;
              Swal.fire('Sucesso', 'Cargo desvinculado com sucesso', 'success').then(
                res => {
                  if (res['value']==true){                
                    this.loading=true;
                    this.obterListaFuncionarioCargos().then(res => {                  
                      if(res) {
                        this.loading=false;
                      }
                    })
                  }
                },
                error => {
                  this.loading = false;
                  Swal.fire('Oops', error, 'error')
                  return
                }
              )
            }
            else
            error => {
              if (error.status == 404) {
                this.loading = false;
                Swal.fire('Oops', error, 'error')
                return
              }
              this.loading = false;
              Swal.fire('Oops', error, 'error')
            }
          },
          error => {
            this.loading = false;
            Swal.fire('Oops', error.error.mensagem, 'error')
            return
          }
        )
      }
    })
  }

  /// === >>> lançamentos ===

  obterListaLancamentos = () => {
    this.loading = true;
    this.apiService.Get(`Funcionarios/${this.funcionario.uuid}/Lancamentos`).then(
      result => {
        if(result) {
          this.listaLancamentosFuncionario = result;
          if(Object.keys(this.listaLancamentosFuncionario).length == 0){
            this.exibeMsgListaLancamentos = true
          }
          else {
            this.exibeMsgListaLancamentos = false;
          }
          //console.log(this.exibeMsgListaLancamentos)
          this.loading = false;
        }
      },
      error => {
        this.loading = false;
        Swal.fire('Oops', error.error.mensagem, 'error');
      }
    )
  }

  abrirApontamento = () => {
    let dialogRef = this.dialog.open(ModalApontamentoComponent, {
      data: { funcionario: this.funcionario.uuid }
    });
    dialogRef.afterClosed().subscribe(
      result => {
        this.obterListaLancamentos()
      }
    );
  }

  editarLancamento = (lancamento) => {
    let dialogRef = this.dialog.open(ModalApontamentoComponent, {
      data: { funcionario: this.funcionario.uuid, lancamento: lancamento.uuid }
    });
    dialogRef.afterClosed().subscribe(
      result => {
        this.obterListaLancamentos()
      }
    );
  }

  removeLancamento = (lancamento) =>{
    Swal.fire({
      title: 'Atenção',
      type: 'warning',
      text: 'Deseja remover este lançamento? Esta ação é IRREVERSÍVEL!',
      showCancelButton: true,      
      cancelButtonColor: '#d33'
    })
    .then(
      res => {
        if(res['value']==true){
          this.loading = true;
          this.apiService.Delete2(`Funcionarios/${this.funcionario.uuid}/Lancamento?lancamento_uuid=${lancamento.uuid}`).then(
            result => {
              this.loading = false
              Swal.fire('Sucesso', 'Lançamento removido com sucesso', 'success').then(
                result => {
                  if (result['value']==true){
                    this.obterListaLancamentos();
                  }
                }
              )
            }
          )
        }
      }
    )
  }
}
