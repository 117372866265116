import { Component, OnInit, NgModule, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { ApiService } from '../../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";
import { AppComponent } from '../../../app.component';
import { ModalBiometriaComponent } from '../../../components/modal-biometria/modal-biometria.component';


@Component({
  selector: 'app-funcionario',
  templateUrl: './funcionario.component.html',
  styleUrls: ['./funcionario.component.css']
})
export class FuncionarioComponent implements OnInit {

  novoFuncionario: boolean = false;
  funcionario: any = {};
  cargo: any = {};
  unidade: any = {};
  setor: any = {};
  vinculo: any = {};
  carga_horaria: any = null;
  loading = false;
  visualizando: any = false;
  listaFuncionarios: any = [];
  listaCargos: any = [];
  listaVinculos: any = [];
  listaUnidades: any = [];
  listaSetores: any = [];
  listaTiposBusca: any = [];
  tipoBusca: any = { id: 0 };
  imgSrc: any = '../../../../assets/img/default.jpg';
  pesquisa: any = { unidade: 0, cargo: 0, vinculo: 0 };
  element: HTMLImageElement;

  currentUser: any = {};

  screenHeight: any;
  screenWidth: any;
  hideTextBtn: boolean = false;

  //partes de testes de funcionários
  cabecalho: any = {};
  listaPaginas: any = [];
  indice_selecionado: any = 0;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1190) {
      this.hideTextBtn = true;
    }
    else {
      this.hideTextBtn = false;
    }
  }

  ngOnInit() {
    //this.obterFuncionarios();
    this.atual(1, {});
    this.obterUnidades();
    this.obterVinculos();
    this.obterCargos();
    this.obterTiposBusca();
  }


  obterTiposBusca() {
    this.listaTiposBusca = [
      { id: 1, nome: 'CPF' },
      { id: 2, nome: 'Nome' },
      { id: 3, nome: 'Matricula' },
      { id: 4, nome: 'Data Admissão' },
    ];
  }

  getFormatterDate(item) {
    return this.global.dateFormater(item);
  }

  add() {
    this.novoFuncionario = true;
    this.funcionario = {};
    this.cargo = {};
    this.setor = {};
    this.unidade = {};
    this.vinculo = {};
    this.carga_horaria = null;
    this.funcionario.ativo = true;
  }

  back() {
    this.atual(1, {});
    //this.obterFuncionarios();
    this.novoFuncionario = false;
    this.imgSrc = '../../../../assets/img/default.jpg';
    this.funcionario = {};
    this.cargo = {};
    this.unidade = {};
    this.setor = {};
    this.vinculo = {};
    this.carga_horaria = null;
  }

  addSetor(unidade, setor) {
    if (unidade == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o unidade!'
      });
      return;
    }
    if (setor == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o setor!'
      });
      return;
    }

    if (this.funcionario.setores == null) {
      this.funcionario.setores = [];
    }
    this.funcionario.setores.push({
      unidade_uuid: unidade.uuid,
      unidade_nome: unidade.nome,
      setor_uuid: setor.uuid,
      setor_nome: setor.nome,
      ativo: setor.ativo
    });
  }

  addCargoVinculo(cargo, vinculo, carga_horaria) {
    if (cargo == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o cargo!'
      });
      return;
    }
    if (vinculo == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o vinculo!'
      });
      return;
    }
    if (carga_horaria == null || carga_horaria == "" || carga_horaria <= 0) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a carga horária mensal!'
      });
      return;
    }
    if (this.funcionario.cargos == null) {
      this.funcionario.cargos = [];
    }
    this.funcionario.cargos.push({
      cargo_uuid: cargo.uuid,
      cargo_nome: cargo.nome,
      vinculo_uuid: vinculo.uuid,
      vinculo_nome: vinculo.nome,
      carga_horaria: carga_horaria
    });
  }

  removeCargoVinculo(cargo_vinculo) {
    var index = this.getIndex(cargo_vinculo);
    if (index != null) {
      this.funcionario.cargos.splice(index, 1);
    }
  }

  getIndex(cargo_vinculo) {
    for (var aux in this.funcionario.cargos) {
      if (this.funcionario.cargos[aux].cargo_uuid == cargo_vinculo.cargo_uuid && this.funcionario.cargos[aux].vinculo_uuid == cargo_vinculo.vinculo_uuid) {
        return aux;
      }
    }
    return null;
  }

  removeSetor(unidade_setor) {
    var index = this.getIndexSetor(unidade_setor);
    if (index != null) {
      this.funcionario.setores.splice(index, 1);
    }
  }

  getIndexSetor(unidade_setor) {
    for (var aux in this.funcionario.setores) {
      if (this.funcionario.setores[aux].setor_uuid == unidade_setor.setor_uuid) {
        return aux;
      }
    }
    return null;
  }

  valida(funcionario) {
    if (funcionario.ativo == null) {
      funcionario.ativo = false;
    }
    if (funcionario.nome == null || funcionario.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome!'
      });
      return false;
    }
    if (funcionario.sobrenome == null || funcionario.sobrenome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o sobrenome!'
      });
      return false;
    }
    if (funcionario.cpf == null || funcionario.cpf == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o CPF!'
      });
      return false;
    }
    //if (funcionario.matricula == null || funcionario.matricula == "") {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira a matricula!'
    //  });
    //  return false;
    //}
    if (funcionario.data_nascimento_str == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a data de nascimento!'
      });
      return false;
    }
    let data_nascimento_ = new Date(funcionario.data_nascimento_str.split("/")[2] + "-" + funcionario.data_nascimento_str.split("/")[1] + "-" + funcionario.data_nascimento_str.split("/")[0] + "T00:00");
    if (data_nascimento_ > new Date()) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a data de nascimento válida!'
      });
      return false;
    }

    if (data_nascimento_.toDateString() == 'Invalid Date') {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a data de nascimento válida!'
      });
      return false;
    }

    funcionario.data_nascimento = data_nascimento_;

    if (funcionario.admitido_em_str == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a data de admissão!'
      });
      return false;
    }



    let data_admitido_ = new Date(funcionario.admitido_em_str.split("/")[2] + "-" + funcionario.admitido_em_str.split("/")[1] + "-" + funcionario.admitido_em_str.split("/")[0] + "T00:00");
    if (data_admitido_.toDateString() == 'Invalid Date') {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a data de admissão válida!'
      });
      return false;
    }
    funcionario.admitido_em = data_admitido_;
    //if (funcionario.demitido_em == null) {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira a data de demissão!'
    //  });
    //  return false;
    //}
    if ((funcionario.telefone1_ddd != null && funcionario.telefone1_ddd != "") && (funcionario.telefone1_ddd < 10 || funcionario.telefone1_ddd > 99)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'DDD Inválido do telefone!'
      });
      return false;
    }
    //if (funcionario.telefone1_numero == null || funcionario.telefone1_numero <= 0) {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira o telefone!'
    //  });
    //  return false;
    //}
    if ((funcionario.telefone2_ddd != null && funcionario.telefone2_ddd != "") && (funcionario.telefone2_ddd < 10 || funcionario.telefone2_ddd > 99)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'DDD Inválido do celular!'
      });
      return false;
    }
    //if (funcionario.telefone2_numero == null || funcionario.telefone2_numero <= 0) {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira o celular!'
    //  });
    //  return false;
    //}
    //if (funcionario.email == null || funcionario.email == "") {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira o e-mail!'
    //  });
    //  return false;
    //}
    if (funcionario.cargos == null || funcionario.cargos.length == 0) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira ao menos 1 cargo!'
      });
      return false;
    }
    if (funcionario.setores == null || funcionario.setores.length == 0) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira ao menos 1 setor!'
      });
      return false;
    }
    return true;
  }

  edit(funcionario) {
    this.novoFuncionario = true;
    this.obterFuncionario(funcionario);
    //this.funcionario = funcionario;
    this.visualizando = false;

  }

  view(funcionario) {
    this.edit(funcionario);
    this.visualizando = true;
  }

  obterFuncionarios() {
    this.loading = true;
    this.apiService.Get("Funcionarios").then(
      result => {
        this.listaFuncionarios = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterCargos() {
    this.loading = true;
    this.apiService.Get("Cargos?ativo=true").then(
      result => {
        this.listaCargos = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get("Unidades").then(
      result => {
        this.listaUnidades = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterSetores(unidade_id) {
    if (unidade_id == 0) {
      this.listaSetores = null;
      return;
    }
    this.loading = true;
    this.apiService.Get("Unidades/" + unidade_id + "/Setores?ativo=true").then(
      result => {
        this.listaSetores = result;
        this.pesquisa.setor = '';
        this.setor = null;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterVinculos() {
    this.loading = true;
    this.apiService.Get("Vinculos").then(
      result => {
        this.listaVinculos = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  submit() {
    if (this.valida(this.funcionario)) {
      this.loading = true;
      if (this.funcionario.usar_nome_social == undefined) {
        this.funcionario.usar_nome_social = false;
      }
      if (this.funcionario.usar_nome_social == false) {
        this.funcionario.nome_social = "";
      }
      if (this.funcionario.usar_nome_social == true && this.funcionario.nome_social == "" ||
        this.funcionario.usar_nome_social == true && this.funcionario.nome_social == null) {
        this.funcionario.nome_social = "";
        this.funcionario.usar_nome_social = false;
      }
      console.log(this.funcionario);
      if (this.funcionario.uuid != null) {
        this.apiService.Put("Funcionarios", this.funcionario).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Funcionário salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      } else {
        this.apiService.Post("Funcionarios", this.funcionario).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Funcionário salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      }
    }
    else {
      return;
    }
  }

  obterFuncionario(funcionario) {
    this.loading = true;
    this.apiService.GetOne("Funcionarios", funcionario.uuid).then(
      result => {
        this.funcionario = result;
        this.funcionario.data_nascimento_str = this.global.dateFormater(result['data_nascimento']);
        this.funcionario.admitido_em_str = this.global.dateFormater(result['admitido_em']);
        this.loading = false;
        if (this.funcionario.foto_perfil != null) {
          this.imgSrc = this.funcionario.foto_perfil_link;
          let img = document.getElementById('imgFuncionario');
          img.onerror = function () {
            document.getElementById('imgFuncionario').setAttribute('src', '../../../../assets/img/default.jpg');
          }
        } else {
          this.imgSrc = '../../../../assets/img/default.jpg';
        }
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  desativar(funcionario) {
    this.loading = true;
    this.apiService.desativar("Funcionarios", funcionario.uuid)
      .then(
        result => {
          this.loading = false;
          this.atual(1, {});
          //this.obterFuncionarios();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  ativar(funcionario) {
    this.loading = true;
    this.apiService.ativar("Funcionarios", funcionario.uuid)
      .then(
        result => {
          this.loading = false;
          this.atual(1, {});
          //this.obterFuncionarios();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  formata_CPF(cpf) {
    return this.global.formataCPF(cpf);
  }

  ///paginação
  obterListaFuncionarios(indice, pesquisa) {
    let nomePesquisa = '';

    if (pesquisa == null) {
      pesquisa = {};
    }
    if (pesquisa.unidade == 0 || pesquisa.unidade == null) {
      pesquisa.unidade = '';
    }
    if (pesquisa.setor == 0 || pesquisa.setor == null) {
      pesquisa.setor = '';
    }
    if (pesquisa.cargo == 0 || pesquisa.cargo == null) {
      pesquisa.cargo = '';
    }
    if (pesquisa.vinculo == 0 || pesquisa.vinculo == null) {
      pesquisa.vinculo = '';
    }

    if (this.tipoBusca.id == 1 && (pesquisa.nome_pesquisa != "" && pesquisa.nome_pesquisa != null)) {
      nomePesquisa = pesquisa.nome_pesquisa;
    }

    if (this.tipoBusca.id == 2 && (pesquisa.nome_pesquisa != "" && pesquisa.nome_pesquisa != null)) {
      nomePesquisa = pesquisa.nome_pesquisa;
    }
    if (this.tipoBusca.id == 3 && (pesquisa.nome_pesquisa != "" && pesquisa.nome_pesquisa != null)) {
      nomePesquisa = pesquisa.nome_pesquisa;
    }
    if (this.tipoBusca.id == 4 && (pesquisa.nome_pesquisa != "" && pesquisa.nome_pesquisa != null)) {
      let dia = pesquisa.nome_pesquisa.substring(0, 2);
      let mes = pesquisa.nome_pesquisa.substring(2, 4);
      let ano = pesquisa.nome_pesquisa.substring(4, 8);
      nomePesquisa = ano + "-" + mes + "-" + dia;
    }

    this.loading = true;
    var resultado: any;
    //this.apiService.Get("Funcionarios?pagina=" + indice).then(
    this.apiService.Get("Funcionarios?ativo=" + "" + "&pagina=" + indice + "&query=" + nomePesquisa + "&unidade=" + pesquisa.unidade + "&setor=" + pesquisa.setor + "&cargo=" + pesquisa.cargo + "&vinculo=" + pesquisa.vinculo).then(
      result => {
        resultado = result;
        this.listaFuncionarios = resultado.conteudo;
        this.cabecalho = {
          total_paginas: resultado.total_paginas,
          pagina_atual: resultado.pagina_atual,
          quantidade_pagina: 50,
          quantidade_total: resultado.quantidade_total
        };
        this.listaPaginas = [];
        for (var i = 1; i <= this.cabecalho.total_paginas; i++) {
          this.listaPaginas.push(i);
        }
        this.loading = false;

        //this.obterVinculos();
        //this.obterCargos();
        //this.obterUnidades();
        //this.obterTiposBusca();
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  primeira(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  anterior(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  atual(indice, pesquisa) {
    if (this.indice_selecionado != indice) {
      this.indice_selecionado = indice;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    } else {
      this.indice_selecionado = indice;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  proxima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  ultima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  mudarTipoPesquisa() {
    this.pesquisa.nome_pesquisa = "";
  }

  limparCampos(pesquisa, tipoBusca) {
    this.indice_selecionado = "";
    this.pesquisa.nome_pesquisa = "";
    this.pesquisa.unidade = "";
    pesquisa.setor = "";
    pesquisa.cargo = "";
    pesquisa.vinculo = "";
    this.tipoBusca.id = 0;
    this.listaSetores = null;
  }

  visualizarBiometria(func) {
    let dialogRef = this.dialog.open(ModalBiometriaComponent, {
      data: {
        func: func
      }
    });
  }
}
