import { Component, OnInit, NgModule } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { Global } from '../../../global';
import { ApiService } from '../../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-vinculo',
  templateUrl: './vinculo.component.html',
  styleUrls: ['./vinculo.component.css']
})
export class VinculoComponent implements OnInit {

  novoVinculo: boolean = false;
  vinculo: any = {};
  loading = false;
  visualizando: any = false;
  listaVinculos: any = [];

  currentUser = {};

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      //this.instituicaoID = this.currentUser.instituicao_logada.instituicao_codigo;
      //this.obtervinculos();
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterVinculos();
  }

  add() {
    this.novoVinculo = true;
    this.vinculo = {};
    this.vinculo.ativo = true;
  }

  back() {
    this.novoVinculo = false;
    this.obterVinculos();
    this.vinculo = {};
  }

  valida(vinculo) {
    if (vinculo.ativo == null) {
      vinculo.ativo = false;
    }
    if (vinculo.nome == null || vinculo.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome!'
      });
      return false;
    }
    return true;
  }

  edit(vinculo) {
    this.novoVinculo = true;
    this.obterVinculo(vinculo);
    //this.vinculo = vinculo;
    this.visualizando = false;
  }

  obterVinculos() {
    this.loading = true;
    this.apiService.Get("Vinculos").then(
      result => {
        this.listaVinculos = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  desativar(usuario) {
    this.loading = true;
    this.apiService.desativar("Vinculos", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterVinculos();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  ativar(usuario) {
    this.loading = true;
    this.apiService.ativar("Vinculos", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterVinculos();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  submit() {
    if (this.valida(this.vinculo)) {
      this.loading = true;
      if (this.vinculo.uuid != null) {
        this.apiService.Put("Vinculos", this.vinculo).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Vinculo salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      } else {
        this.apiService.Post("Vinculos", this.vinculo).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Vínculo salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      }
    }
    else {
      return;
    }
  }

  obterVinculo(vinculo) {
    this.loading = true;
    this.apiService.GetOne("Vinculos", vinculo.uuid).then(
      result => {
        this.vinculo = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }



}
