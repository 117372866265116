import { Component, OnInit, NgModule, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';
import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";


@Component({
  selector: 'app-modal-acompanhamento',
  templateUrl: './modal-acompanhamento.component.html',
  styleUrls: ['./modal-acompanhamento.component.css']
})

export class ModalAcompanhamentoComponent implements OnInit {

  ativo: any;

  acompanhamento: any = {};
  escala_acompanhamento: any = {};

  diario_freq: any = {};
  currentUser: any = {};

  setor: any = {};
  turno: any = {};
  gerente: any = {};
  listaSetores: any = [];
  listaTurnos: any = [];
  listaGerentes: any = [];
  loading = false;
  visualizando: any = false;
  edita: any = false;
  errorGetData: boolean = false;
  ajuste: any = {};
  acaoPossivel: any = '0';
  ajustePonto: boolean = false;
  realizarAjuste: boolean = false;
  ajusteEdicaoPonto: boolean = false;

  @Input() funcionarioEscolhido = '';
  @Input() unidadeEscolhida = '';
  @Input() setorEscolhido = '';

  salvarAjustePonto: boolean = false;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,    
    public dialogRef: MatDialogRef<ModalAcompanhamentoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

      this.acompanhamento = data;
      this.obterDiarioFrequencia();

    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    
  }

  fechaModal() {
    this.dialogRef.close();
  }

  obterDiarioFrequencia() {
    this.loading = true;
    this.apiService.Get("DiarioFrequencias/Funcionarios/" + this.acompanhamento.funcionario_id + "?data=" + this.acompanhamento.data).then(      
      result => {
        this.escala_acompanhamento = result;
        this.errorGetData = false;

        let dia = parseInt(this.acompanhamento.data.split('-')[2])
        let mes = parseInt(this.acompanhamento.data.split('-')[1])
        let ano = parseInt(this.acompanhamento.data.split('-')[0])

        this.acompanhamento.data_formatada = `${dia < 10 ? '0'+dia : dia}/${mes < 10 ? "0"+ mes : mes}/${ano}`;

        for (let escala of this.escala_acompanhamento.escalas) {
          escala.data_hora = escala.data.split('T',2)[0]+'T'+escala.hora
        }

        var escalas = this.escala_acompanhamento.escalas;
        for (let escala of escalas){
          if (escala.presenca_em){
            escala.editarPonto = false;
          }
        }
        if (this.escala_acompanhamento.pontos.length == 0){
          this.salvarAjustePonto = true;
        }
        if (this.escala_acompanhamento.pontos.length < this.escala_acompanhamento.escalas.length){
          this.salvarAjustePonto = true;
        }
        if (this.escala_acompanhamento.pontos.length >= this.escala_acompanhamento.escalas.length){
          this.salvarAjustePonto = false;
        }
        // fim do acerto de horas
        this.loading = false;
      },      
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
        this.errorGetData = true;
      }
    );
  }

  obterTurnos() {
    this.loading = true;
    this.apiService.Get("Turno?ativo=true").then(
      result => {
        this.listaTurnos = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  onlyNumber = (e) => {
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105) || charCode == 8) {      
        return true;
    }
    return false;
  }

  // Não esta sendo usado
  salvarAjuste = (ponto) =>{
    this.loading = true;
    // VALIDAÇÃO DOS CAMPOS
    if (ponto.hora == '' || ponto.hora == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'É preciso informar um horário para o ajuste de ponto'
      });
      this.loading = false;
      return
    }
    var splits = ponto.hora.split(':', 3)
    if ((splits[0] < 0 || splits[0] > 24 || splits[0] == '' || splits[0] == null) || 
        (splits[1] < 0 || splits[1] > 59 || splits[1] == '' || splits[1] == null)){
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'É preciso informar um horário válido para o ajuste de ponto'
          });
          this.loading = false;
          return
    }
    if (ponto.justificativa == '' || ponto.justificativa == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'É preciso informar uma justificativa para o ajuste de ponto'
      });
      this.loading = false;
      return
    }
    else{
      var data = this.acompanhamento.data.split('/',3);
      var Obj = { 
        'presenca_em': data[0]+' '+ponto.hora_justificativa,
        'status': 2,
        'funcionario_uuid': localStorage.getItem('funcionarioEscolhido'),
        'unidade_uuid': localStorage.getItem('unidadeEscolhida'),
        'setor_uuid': localStorage.getItem('setorEscolhido'),
        'enviado_sms': true,
        'enviado_email': true,
        'ajustado': true,
        'justificativa_ajuste': ponto.justificativa,
        'registro': ''
      }
      this.apiService.Post('Pontos', Obj).then(res => {
        this.loading = false;
        Swal.fire({
          type: 'success',
          title: 'Sucesso!',
          text: 'Ajuste de ponto realizado com sucesso!'
        });
        ponto.editarPonto = !ponto.editarPonto;
        this.obterDiarioFrequencia();
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        else {
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
          ponto.editarPonto = !ponto.editarPonto;
          this.obterDiarioFrequencia();
        }
      }
      )
    }
  }

  deletePonto = (ponto) => {
    this.loading = true;
    var Obj = ponto.ponto_uuid;
    this.apiService.Delete(`Pontos`, Obj).then(result => {
      if (result == true) {
        this.loading = false;
        Swal.fire({
          type: 'success',
          title: 'Sucesso',
          text: 'Ponto removido com sucesso'
        });
        this.obterDiarioFrequencia();
      }
    },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        else {
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      }
    );
  }

  realizarAjustePonto = (ponto) => {
    ponto.editarPonto = !ponto.editarPonto;
    for (let item of this.escala_acompanhamento.escalas){
      if (item.editarPonto){
        this.salvarAjustePonto = true;
      }
    }    
    var x = this.escala_acompanhamento.escalas;
    for (var i = 0; i < x.length; i++){
      if (ponto.ponto_uuid === x[i].ponto_uuid){
        ponto.justificativa = x[i].justificativa_ajuste;
        ponto.hora_justificativa = x[i].presenca_em.split('T')[1];
        return;
      }
    }
  }

  // Não esta sendo usado
  salvarEdicaoAjustePonto = (ponto) => {
    this.loading = true;
    var data = this.acompanhamento.data.split('/',3);
      var Obj = { 
        'presenca_em': data[2]+'-'+data[1]+'-'+data[0]+' '+ponto.hora_justificativa,
        'justificativa_ajuste': ponto.justificativa
      }
    this.apiService.Patch_body(`Pontos/${ponto.uuid}/Presenca`, Obj).then(result => {
      if (result){
        this.loading = false;
        Swal.fire({
          type: 'success',
          title: 'Sucesso',
          text: 'Ponto alterado com sucesso'
        });
        this.realizarAjustePonto(ponto);
        this.obterDiarioFrequencia();
      }
    })
  }

  cancelarEdicaoPonto = () => {
    this.ajustePonto = true; //não pode ajustar
    this.ajusteEdicaoPonto = false;
  }

  cancelarAjustePonto = (ponto) => {
    ponto.editarPonto = !ponto.editarPonto;
    if (this.escala_acompanhamento.escalas.some(elem => elem.editarPonto == true)){
      this.salvarAjustePonto = true;
    }
    else{
      this.salvarAjustePonto = false;
    }
    if (this.escala_acompanhamento.pontos.length < this.escala_acompanhamento.escalas.length){
      this.salvarAjustePonto = true;
    }
    var x = this.escala_acompanhamento.escalas;
    // console.log(x)
    for (var i = 0; i < x.length; i++){
      if (ponto.uuid === x[i].uuid){
        if (ponto.justificativa != x[i].justificativa_ajuste) {
            ponto.justificativa = x[i].justificativa_ajuste;
        }
        if (ponto.hora_justificativa != x[i].hora) {
            ponto.hora_justificativa = x[i].hora;
        }
        else if (ponto.justificativa === x[i].justificativa_ajuste && ponto.hora_justificativa === x[i].hora) {
          return;
        }
      }
    }
  }

  salvarTodasEscalas = (escalas) => {
    try {
      let objEscalasSalvar = [];
      escalas.forEach((ponto) => {
        this.loading = true;
        // VALIDAÇÃO DOS CAMPOS
        // if (ponto.editarPonto == false && ponto.ponto_registrado == true) {
        //   return
        // }

        if ((ponto.hora_justificativa == '' || ponto.hora_justificativa == null)
          && (ponto.justificativa == '' || ponto.justificativa == null) && (ponto.ponto_registrado == false)) {
            return
          }
         
          let justificativa_texto = '';
          let justificativa_hora = '';
          if ((ponto.ponto_registrado && ponto.editarPonto) || (!ponto.ponto_registrado && !ponto.editarPonto)) {

            if (ponto.hora_justificativa == '' || ponto.hora_justificativa == null){
              throw `É preciso informar um horário nos campos para o ajuste de ponto`
            }
            
            var splits = ponto.hora_justificativa.split(':', 3)
            if ((splits[0] < 0 || splits[0] > 24 || splits[0] == '' || splits[0] == null) ||
              (splits[1] < 0 || splits[1] > 59 || splits[1] == '' || splits[1] == null)) {
              throw `É preciso informar um horário válido nos campos para o ajuste de ponto`
            }

            if (ponto.justificativa == '' || ponto.justificativa == null){
              throw `É preciso informar uma justificativa nos campos para o ajuste de ponto`
            }
              justificativa_texto = ponto.justificativa
              justificativa_hora = ponto.hora_justificativa
              if(ponto.hora_justificativa.length == 5){
                justificativa_hora = ponto.hora_justificativa+':00'
              }
            }else{
              justificativa_texto = ponto.justificativa_ajuste
              justificativa_hora = ponto.presenca_em
            }
          var data = this.acompanhamento.data.split('/', 3);
          let objSalvar = {            
            'status': 2,
            'funcionario_uuid': localStorage.getItem('funcionarioEscolhido'),
            'unidade_uuid': localStorage.getItem('unidadeEscolhida'),
            'setor_uuid': localStorage.getItem('setorEscolhido'),
            'enviado_sms': true,
            'enviado_email': true,
            'ajustado': true,
            'justificativa_ajuste': justificativa_texto,
            'registro': '',
            'escala_intervalo_uuid': ponto.uuid //posição do item
          }
          
          if(new Date(justificativa_hora).toDateString() === 'Invalid Date'){
            objSalvar['presenca_em'] = ponto.data.split('T',2)[0] + 'T' + justificativa_hora
          }
          else {
            objSalvar['presenca_em'] = justificativa_hora
          }

          if (ponto.ponto_uuid != null) {
            Object.assign(objSalvar, { uuid: ponto.ponto_uuid });
          }
          objEscalasSalvar.push(objSalvar);
      });
      if(objEscalasSalvar.length == 0){
        throw `É preciso preencher os campos para salvar`
      }
      this.apiService.Post('Pontos/Diversos', objEscalasSalvar).then(res => {
        this.loading = false;
        Swal.fire({
          type: 'success',
          title: 'Sucesso!',
          text: 'Ajuste de ponto realizado com sucesso!'
        });
        this.obterDiarioFrequencia();
      },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          else {
            this.loading = false;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
            escalas.forEach(escala => {
              escala.editarPonto = !escala.editarPonto;
            });
            this.obterDiarioFrequencia();
          }
        }
      )
    } catch (error) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: error
      });
      this.loading = false;
    }
  }

}

