import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { Global } from '../../global';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-aprovar-fotos',
  templateUrl: './aprovar-fotos.component.html',
  styleUrls: ['./aprovar-fotos.component.css']
})
export class AprovarFotosComponent implements OnInit {
  loading = false;

  filtro: any = {};
  listaClientes: any = [];
  listaUnidades: any = [];
  listaPontos: any = [];
  hoje: any = '';

  currentUser:any = {};

  constructor(
    private apiService: ApiService,
    public global: Global,
    private router: Router
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.global.token_acesso = this.currentUser.token_acesso;
    console.log(this.global)

    var today = new Date();
    this.hoje = 
    (('0'+today.getDate().toString()).slice(-2))+'/'+
    ((('0'+(today.getMonth()+1)).toString()).slice(-2))+
    '/'+today.getFullYear();

    this.removeMenu();
    this.getClientes();
  }

  removeMenu = () => {
    var burg = document.getElementsByClassName('sidebar-toggle-box')[0];
    burg.remove();
  }

  getClientes = () => {
    this.loading = true;
    this.apiService.Get(`AjustePonto/Cliente`).then(result => {
      this.listaClientes = result;
      this.loading = false;
    })
  }

  getUnidades = (uid) => {
    this.loading = true;
    this.filtro.unidade = '';
    this.apiService.Get(`AjustePonto/Cliente/${uid}/Unidade`).then(result =>{
      this.listaUnidades = result;
      this.filtro.data = this.hoje;
      this.loading = false;
    })
  }

  dateMask = () => {
    var input = this.filtro.data;
    this.filtro.data = input.replace(/\D/g, "");

    this.filtro.data = this.filtro.data.replace(/(\d{2})(\d)/, "$1/$2");
    this.filtro.data = this.filtro.data.replace(/(\d{2})(\d{1})/, "$1/$2");
    this.filtro.data = this.filtro.data.replace(/(\d{2})(\d{4})$/, "$1/$2");
  }

  getPontos = () => {
    this.loading = true;
    if (this.filtro.cliente == '' || this.filtro.cliente == null){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Selecione um cliente'
      });
      return
    }
    if (this.filtro.unidade == '' || this.filtro.unidade == null){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Selecione uma unidade'
      });
      return
    }
    if (this.filtro.data == '' || this.filtro.data == null){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma data'
      });
      return
    }
    var dia = this.filtro.data.split('/')[0];
    var mes = this.filtro.data.split('/')[1];
    var ano = this.filtro.data.split('/')[2];
    if (dia < 1 || dia > 31 || mes < 1 || mes > 12){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma data válida'
      });
      return
    }
    
    var Obj = `unidade_uuid=${this.filtro.unidade}&data=${ano}-${mes}-${dia}`;
    this.listaPontos = [];
    this.apiService.Get(`AjustePonto/Ponto?${Obj}`).then(result => {
      for (let r of result as Array<1>){
        if (r['registro']){
          this.listaPontos.push(r);
        }
      }
      this.loading = false;
    },
    err => {
      if (err.status == 401) {
        this.router.navigate(["/login"]);
        return;
      }
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: err.error.mensagem
      });
    })
  }

  updateStatus = (ponto,val) => {    
    this.loading = true;
    if(!ponto.justificativa){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'É obrigatório informar uma justificativa'
      });
      return
    }
    if (val === 1){
      var s = "REPROVADO"
    }
    if (val === 2){
      var s = "REPROVADO"
    }
    var Obj = {
      status: s,
      justificativa_ajuste: ponto.justificativa
    }
    this.apiService.Patch_body(`AjustePonto/Ponto/${ponto.uuid}`, Obj).then(result =>{
      Swal.fire({
        type: 'success',
        title: 'Sucesso!',
        text: 'O ponto foi ajustado com sucesso'
      });
      this.getPontos()
      this.loading = false;
    },
    err => {
      if (err.status == 401) {
        this.router.navigate(["/login"]);
        return;
      }
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: err.error.mensagem
      });
    })
  }
}
