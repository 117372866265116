import { Component, OnInit, NgModule, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { ApiService } from '../../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";
import { AppComponent } from '../../../app.component';
import { ModalBiometriaComponent } from '../../../components/modal-biometria/modal-biometria.component';


@Component({
  selector: 'app-listar-funcionarios',
  templateUrl: './listar-funcionarios.component.html',
  styleUrls: ['./listar-funcionarios.component.css']
})
export class ListarFuncionariosComponent implements OnInit {

  novoFuncionario: boolean = false;
  funcionario: any = {};
  cargo: any = {};
  unidade: any = {};
  setor: any = {};
  vinculo: any = {};
  carga_horaria: any = null;
  loading = false;
  visualizando: any = false;
  listaFuncionarios: any = [];
  listaCargos: any = [];
  listaVinculos: any = [];
  listaUnidades: any = [];
  listaSetores: any = [];
  listaTiposBusca: any = [];
  tipoBusca: any = { id: 0 };
  imgSrc: any = '../../../../assets/img/default.jpg';
  pesquisa: any = { unidade: 0, cargo: 0, vinculo: 0 };
  element: HTMLImageElement;

  currentUser: any = {};

  screenHeight: any;
  screenWidth: any;
  hideTextBtn: boolean = false;

  //partes de testes de funcionários
  cabecalho: any = {};
  listaPaginas: any = [];
  indice_selecionado: any = 0;
  totalPaginas = 0;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
    this.onResize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1190) {
      this.hideTextBtn = true;
    }
    else {
      this.hideTextBtn = false;
    }
  }

  ngOnInit() {
    this.obterFuncionarios();
    // this.atual(1, {});
    this.obterUnidades();
    this.obterVinculos();
    this.obterCargos();
    this.obterTiposBusca();
  }


  obterTiposBusca() {
    this.listaTiposBusca = [
      { id: 1, nome: 'CPF' },
      { id: 2, nome: 'Nome' },
      { id: 3, nome: 'Matricula' },
      { id: 4, nome: 'Data Admissão' },
      { id: 5, nome: 'Status' }
    ];
  }

  getFormatterDate(item) {
    return this.global.dateFormater(item);
  }

  edit(funcionario) {
    this.novoFuncionario = true;
    this.obterFuncionario(funcionario);
    this.funcionario = funcionario;
    this.visualizando = false;

  }

  view(funcionario) {
    this.edit(funcionario);
    this.visualizando = true;
  }

  obterFuncionarios() {
    this.loading = true;
    this.apiService.Get("Funcionarios?ativo=&pagina=1").then(
      result => {
        this.listaFuncionarios = result['conteudo'];
        this.totalPaginas = result['total_paginas'];
        this.cabecalho = {
          total_paginas: result['total_paginas'],
          pagina_atual: result['pagina_atual'],
          quantidade_pagina: result['quantidade_pagina'],
          quantidade_total: result['quantidade_total']
        };
        this.loading = false;
      },
      err => {
         if (err.status == 401) {
           this.router.navigate(["/login"]);
           return;
         }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterCargos() {
    this.loading = true;
    this.apiService.Get("Cargos?ativo=true").then(
      result => {
        this.listaCargos = result;
        this.listaCargos.unshift({'nome': 'TODOS','uuid':''})
        this.loading = false;
      },
      err => {
         if (err.status == 401) {
           this.router.navigate(["/login"]);
           return;
         }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get("Unidades").then(
      result => {
        this.listaUnidades = result;
        this.listaUnidades.unshift({'nome': 'TODAS','uuid':''});
        this.loading = false;
      },
      err => {
         if (err.status == 401) {
           this.router.navigate(["/login"]);
           return;
         }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterSetores(unidade_id) {
    if (unidade_id == 0) {
      this.listaSetores = null;
      return;
    }
    this.loading = true;
    this.apiService.Get("Unidades/" + unidade_id + "/Setores?ativo=true").then(
      result => {
        this.listaSetores = result;
        this.listaSetores.unshift({'nome': 'TODOS','uuid':''});
        this.pesquisa.setor = '';
        this.setor = null;
        this.loading = false;
      },
      err => {
         if (err.status == 401) {
           this.router.navigate(["/login"]);
           return;
         }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterVinculos() {
    this.loading = true;
    this.apiService.Get("Vinculos").then(
      result => {
        this.listaVinculos = result;
        this.listaVinculos.unshift({'nome': 'TODOS','uuid':''});
        this.loading = false;
      },
      err => {
         if (err.status == 401) {
           this.router.navigate(["/login"]);
           return;
         }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterFuncionario(funcionario) {
    this.loading = true;
    this.apiService.GetOne("Funcionarios", funcionario.uuid).then(
      result => {
        this.funcionario = result;
        this.funcionario.data_nascimento_str = this.global.dateFormater(result['data_nascimento']);
        this.funcionario.admitido_em_str = this.global.dateFormater(result['admitido_em']);
        this.loading = false;
        if (this.funcionario.foto_perfil != null) {
          this.imgSrc = this.funcionario.foto_perfil_link;
          let img = document.getElementById('imgFuncionario');
          img.onerror = function () {
            document.getElementById('imgFuncionario').setAttribute('src', '../../../../assets/img/default.jpg');
          }
        } else {
          this.imgSrc = '../../../../assets/img/default.jpg';
        }
      },
      err => {
         if (err.status == 401) {
           this.router.navigate(["/login"]);
           return;
         }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  desativar(funcionario) {
    this.loading = true;
    this.apiService.desativar("Funcionarios", funcionario.uuid)
      .then(
        result => {
          this.loading = false;
          this.atual(1, {});
          //this.obterFuncionarios();
        },
        err => {
          this.loading = false;
           if (err.status == 401) {
             this.router.navigate(["/login"]);
             return;
           }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  ativar(funcionario) {
    this.loading = true;
    this.apiService.ativar("Funcionarios", funcionario.uuid)
      .then(
        result => {
          this.loading = false;
          this.atual(1, {});
          //this.obterFuncionarios();
        },
        err => {
          this.loading = false;
           if (err.status == 401) {
             this.router.navigate(["/login"]);
             return;
           }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  formata_CPF(cpf) {
    return this.global.formataCPF(cpf);
  }

  ///paginação
  obterListaFuncionarios(indice, pesquisa) {
    let nomePesquisa = '';

    if (pesquisa == null) {
      pesquisa = {};
    }
    if(this.tipoBusca.id != 5){
      pesquisa.status = '';
    }
    if(pesquisa.status === undefined || pesquisa.status === null) {
      pesquisa.status = '';
    }
    if (pesquisa.unidade == 0 || pesquisa.unidade == null) {
      pesquisa.unidade = '';
    }
    if (pesquisa.setor == 0 || pesquisa.setor == null) {
      pesquisa.setor = '';
    }
    if (pesquisa.cargo == 0 || pesquisa.cargo == null) {
      pesquisa.cargo = '';
    }
    if (pesquisa.vinculo == 0 || pesquisa.vinculo == null) {
      pesquisa.vinculo = '';
    }

    if (this.tipoBusca.id == 1 && (pesquisa.nome_pesquisa != "" && pesquisa.nome_pesquisa != null)) {
      nomePesquisa = pesquisa.nome_pesquisa;
    }

    if (this.tipoBusca.id == 2 && (pesquisa.nome_pesquisa != "" && pesquisa.nome_pesquisa != null)) {
      nomePesquisa = pesquisa.nome_pesquisa;
    }
    if (this.tipoBusca.id == 3 && (pesquisa.nome_pesquisa != "" && pesquisa.nome_pesquisa != null)) {
      nomePesquisa = pesquisa.nome_pesquisa;
    }
    if (this.tipoBusca.id == 4 && (pesquisa.nome_pesquisa != "" && pesquisa.nome_pesquisa != null)) {
      let dia = pesquisa.nome_pesquisa.substring(0, 2);
      let mes = pesquisa.nome_pesquisa.substring(2, 4);
      let ano = pesquisa.nome_pesquisa.substring(4, 8);
      nomePesquisa = ano + "-" + mes + "-" + dia;
    }

    this.loading = true;
    var resultado: any;
    //this.apiService.Get("Funcionarios?pagina=" + indice).then(
    this.apiService.Get("Funcionarios?ativo=" + pesquisa.status + "&pagina=" + indice + "&query=" + nomePesquisa + "&unidade=" + pesquisa.unidade + "&setor=" + pesquisa.setor + "&cargo=" + pesquisa.cargo + "&vinculo=" + pesquisa.vinculo).then(
      result => {
        resultado = result;
        this.listaFuncionarios = resultado.conteudo;
        this.cabecalho = {
          total_paginas: resultado.total_paginas,
          pagina_atual: resultado.pagina_atual,
          quantidade_pagina: resultado.quantidade_pagina,
          quantidade_total: resultado.quantidade_total
        };
        this.totalPaginas = this.cabecalho.total_paginas;
        this.listaPaginas = [];
        for (var i = 1; i <= this.cabecalho.total_paginas; i++) {
          this.listaPaginas.push(i);
        }
        this.loading = false;

        this.obterVinculos();
        this.obterCargos();
        this.obterUnidades();
        this.obterTiposBusca();
      },
      err => {
         if (err.status == 401) {
           this.router.navigate(["/login"]);
           return;
         }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  primeira(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  anterior(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  atual(indice, pesquisa) {
    console.log(pesquisa)
    if (this.indice_selecionado != indice) {
      this.indice_selecionado = indice;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    } else {
      this.indice_selecionado = indice;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  proxima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  ultima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
      this.obterListaFuncionarios(this.indice_selecionado, pesquisa);
    }
  }

  mudarTipoPesquisa() {
    this.pesquisa.nome_pesquisa = "";
  }

  limparCampos(pesquisa, tipoBusca) {
    this.indice_selecionado = "";
    this.pesquisa.nome_pesquisa = "";
    this.pesquisa.unidade = "";
    pesquisa.setor = "";
    pesquisa.cargo = "";
    pesquisa.vinculo = "";
    this.tipoBusca.id = 0;
    this.listaSetores = null;
  }

  visualizarBiometria(func) {
    let dialogRef = this.dialog.open(ModalBiometriaComponent, {
      data: {
        func: func
      }
    });
  }
}
