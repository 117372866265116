import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import Swal from 'sweetalert2';
import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

import { Router } from "@angular/router";

@Component({
  selector: 'app-modal-apontamento-massa',
  templateUrl: './modal-apontamento-massa.component.html',
  styleUrls: ['./modal-apontamento-massa.component.css']
})
export class ModalApontamentoMassaComponent implements OnInit {
  loading: any = false;
  currentUser: any = {};
  dadosApontamento: any = {};
  listaTiposLancamento: any = [];

  dadoPontoFuncionario: any = {};
  escalaApontamento: any = {};
  ponto_existente: boolean = false;

  listaPontoExistente: any = [];

  imgSrc: any = '../../assets/img/default.jpg';

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,    
    public dialogRef: MatDialogRef<ModalApontamentoMassaComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);

      // this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

      this.dadosApontamento = data;

      if(this.dadosApontamento.tipo_apontamento == 1 || this.dadosApontamento.tipo_apontamento == 2 || this.dadosApontamento.tipo_apontamento == 3){
        this.obterDadosPontoFuncionario();
      }
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterTiposLancamento();
  }

  fechaModal() {
    this.dialogRef.close();
  }
  
  obterDadosPontoFuncionario(){
    this.loading = true;
    this.apiService.Get(`Pontos/${this.dadosApontamento.intervalo.ponto_uuid}`).then(
      (result: any) => {
        this.dadoPontoFuncionario = result;

        if(this.dadoPontoFuncionario.registro_link.includes('.png')){
          this.imgSrc = this.dadoPontoFuncionario.registro_link;
        } else {
          this.imgSrc = '../../assets/img/default.jpg';
        }
        
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  salvarApontamento(){
    if(this.escalaApontamento.tipo == null || this.escalaApontamento.tipo == 0){
      Swal.fire('Erro!', 'Selecione o tipo de apontamento', 'error');
      return
    }
    if(this.escalaApontamento.descricao_apontamento == null || this.escalaApontamento.descricao_apontamento == ''){
      Swal.fire('Erro!', 'Inserir descrição no espelho de ponto', 'error');
      return
    }
    let data_inicio = `${this.dadosApontamento.escala.data.split('T')[0]}T${this.dadosApontamento.escala.escala_intervalos[0].hora}`;
    let obj = {
      "tipo": this.escalaApontamento.tipo,
      "descricao_espelho_ponto": this.escalaApontamento.descricao_apontamento,
      "data_inicio": data_inicio,
      "data_final": data_inicio,
      "observacao": this.escalaApontamento.observacao,
      "funcionario_cargos": [
        this.dadosApontamento.funcionario.cargo_uuid
      ]
    }
    this.loading = true;
    this.apiService.Post(`Funcionarios/${this.dadosApontamento.funcionario.funcionario_uuid}/Lancamento`, obj).then(
      res => {
        if (res) {
          this.loading = false;
          Swal.fire({
            title: 'Sucesso',
            text: "Apontamento realizado com sucesso!",
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
          }).then(result => {
            this.fechaModal();
          });
        }
      },
      err => {
        //console.log(err)
        this.loading = false;
        if(err.error.mensagem){
          Swal.fire('Oops', err.error.mensagem, 'error');
        }
        else{
          Swal.fire('Oops', 'Não foi possível realizar o apontamento', 'error');
        }
      }
    )
  }

  obterTiposLancamento = () => {
    return new Promise((resolve) => {
      this.listaTiposLancamento = [
        { id: 1, nome: 'Afastamento' },
        { id: 2, nome: 'Férias' },
        { id: 3, nome: 'Falta' }
      ];
      this.escalaApontamento.tipo = 0;
      resolve('Lista de lançamentos carregada')
    })
  }

  onlyNumber = (e) => {
    const charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105) || charCode == 8) {      
        return true;
    }
    return false;
  }

  alertaErro = (erro) =>{
    this.loading = false;
    Swal.fire('Erro!', erro, 'error');
  }

  verificarPontosExistente(existe_ponto) {
    this.loading = true;
    if(existe_ponto){
      let data = this.dadosApontamento.intervalo.data.split('T')[0];
      this.apiService.Get(`Funcionarios/${this.dadosApontamento.funcionario.funcionario_uuid}/PontosDiarios?data=${data}`).then(
        (result: any) => {
          if(result.length == 0){
            Swal.fire('Oops', 'Sem pontos registrados', 'error');
          }
          this.listaPontoExistente = result;
          this.dadoPontoFuncionario.pontoExistente = 0;
          this.dadoPontoFuncionario.hora_presenca_em = null;
          
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
    }else{
      this.listaPontoExistente = [];
      this.dadosApontamento.pontoExistente = null;
      this.loading = false;
    }
  };

  salvarNovoPonto(){
    if(this.dadosApontamento.hora_presenca_em == null || this.dadosApontamento.hora_presenca_em == ''){
      Swal.fire('Erro!', 'Insira a hora', 'error');
      return
    }
    if(!this.dadosApontamento.hora_presenca_em.includes(':')){
      Swal.fire('Erro!', 'Hora inválida', 'error');
      return
    }
    if(this.dadosApontamento.hora_presenca_em.split(':')[1].length == 0 || this.dadosApontamento.hora_presenca_em.split(':')[1].length == 1){
      Swal.fire('Erro!', 'Hora inválida', 'error');
      return
    }
    if(parseInt(this.dadosApontamento.hora_presenca_em.split(':')[0]) > 23){
      Swal.fire('Erro!', 'Hora inválida', 'error');
      return
    }
    if(this.dadosApontamento.hora_presenca_em.split(':')[1].length == 0 || this.dadosApontamento.hora_presenca_em.split(':')[1].length == 1){
      Swal.fire('Erro!', 'Hora inválida', 'error');
      return
    }
    if(parseInt(this.dadosApontamento.hora_presenca_em.split(':')[1]) > 59){
      Swal.fire('Erro!', 'Hora inválida', 'error');
      return
    }
    if(this.dadosApontamento.justificativa_cadastrada == null || this.dadosApontamento.justificativa_cadastrada == ''){
      Swal.fire('Erro!', 'Insira a justificativa', 'error');
      return
    }

    let dataHoraPonto = `${this.dadosApontamento.intervalo.data.split('T')[0]}T${this.dadosApontamento.hora_presenca_em}:00`;
    
    let obj =   {
      "ativo": true,
      //'escala_intervalo_uuid':this.dadosApontamento.intervalo.escala_intervalo_uuid,
      "presenca_em": dataHoraPonto,
      "registro": "",
      "latitude": 0,
      "longitude": 0,
      "precisao": 0,
      "status": 2,
      "funcionario_uuid": this.dadosApontamento.funcionario.funcionario_uuid,
      "unidade_uuid": this.dadosApontamento.unidade_uuid,
      "setor_uuid": this.dadosApontamento.setor_uuid,
      "justificativa_cadastrada": this.dadosApontamento.justificativa_cadastrada,
      "ajustado": true
    }

    this.loading = true;
    this.apiService.Post(`Pontos/Apontamento`, obj).then(
      res => {
        if (res) {
          this.loading = false;
          Swal.fire({
            title: 'Sucesso',
            text: "Ponto registrado com sucesso!",
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
          }).then(result => {
            this.fechaModal();
          });
        }
      },
      err => {
        //console.log(err)
        this.loading = false;
        if(err.error.mensagem){
          Swal.fire('Oops', err.error.mensagem, 'error');
        }
        else{
          Swal.fire('Oops', 'Não foi possível realizar registro', 'error');
        }
      }
    )
  }

  salvarAjustePonto() {
    let pontoFuncionario = { ...this.dadoPontoFuncionario }

    if (this.ponto_existente) {
      if (this.dadoPontoFuncionario.pontoExistente == null || this.dadoPontoFuncionario.pontoExistente == 0) {
        Swal.fire('Oops', 'Selecione o ponto', 'error');
        return;
      }
      pontoFuncionario.presenca_em = pontoFuncionario.pontoExistente.presenca_em;
    } else {
      if (this.dadoPontoFuncionario.hora_presenca_em == null || this.dadoPontoFuncionario.hora_presenca_em == '') {
        Swal.fire('Erro!', 'Insira a hora', 'error');
        return
      }
      if (!this.dadoPontoFuncionario.hora_presenca_em.includes(':')) {
        Swal.fire('Erro!', 'Hora inválida', 'error');
        return
      }
      if (this.dadoPontoFuncionario.hora_presenca_em.split(':')[1].length == 0 || this.dadoPontoFuncionario.hora_presenca_em.split(':')[1].length == 1) {
        Swal.fire('Erro!', 'Hora inválida', 'error');
        return
      }
      if (parseInt(this.dadoPontoFuncionario.hora_presenca_em.split(':')[0]) > 23) {
        Swal.fire('Erro!', 'Hora inválida', 'error');
        return
      }
      if (parseInt(this.dadoPontoFuncionario.hora_presenca_em.split(':')[1]) > 59) {
        Swal.fire('Erro!', 'Hora inválida', 'error');
        return
      }

      pontoFuncionario.presenca_em = `${this.dadoPontoFuncionario.presenca_em.split('T')[0]}T${this.dadoPontoFuncionario.hora_presenca_em}:00`
    }

    if (this.dadoPontoFuncionario.justificativa_ajuste == null || this.dadoPontoFuncionario.justificativa_ajuste == '') {
      Swal.fire('Erro!', 'Informar justificativa', 'error');
      return
    }

    this.loading = true;

    this.apiService.Patch2(`Pontos/${this.dadosApontamento.intervalo.ponto_uuid}/Presenca`, pontoFuncionario).then(
      res => {
        if (res) {
          this.loading = false;
          Swal.fire({
            title: 'Sucesso',
            text: "Ponto ajustado com sucesso!",
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
          }).then(result => {
            this.fechaModal();
          });
        }
      },
      err => {
        //console.log(err)
        this.loading = false;
        if (err.error.mensagem) {
          Swal.fire('Oops', err.error.mensagem, 'error');
        }
        else {
          Swal.fire('Oops', 'Não foi possível realizar o ajuste', 'error');
        }
      }
    )
  };

}
