import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";

import { Global } from '../../global'

@Component({
  selector: "app-espelho-ponto",
  templateUrl: "./espelho-ponto.component.html",
  styleUrls: ["./espelho-ponto.component.css"],
})
export class EspelhoPontoComponent implements OnInit {
  loading: any = false;

  filtro: any = { unidade: 0 };
  filtro_selecionado: any = {};

  listaUnidades: any = [];
  listaSetores: any = [];
  listaCargos: any = [];
  listaFuncionarios: any = [];
  listaMeses: any = [];

  listaEspelhos: any = [];

  //paginação
  cabecalho: any = {};
  indice_selecionado: any = 0;
  pesquisa: any = '';
  showPaginas: boolean = true;
  indice = 1;

  constructor(private apiService: ApiService, private router: Router, private global: Global) {}

  ngOnInit() {
    this.obterUnidades();
    this.obterMeses();
    this.obterListaInicial();
    this.filtro.ano = new Date().getFullYear();
  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get("Unidades?ativo=true&gerente=true").then(
      (result) => {
        this.listaUnidades = result;
        this.loading = false;
      },
      (err) => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: err.error.mensagem,
        });
      }
    );
  }

  obterSetores(unidade_id) {
    this.listaSetores = null;
    this.listaCargos = null;
    this.listaFuncionarios = null;
    this.filtro.setor = null;
    this.filtro.cargo = null;
    this.filtro.funcionario = null;
    if (unidade_id == 0) {
      return null;
    }
    this.loading = true;
    this.apiService
      .Get("Unidades/" + unidade_id + "/Setores?ativo=true&gerente=true")
      .then(
        (result) => {
          this.listaSetores = result;
          this.filtro.setor = 0;
          this.loading = false;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  }

  obterCargos(setor_uuid) {
    this.listaCargos = null;
    this.listaFuncionarios = null;
    this.filtro.cargo = null;
    this.filtro.funcionario = null;
    if (setor_uuid == 0) {
      return null;
    }
    this.loading = true;
    this.apiService
      .Get("Setor/" + setor_uuid + "/Cargos?ativo=true&gerente=true")
      .then(
        (result) => {
          this.listaCargos = result;
          this.loading = false;
          this.filtro.cargo = 0;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  }

  obterFuncionarios(setorUuid, cargoUuid) {
    this.listaFuncionarios = null;
    this.filtro.funcionario = null;
    this.loading = true;
    if (!cargoUuid) {
      this.loading = false;
      return
    }
    if (!this.filtro.ano || this.filtro.ano == '') {
      this.loading = false;
      Swal.fire('Erro', 'É preciso informar o ano', 'error')
      return
    }
    var txt = `setor=${setorUuid}&cargo=${cargoUuid}&ano=${this.filtro.ano}&mes=${this.filtro.mes}`
    this.apiService
      .Get(`Funcionarios?${txt}`)
      .then(
        (result) => {
          this.listaFuncionarios = result;
          this.filtro.funcionario = 0;
          this.loading = false;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  }

  obterMeses() {
    this.loading = true;
    this.listaMeses = [
      { id: 1, nome: "Janeiro" },
      { id: 2, nome: "Fevereiro" },
      { id: 3, nome: "Março" },
      { id: 4, nome: "Abril" },
      { id: 5, nome: "Maio" },
      { id: 6, nome: "Junho" },
      { id: 7, nome: "Julho" },
      { id: 8, nome: "Agosto" },
      { id: 9, nome: "Setembro" },
      { id: 10, nome: "Outubro" },
      { id: 11, nome: "Novembro" },
      { id: 12, nome: "Dezembro" },
    ];
    this.filtro.mes = new Date().getMonth() + 1;
  }

  valida_filtro() {
    if (
      this.filtro.unidade == null ||
      this.filtro.unidade == 0 ||
      this.filtro.unidade == "0"
    ) {
      Swal.fire({
        type: "error",
        title: "oops...",
        text: "Insira a unidade",
      });
      return false;
    }
    if (
      this.filtro.setor == null ||
      this.filtro.setor == 0 ||
      this.filtro.setor == "0"
    ) {
      Swal.fire({
        type: "error",
        title: "oops...",
        text: "Insira a unidade",
      });
      return false;
    }

    if (
      this.filtro.ano == null ||
      this.filtro.ano == "" ||
      this.filtro.ano == 0 ||
      this.filtro.ano.length < 4
    ) {
      Swal.fire({
        type: "error",
        title: "oops...",
        text: "Insira o ano!",
      });
      return false;
    }
    if (this.filtro.mes == null) {
      Swal.fire({
        type: "error",
        title: "oops...",
        text: "Insira o mes",
      });
      return false;
    }
    return true;
  }

  imprimir() {
    if (this.valida_filtro()) {
      this.filtro_selecionado.setor_uuid = this.filtro.setor.uuid;
      const cargo = this.filtro.cargo ? this.filtro.cargo.uuid : "";

      if (this.filtro.funcionario != 0 && this.filtro.funcionario != null)
        this.filtro_selecionado.funcionario_uuid = this.filtro.funcionario.uuid;
      else this.filtro_selecionado.funcionario_uuid = "";

      this.filtro_selecionado.mes = this.filtro.mes;
      this.filtro_selecionado.ano = this.filtro.ano;

      this.loading = true;

      this.apiService.Get(
        "EspelhoPonto?setor_uuid="+this.filtro_selecionado.setor_uuid+
        "&cargo_uuid="+cargo+
        "&ano="+this.filtro_selecionado.ano+
        "&mes="+this.filtro_selecionado.mes+
        "&funcionario_uuid="+this.filtro_selecionado.funcionario_uuid)
        .then(
          (result) => {
            this.loading = false;
          },
          (err) => {
            if (err.status == 200) {
              let dataAtual = new Date();
              let mesAtual = dataAtual.getMonth();
              let anoAtual = dataAtual.getFullYear();
              var result = saveAs(
                err["error"]["text"],
                `espelho-de-ponto-${mesAtual}/${anoAtual}.pdf`
              );
              this.loading = false;
              return;
            }
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              this.loading = false;
              return;
            }
            Swal.fire({
              type: "error",
              title: "Oops...",
              text: err.error.mensagem,
            });
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      return;
    }
  }

  gerarEspelho = () => {
    this.loading = true;
    this.valida_requisicao(this.filtro).then(
      result => {
        var filtro: any;
        filtro = result;
        var obj: any = {}
        obj.mes = filtro.mes;
        obj.ano = filtro.ano;
        obj.unidade_uuid = filtro.unidade.uuid;
        obj.setor_uuid = filtro.setor.uuid;
        if (filtro.cargo.uuid){
          obj.cargo_uuid = filtro.cargo.uuid;
        }
        if (filtro.funcionario){
          obj.funcionario_uuid = filtro.funcionario.uuid;
        }
        this.apiService.Post(`EspelhoPonto`, obj).then(
          result => {
            this.loading = false;
            Swal.fire('Sucesso', 'Solicitação de espelho de ponto gerada com sucesso. Aguarde finalizar o processamento', 'success').then(
              result => {
                if (result.value){
                  this.listarEspelhos(1)
                }
              }
            )
          },
          error => {
            this.alertaErro(error)
          }
        )
      },
      error => {
        this.alertaErro(error)
      }
    )
  }
  
  listarEspelhos = (pagina) => {
    this.loading = true;
    this.valida_requisicao(this.filtro).then(
      result => {        
        var filtro: any;
        filtro = result;
        var text: any = '';
        if (filtro.unidade == 0){
          text = `?pagina=${pagina}`
        }
        else {
          text = `?mes=${filtro.mes}&ano=${filtro.ano}&unidade_uuid=${filtro.unidade.uuid}&setor_uuid=${filtro.setor.uuid}&pagina=${pagina}`;
          if (filtro.cargo.uuid){
            text += `&cargo_uuid=${filtro.cargo.uuid}`;
          }
          if (filtro.funcionario){
            text+=`&funcionario_uuid=${filtro.funcionario.uuid}`;
          }
        }
        this.apiService.Get(`EspelhoPonto/Listar${text}`).then(
          result => {
            this.listaEspelhos = result['conteudo'];
        this.indice_selecionado = result['pagina_atual']
        this.cabecalho.total_paginas = result['total_paginas']
        this.cabecalho.quantidade_pagina = result['quantidade_pagina']
        this.cabecalho.quantidade_total = result['quantidade_total']
            this.loading = false;
          },
          error => {
            this.alertaErro(error)
          }
        )
      },
      error => {
        this.alertaErro(error)
      }
    )
  }

  valida_requisicao = (filtro) => {
    return new Promise((resolve, reject) => {
      if (
        filtro.unidade == null ||
        filtro.unidade == undefined
      ) {
        reject("Insira a unidade")
        return
      }
      if (filtro.unidade != 0){
        if (
          filtro.setor == null ||
          filtro.setor == 0 ||
          filtro.setor == "0"
        ) {
          reject("Insira o setor")
          return
        }
      }
  
      if (
        filtro.ano == null ||
        filtro.ano == "" ||
        filtro.ano == 0 ||
        filtro.ano.length < 4
      ) {
        reject("Insira o ano!")
        return
      }
      if (filtro.mes == null) {
        reject("Insira o mes")
        return;
      }
      resolve(filtro)
    })
  }

  alertaErro = (erro) =>{
    this.loading = false;
    Swal.fire('Erro!', erro, 'error');
  }

  imprimirEspelho = (arquivo) => {
    let dataAtual = new Date();
    let mesAtual = dataAtual.getMonth();
    let anoAtual = dataAtual.getFullYear();
    var result = saveAs(
      arquivo
    );
  }

  obterListaInicial = () => {
    var text = "?pagina=1"
    this.apiService.Get(`EspelhoPonto/Listar${text}`).then(
      result => {
        this.listaEspelhos = result['conteudo'];
        this.indice_selecionado = result['pagina_atual']
        this.cabecalho.total_paginas = result['total_paginas']
        this.cabecalho.quantidade_pagina = result['quantidade_pagina']
        this.cabecalho.quantidade_total = result['quantidade_total']
        this.loading = false;
      },
      error => {
        this.alertaErro(error)
      }
    )
  }

  exibirMes = (mes) => {
    var listaMeses = [
      { id: 1, nome: "Janeiro" },
      { id: 2, nome: "Fevereiro" },
      { id: 3, nome: "Março" },
      { id: 4, nome: "Abril" },
      { id: 5, nome: "Maio" },
      { id: 6, nome: "Junho" },
      { id: 7, nome: "Julho" },
      { id: 8, nome: "Agosto" },
      { id: 9, nome: "Setembro" },
      { id: 10, nome: "Outubro" },
      { id: 11, nome: "Novembro" },
      { id: 12, nome: "Dezembro" },
    ];
    for (let item of listaMeses) {
      if (item.id == mes){
        return item.nome
      }
    }
  }
  
  //paginação  
  primeira() {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.listarEspelhos(this.indice_selecionado);
    }
  }

  anterior() {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
        this.listarEspelhos(this.indice_selecionado);
    }
  }

  proxima() {
    console.log(this.filtro)
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.listarEspelhos(this.indice_selecionado);
    }
  }

  ultima() {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
        this.listarEspelhos(this.indice_selecionado);
    }
  }
}
