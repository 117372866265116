import { Component, OnInit, NgModule } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { Global } from '../../../global';
import { ApiService } from '../../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";
import { AppComponent } from '../../../app.component';

@Component({
  selector: 'app-turno',
  templateUrl: './turno.component.html',
  styleUrls: ['./turno.component.css']
})
export class TurnoComponent implements OnInit {

  novoTurno: boolean = false;
  turno: any = {};
  intervalo: any = {};
  loading = false;
  visualizando: any = false;
  listaTurnos: any = [];

  currentUser: any = {};

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      //this.instituicaoID = this.currentUser.instituicao_logada.instituicao_codigo;
      //this.obtercargos();
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterTurnos();
  }

  add() {
    this.novoTurno = true;
    this.turno = {};
    this.turno.ativo = true;
  }

  back() {
    this.obterTurnos();
    this.novoTurno = false;
    this.turno = {};
  }

  getFormatterDate(item) {
    return this.global.dateTimeFormater(item);
  }

  valida(turno) {
    if (turno.ativo == null) {
      turno.ativo = false;
    }
    if (turno.nome == null || turno.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome!'
      });
      return false;
    }
    if (turno.sigla == null || turno.sigla == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira uma sigla!'
      });
      return false;
    }
    //validação rubrica
    if (turno.rubrica == null || turno.rubrica == ''){
      delete turno.rubrica
    }
    if (turno.rubrica){
      turno.rubrica = parseInt(turno.rubrica);
    }
    if (turno.rubrica < 0){
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma rubrica válida!'
      });
      return false;
    }
    if (turno.rubrica > 999999){
      turno.rubrica = 999999;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Valor máximo válido é 999999!'
      });
      return false;
    }
    //validação sufixo rubrica
    if (turno.sufixo_rubrica == null || turno.sufixo_rubrica == ''){
      delete turno.sufixo_rubrica
    }
    if (turno.sufixo_rubrica){
      turno.sufixo_rubrica = parseInt(turno.sufixo_rubrica);
    }
    if (turno.sufixo_rubrica < 0){
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe um sufixo válido!'
      });
      return false;
    }
    if (turno.sufixo_rubrica > 99){
      turno.sufixo_rubrica = 99;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Valor máximo válido é 99!'
      });
      return false;
    }
    if (turno.exibir_sabado_domingo == null) {
      turno.exibir_sabado_domingo = false;
    }
    return true;
  }

  edit(turno) {
    this.novoTurno = true;
    this.obterTurno(turno);
    //this.turno = turno;
    this.visualizando = false;
  }

  view(turno) {
    this.edit(turno);
    this.visualizando = true;
  }

  obterTurnos() {
    this.loading = true;
    this.apiService.Get("Turno").then(
      result => {
        this.listaTurnos = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  desativar(usuario) {
    this.loading = true;
    this.apiService.desativar("Turno", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterTurnos();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  ativar(usuario) {
    this.loading = true;
    this.apiService.ativar("Turno", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterTurnos();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  submit() {
    // console.log(this.turno);
    // return;
    if (this.valida(this.turno)) {
      this.loading = true;
      // this.turno = this.prepararSubmit(this.turno);
      if (this.turno.uuid != null) {
        ///this.global.alunoId = this.alunoID;
        this.apiService.Put("Turno", this.turno).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Turno atualizado com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      } else {
        this.apiService.Post("Turno", this.turno).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Turno salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      }
    }
    else {
      return;
    }
  }
  
  obterTurno(turno) {
    this.loading = true;
    this.apiService.GetOne("Turno", turno.uuid).then(
      result => {
        this.turno = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  //intervalos
  addIntervalo(hora_inicio, hora_fim, virou_dia) {
    if (virou_dia == undefined) {
      virou_dia = false;
    }
    if (hora_inicio == null || !this.global.validaHorario(hora_inicio)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira uma hora inicial do intervalo válida!'
      });
      return;
    }
    if (hora_fim == null || !this.global.validaHorario(hora_fim)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira uma hora final do intervalo válida!'
      });
      return;
    }
    if (this.turno.intervalos == null) {
      this.turno.intervalos = [];
    }
    this.turno.intervalos.push({ hora_inicio: hora_inicio, hora_fim: hora_fim, ordem_intervalo: this.turno.intervalos.length + 1, virou_dia: virou_dia });
    console.log(this.turno)
    this.intervalo = {};
  }

  removeIntervalo(intervalo) {
    var index = this.getIndex(intervalo);
    if (index != null) {
      this.turno.intervalos.splice(index, 1);
    }
  }

  getIndex(intervalo) {
    for (var aux in this.turno.intervalos) {
      if (this.turno.intervalos[aux].hora_inicio == intervalo.hora_inicio && this.turno.intervalos[aux].hora_fim == intervalo.hora_fim) {
        return aux;
      }
    }
    return null;
  }

  subirOrdemTurno(turno) {
    if (turno.ordem_intervalo > 1) {
      var index = this.turno.intervalos.findIndex(x => x.ordem_intervalo == turno.ordem_intervalo);

      var turno_ordenado = this.alterarOrdem(this.turno.intervalos, index, index - 1);

      for (var i = 0; i < turno_ordenado.length; i++) {
        turno_ordenado[i].ordem_intervalo = i + 1;
      }

      this.turno.intervalos = turno_ordenado;
    }
  }

  descerOrdemTurno(turno) {
    if (turno.ordem_intervalo != this.turno.intervalos.length) {
      var index = this.turno.intervalos.findIndex(x => x.ordem_intervalo == turno.ordem_intervalo);

      var turno_ordenado = this.alterarOrdem(this.turno.intervalos, index, index + 1);

      for (var i = 0; i < turno_ordenado.length; i++) {
        turno_ordenado[i].ordem_intervalo = i + 1;
      }

      this.turno.intervalos = turno_ordenado;
    }
  }

  alterarOrdem(arr, old_index, new_index) {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length;
    while ((k--) + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
  }

}

