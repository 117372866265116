import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../services/api.service';


@Component({
  selector: 'app-permissoes',
  templateUrl: './permissoes.component.html',
  styleUrls: ['./permissoes.component.css']
})
export class PermissoesComponent implements OnInit {

  novaPermissao: boolean = false;
  loading: boolean = false;
  exibirSubmenu: boolean = false;
  perfis: any = [];
  perfil: any = {};
  menus: any = [];
  menusSelecionados: any = [];
  submenusSelecionados: any = [];
  todosSelecionados: boolean = false;

  constructor(
    public global: Global,
    private apiService: ApiService,
    private router: Router
  ) {
    this.obterPerfis();
  }

  ngOnInit() {
  }

  add() {
    this.menusSelecionados = [];
    this.obterMenus();
    this.novaPermissao = true;
    this.perfil = {};
    this.perfil.ativo = true;
  }

  back() {
    this.novaPermissao = false;
    this.perfil = {};
  }

  obterPerfis() {
    this.loading = true;
    this.apiService.Get("Perfis").then(
      result => {
        this.perfis = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterPerfil(perfilUuid) {
    this.loading = true;
    this.apiService.GetOne("Perfis", perfilUuid).then(
      result => {
        this.perfil = result;
        this.menusSelecionados = this.perfil.perfil_menus;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  desativar(perfilUuid) {
    this.loading = true;
    this.apiService.desativar("Perfis", perfilUuid)
      .then(
        result => {
          this.loading = false;
          this.obterPerfis();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  ativar(perfilUuid) {
    this.loading = true;
    this.apiService.ativar("Perfis", perfilUuid)
      .then(
        result => {
          this.loading = false;
          this.obterPerfis();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  edit(perfilUuid) {
    this.obterPerfil(perfilUuid);
    this.obterMenus();
    this.novaPermissao = true;
  }

  obterMenus() {
    this.loading = true;
    this.apiService.Get("Menus").then(
      result => {
        this.menus = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  selecionarMenu(menuUuid, selecionado) {
    if (selecionado) {
      this.exibirSubmenu = true;
    }
    else
      this.exibirSubmenu = false;
    if (this.menusSelecionados.length == 0 && selecionado) {
      var menuSelecionado = {
        "menu_uuid": menuUuid,
        "sub_menus": []
      }
      this.menusSelecionados.push(menuSelecionado);
      var menu_selecionado = this.menus.filter(x => x.uuid == menuUuid);
      if (menu_selecionado[0].sub_menus.length > 0) {
        menu_selecionado[0].sub_menus.forEach((item) => {
          this.selecionarSubMenu(menuUuid, item.uuid, selecionado);
        });
      }
      return;
    }

    if (this.menusSelecionados.length > 0) {
      this.menusSelecionados.forEach((item, index) => {
        if (item.menu_uuid == menuUuid && !selecionado) {
          this.menusSelecionados.splice(index, 1);
          return;
        }
      });
      if (selecionado) {
        var menuSelecionado = {
          "menu_uuid": menuUuid,
          "sub_menus": []
        }
        this.menusSelecionados.push(menuSelecionado);
        var menu_selecionado = this.menus.filter(x => x.uuid == menuUuid);
        if (menu_selecionado[0].sub_menus.length > 0) {
          menu_selecionado[0].sub_menus.forEach((item) => {
            this.selecionarSubMenu(menuUuid, item.uuid, selecionado);
          });
        }
      }
    }
  }

  verificarMenusChecados(menuUuid) {
    if (this.menusSelecionados.length < 1)
      return false;
    let achou = false;
    this.menusSelecionados.forEach((item) => {
      if (item.menu_uuid == menuUuid) {
        achou = true;
        return
      }
    });
    if (achou)
      return true;
    else
      return false;
  }

  selecionarSubMenu(menuUuid, submenuUuid, selecionado) {
    if (selecionado)
      this.exibirSubmenu = true;
    else
      this.exibirSubmenu = false;

    if (selecionado)
      this.menusSelecionados.forEach((item) => {
        if (item.menu_uuid == menuUuid) {
          item.sub_menus.push({ "sub_menu_uuid": submenuUuid });
          return;
        }
      });
    else
      this.menusSelecionados.forEach((menu) => {
        if (menu.menu_uuid == menuUuid) {
          menu.sub_menus.forEach((submenu, index) => {
            if (submenu.sub_menu_uuid == submenuUuid) {
              menu.sub_menus.splice(index, 1);
              return;
            }
          });
        }
      });
  }

  verificarSubMenusChecados(menuUui, submenuUuid) {
    if (this.menusSelecionados.length < 1)
      return false;
    let achou = false;
    if (this.menusSelecionados != null && this.menusSelecionados.length > 0) {
      this.menusSelecionados.forEach((menu) => {
        if (menu.menu_uuid == menuUui) {
          if (menu.sub_menus != null || menu.sub_menus.length > 0)
            menu.sub_menus.forEach((submenu) => {
              if (submenu.sub_menu_uuid == submenuUuid) {
                achou = true;
                return
              }
            });
        }
      });
    }
    if (achou)
      return true;
    else
      return false;
  }

  selecionarTodos(selecionado) {
    if (selecionado) {
      this.todosSelecionados = true;
      this.menus.forEach((menu) => {
        this.selecionarMenu(menu.uuid, selecionado);
        if (menu.sub_menus.length > 0) {
          menu.sub_menus.forEach((submenu) => {
            this.selecionarSubMenu(menu.uuid, submenu.uuid, selecionado);
          });
        }
      });
    } else {
      this.todosSelecionados = false;
      this.menusSelecionados = [];
    }      
  }

  submit(perfil) {
    perfil.perfil_menus = this.menusSelecionados;
    this.loading = true;
    if (this.perfil.uuid != null) {
      this.apiService.Put("Perfis", perfil).then(
        result => {
          this.back();
          this.obterPerfis();
          this.loading = false;
          Swal.fire({
            type: 'success',
            title: 'Sucesso!',
            text: 'Perfil alterado com sucesso!'
          });
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          else {
            this.loading = false;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
          }
        }
      );
    } else {
      this.apiService.Post("Perfis", perfil).then(
        result => {
          this.obterPerfis();
          this.back();
          this.loading = false;
          Swal.fire({
            type: 'success',
            title: 'Sucesso!',
            text: 'perfil salvo com sucesso!'
          });
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          else {
            this.loading = false;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
          }
        }
      );
    }
  }

}
