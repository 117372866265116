import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { Global } from '../../../global';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-motivo-alteracao',
  templateUrl: './motivo-alteracao.component.html',
  styleUrls: ['./motivo-alteracao.component.css']
})
export class MotivoAlteracaoComponent implements OnInit {


  novoMotivo: boolean = false;
  motivo: any = {};
  motivoStatus: boolean = true;
  
  loading = false;
  visualizando: any = false;
  listaMotivos: any = [];

  currentUser = {};

  //partes de testes de funcionários
  cabecalho: any = {};
  listaPaginas: any = [];
  indice_selecionado: any = 0;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterMotivos();
  }

  add() {
    this.novoMotivo = true;
    this.motivo = {};
    this.motivo.ativo = true;
  }

  back() {
    this.novoMotivo = false;
    this.obterMotivos();
    this.motivo = {};
  }

  valida(motivo) {
    if (motivo.ativo == null) {
      motivo.ativo = false;
    }
    if (motivo.nome == null || motivo.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome!'
      });
      return false;
    }
    return true;
  }

  edit(motivo) {
    this.novoMotivo = true;
    this.obterMotivo(motivo);
    //this.motivo = motivo;
    this.visualizando = false;
  }

  obterMotivos() {
    this.loading = true;
    this.apiService.Get("MotivosAlteracaoEscala").then(
      result => {
        this.listaMotivos = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  desativar(usuario) {
    this.loading = true;
    this.apiService.desativar("MotivosAlteracaoEscala", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterMotivos();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  ativar(usuario) {
    this.loading = true;
    this.apiService.ativar("MotivosAlteracaoEscala", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterMotivos();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  submit() {
    if (this.valida(this.motivo)) {
      this.loading = true;
      if (this.motivo.uuid != null) {
        this.apiService.Put("MotivosAlteracaoEscala", this.motivo).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Motivo salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      } else {
        this.apiService.Post("MotivosAlteracaoEscala", this.motivo).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Motivo salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      }
    }
    else {
      return;
    }
  }

  obterMotivo(motivo) {
    this.loading = true;
    this.apiService.GetOne("MotivosAlteracaoEscala", motivo.uuid).then(
      result => {
        this.motivo = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }


}
