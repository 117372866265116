import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import Swal from 'sweetalert2';


import { Router, Event as NavigationEvent } from '@angular/router';
import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

import { ModalCopiaEscalaComponent } from '../../components/modal-copia-escala/modal-copia-escala.component';

@Component({
  selector: 'app-escala',
  templateUrl: './escala.component.html',
  styleUrls: ['./escala.component.css']
})
export class EscalaComponent implements OnInit {
  loading: any = false;

  filtro: any = { unidade: 0 };
  filtro_selecionado: any = {};
  turnoPesquisado: any = {};

  diasMes = [];
  listaCargos: any = [];
  listaUnidades: any = [];
  listaSetores: any = [];
  listaMeses: any = [];
  listaTurnos: any = [];
  listaFuncionarios: any = [];
  listaFeriados: any = [];
  objEscalas: any = {};
  listaEscalas: any = [];
  total: any = {};
  momento: any = 1;

  hora_cadastrada: any = '00:00:00';
  hora_a_cadastrar: any = '00:00:00';
  banco_de_horas: any = '00:00:00';

  dia_atual: any;
  mes_atual: any;
  ano_atual: any;


  func = [];
  elem: any = 0;

  currentUser: any = {};

  listaGrupoEscalas: any = [];

  //paginação
  cabecalho: any = {};
  listaPaginas: any = [];
  indice_selecionado: any = 0;
  pesquisa: any = '';
  showPaginas: boolean = false;
  indice = 1;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem('currentUser') == null) {
        this.router.navigate(["/login"]);
      }
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.filtro.mes = 0;
    } catch (err) {
      this.router.navigate(['/login']);
    }
  }

  add() { }

  ngOnInit() {
    this.dia_atual = (new Date()).getDate();
    this.mes_atual = (new Date()).getMonth() + 1;
    this.ano_atual = (new Date()).getFullYear();
    //this.obterCargos();
    this.obterUnidades();
    this.obterMeses();
    this.filtro.ano = (new Date()).getFullYear();
    //this.obterTurnos();
    //this.obterFuncionarios();
    //this.contadorMes();
  }

  diasNoMes(ano, mes) {
    let data = new Date(ano, mes, 0);
    return data.getDate();
  }

  modificarCelulas() {
    this.momento = 2;
  }

  modificarTurno() {
    this.momento = 3;
  }

  //submitTurno() {
  //  this.momento = 1;
  //}

  limparCelulas() {
    for (let i = 0; i < this.listaEscalas.length; i++) {
      let tam_atual = this.listaEscalas[i].escalas.length;
      for (let j = 0; j < tam_atual; j++) {
        if (this.listaEscalas[i].escalas[j].selecionado) {
          this.listaEscalas[i].escalas[j].selecionado = null;
        }
      }
    }
    this.momento = 1;
  }

  selecionarCelulas(lista_celulas) {
    for (let i = 0; i < this.listaEscalas.length; i++) {
      let tam_atual = this.listaEscalas[i].escalas.length;
      for (let j = 0; j < tam_atual; j++) {
        for (let k = 0; k < lista_celulas.length; k++) {
          if (this.listaEscalas[i].escalas[j].escala_uuid != null) {
            if (this.listaEscalas[i].escalas[j].escala_uuid == lista_celulas[k].escala_uuid) {
              this.listaEscalas[i].escalas[j].selecionado = true;
            }
          }
        }
      }
    }
    this.momento = 1;
  }

  deletarEscalas() {
    let listaDeletadas = [];
    for (let i = 0; i < this.listaEscalas.length; i++) {
      let tam_atual = this.listaEscalas[i].escalas.length;
      for (let j = 0; j < tam_atual; j++) {
        if (this.listaEscalas[i].escalas[j].selecionado) {
          if (this.listaEscalas[i].escalas[j].escala_uuid != null) {
            listaDeletadas.push(this.listaEscalas[i].escalas[j].escala_uuid);
          }
        }
      }
    }
    this.loading = true;
    this.apiService.DelereEscala('Escalas', listaDeletadas).then(
      result => {
        let resultado: any = [];
        resultado = result;
        if (resultado == null || resultado.length == 0) {
          this.obterEscalas(1);
          this.loading = false;
          Swal.fire({
            type: 'success',
            title: 'Sucesso!',
            text: 'Escalas deletadas com sucesso!'
          });
        } else {
          if (resultado.length > 0){
            this.loading = false;
            var erros = []
            resultado.forEach((value, i) => {
              //console.log('%d: %s', i+1, value.mensagem);
              var item = i+1+': '+value.mensagem;
              if (value.mensagem){
                erros.push(item)
              }
            });
            Swal.fire('Oops',`${erros}`, 'error')
            //   {
            //   type: 'error',
            //   title: 'Oops... As áreas demarcadas não puderam ser deletadas. <br> Seguem os motivos:',
            //   html: mensagem_erro
            // });
            return
          }
          let mensagem_erro = '';
          for (let erro = 0; erro < resultado.length; erro++) {
            mensagem_erro += 'A escala do funcionário ' + resultado[erro].funcionario_nome + ' para o dia ' + resultado[erro].dia + ' não pode ser deletado! Motivo: ' + resultado[erro].mensagem + '<br>';
          }
          this.limparCelulas();
          this.obterEscalas_apos_delete(resultado);
          ///marcando as celulas
          //this.selecionarCelulas(resultado);
          Swal.fire({
            type: 'error',
            title: 'Oops... As áreas demarcadas não puderam ser deletadas. <br> Seguem os motivos:',
            html: mensagem_erro
          });
        }
        //demarcar as áreas que nao puderam ser deletadas
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
    this.momento = 1;
  }

  varificarTurno(turno_uuid) {
    if (this.turnoPesquisado.uuid != this.filtro_selecionado.turno.uuid) {
      Swal.fire({
        title: 'Atenção!',
        text: `Será adicionado o turno referência: ${this.turnoPesquisado.nome}`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this.submitTurno(turno_uuid)
        }
      })
    }else{
      this.submitTurno(turno_uuid)
    }
  }

  submitTurno(turno_uuid) {

    this.loading = true;

    let Obj = null;
    let listaEscalasSelecionadas = [];
    for (let i = 0; i < this.listaEscalas.length; i++) {
      let tam_atual = this.listaEscalas[i].escalas.length;
      for (let j = 0; j < tam_atual; j++) {
        if (this.listaEscalas[i].escalas[j].selecionado) {
          if (this.listaEscalas[i].escalas[j].escala_uuid != null) {

            if (this.filtro_selecionado.turno.exibir_sabado_domingo != null && this.filtro_selecionado.turno.exibir_sabado_domingo == true) {
              Obj = {
                uuid: this.listaEscalas[i].escalas[j].escala_uuid,
                cargo_uuid: this.listaEscalas[i].cargo_uuid,
                funcionario_uuid: this.listaEscalas[i].funcionario_uuid,
                vinculo_uuid: this.listaEscalas[i].vinculo_uuid,
                data_escala: `${this.filtro_selecionado.ano}-${(('0' + this.filtro_selecionado.mes).slice(-2))}-${('0' + this.listaEscalas[i].escalas[j].escala_dia.toString()).slice(-2)}`
              };
            } else {
              Obj = {
                uuid: this.listaEscalas[i].escalas[j].escala_uuid,
                cargo_uuid: this.listaEscalas[i].cargo_uuid,
                funcionario_uuid: this.listaEscalas[i].funcionario_uuid,
                vinculo_uuid: this.listaEscalas[i].vinculo_uuid,
                data_escala: this.filtro_selecionado.ano + '-' +
                  (('0' + this.filtro_selecionado.mes).slice(-2)) + '-' + (('0' + j + 1).slice(-2)),
              };
            };
            listaEscalasSelecionadas.push(Obj);
          } else {
            if (this.filtro_selecionado.turno.exibir_sabado_domingo != null && this.filtro_selecionado.turno.exibir_sabado_domingo == true) {
              Obj = {
                cargo_uuid: this.listaEscalas[i].cargo_uuid,
                funcionario_uuid: this.listaEscalas[i].funcionario_uuid,
                vinculo_uuid: this.listaEscalas[i].vinculo_uuid,
                // data_escala: this.filtro_selecionado.ano + '-' + (('0'+this.filtro_selecionado.mes).slice(-2)) + '-' + (('0'+ (j + 1)).slice(-2)),
                data_escala: `${this.filtro_selecionado.ano}-${(('0' + this.filtro_selecionado.mes).slice(-2))}-${('0' + this.listaEscalas[i].escalas[j].escala_dia.toString()).slice(-2)}`,
              };
            } else {
              Obj = {
                cargo_uuid: this.listaEscalas[i].cargo_uuid,
                funcionario_uuid: this.listaEscalas[i].funcionario_uuid,
                vinculo_uuid: this.listaEscalas[i].vinculo_uuid,
                data_escala: this.filtro_selecionado.ano + '-' + (('0' + this.filtro_selecionado.mes).slice(-2)) + '-' + (('0' + (j + 1)).slice(-2)),
              };
            };
            listaEscalasSelecionadas.push(Obj);
          }
        }
      }
    }
    let Objeto = {
      setor_uuid: this.filtro_selecionado.setor.uuid,
      turno_uuid: turno_uuid,
      escalas: listaEscalasSelecionadas
    };
    //console.log(this.filtro)
    //console.log(this.listaEscalas)
    //console.log(listaEscalasSelecionadas)

    //console.log(Objeto);
    this.apiService.Post('Escalas', Objeto).then(
      result => {
        Swal.fire({
          type: 'success',
          title: 'Sucesso!',
          text: 'Escala salva com sucesso!'
        });
        this.obterEscalas(1);
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
    //this.loading = false;
    this.momento = 1;
  }

  diaDaSemana(ano, mes, dia) {
    let semana = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    let teste = new Date(ano, mes - 1, dia);
    let diaSemana = teste.getDay();
    return semana[diaSemana];
  }

  contadorMes() {
    this.diasMes = [];
    for (let month = 0; month < this.diasNoMes(this.filtro_selecionado.ano, this.filtro_selecionado.mes); month++) {
      let day = month + 1;
      this.diasMes.push({ day: day, daySemana: this.diaDaSemana(this.filtro_selecionado.ano, this.filtro_selecionado.mes, day) });
    }
  }

  obterFuncionarios() {
    this.loading = true;
    this.apiService.Get('Funcionarios').then(
      result => {
        this.listaFuncionarios = result;
        this.criando_turnos_temporario();
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  criando_turnos_temporario() {
    let turnos = [];
    for (let i = 0; i < this.listaFuncionarios.length; i++) {
      turnos = [];
      for (let j = 0; j < this.diasMes.length; j++) {
        let turno = { turno_nome: this.listaTurnos[0].nome, turno_uuid: this.listaTurnos[0].uuid };
        turnos.push(turno);
      }
      this.listaFuncionarios[i].turnos = turnos;
    }
  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get('Unidades?ativo=true&gerente=true').then(
      result => {
        this.listaUnidades = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterSetores(unidade_id) {
    this.listaSetores = null;
    this.listaCargos = null;
    this.listaTurnos = null;
    this.filtro.setor = null;
    this.filtro.cargo = null;
    this.filtro.turno = null;
    if (unidade_id == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get('Unidades/' + unidade_id + '/Setores?ativo=true&gerente=true').then(
      result => {
        this.listaSetores = result;
        this.filtro.setor = 0;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  lista_escala_default(escalas) {
    let lista_escala = [];
    for (let j = 0; j < this.diasMes.length; j++) {
      lista_escala.push(
        {
          turno_nome: '',
          turno_sigla: '',
          turno_horas: '00:00:00',
          escala_mes: this.filtro_selecionado.mes,
          escala_ano: this.filtro_selecionado.ano
          //escala_dia: j+1
        }
      );
    }
    this.hora_cadastrada = '00:00';
    for (let i = 0; i < escalas.length; i++) {
      if(this.filtro_selecionado.turno.exibir_sabado_domingo != null && this.filtro.turno.exibir_sabado_domingo == true){
        lista_escala.splice(this.diasMes.findIndex(x => x.day == escalas[i].escala_dia), 0, (escalas[i]));
      }else{
        lista_escala.splice(escalas[i].escala_dia - 1, 0, (escalas[i]));
      }
      lista_escala.pop();
      this.hora_cadastrada = this.global.addHora(this.hora_cadastrada, escalas[i].turno_horas);
    }
    for (let j = 0; j < this.diasMes.length; j++) {
      if(this.filtro_selecionado.turno.exibir_sabado_domingo != null && this.filtro.turno.exibir_sabado_domingo == true){
        lista_escala[j].escala_dia = this.diasMes[j].day;
      }else{
        lista_escala[j].escala_dia = j + 1;
      }
    }
    return lista_escala;
  }
  
  obterEscalas(indice) {
    this.loading = true;
    if (this.filtro_selecionado.cargo == undefined) {
      // var t = 'setor_uuid=' + this.filtro_selecionado.setor.uuid + '&ano=' + this.filtro_selecionado.ano + '&mes=' + this.filtro_selecionado.mes;
      var t = `setor_uuid=${this.filtro_selecionado.setor.uuid}&turno_uuid=${this.filtro_selecionado.turno.uuid}&ano=${this.filtro_selecionado.ano}&mes=${this.filtro_selecionado.mes}`
      var txt = `pagina=${indice}&${t}`;
    }
    else {
      // var t = 'setor_uuid=' + this.filtro_selecionado.setor.uuid + '&cargo_uuid='+ this.filtro_selecionado.cargo.uuid+ '&ano=' + this.filtro_selecionado.ano + '&mes=' + this.filtro_selecionado.mes;
      var t = `setor_uuid=${this.filtro_selecionado.setor.uuid}&cargo_uuid=${this.filtro_selecionado.cargo.uuid}&turno_uuid=${this.filtro_selecionado.turno.uuid}&ano=${this.filtro_selecionado.ano}&mes=${this.filtro_selecionado.mes}`
      var txt = `pagina=${indice}&${t}`;
    }
    this.apiService.Get('Escalas?'+ txt).then(
      result => {
        this.objEscalas = result['conteudo'];
        this.listaEscalas = this.objEscalas.escalas;
        this.turnoPesquisado = this.filtro_selecionado.turno;

        this.cabecalho = {
          total_paginas: result['total_paginas'],
          pagina_atual: result['pagina_atual'],
          quantidade_pagina: result['quantidade_pagina'],
          quantidade_total: result['quantidade_total']
        };
        this.indice_selecionado = this.cabecalho.pagina_atual;
        if(Object.keys(this.cabecalho).length > 0){this.showPaginas = true;}

        for (let i = 0; i < this.listaEscalas.length; i++) {
          let vet_banco_hora = this.listaEscalas[i].banco_hora_total.split(':');
          if (vet_banco_hora.length == 3) {
            this.listaEscalas[i].banco_hora_total = vet_banco_hora[0] + ':' + vet_banco_hora[1];
          }
          this.listaEscalas[i].escalas = this.lista_escala_default(this.listaEscalas[i].escalas);
          this.listaEscalas[i].hora_cadastrada = this.hora_cadastrada;
          let horario_ = this.global.minusHora(this.listaEscalas[i].funcionario_carga_horaria + ':00:00', this.hora_cadastrada);
          if (parseInt(horario_.split(':')[0]) < 0) {
            this.listaEscalas[i].hora_a_cadastrar = '00:00';
          } else {
            this.listaEscalas[i].hora_a_cadastrar = horario_;
          }
        }
        this.obterTotais();
        
        this.loading = false;
        this.listarGrupoEscalas();
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterFeriados() {
    this.loading = true;
    this.apiService.Get(`Feriados?ano=${this.filtro_selecionado.ano}&mes=${this.filtro_selecionado.mes}`).then(
      result => {
        //console.log(result);
        this.listaFeriados = result;
        this.listaFeriados.map(function (item) {
          let data_inicio = new Date(item.inicio);
          let data_fim = new Date(item.fim);
          let mes_inicio = new Date(item.inicio).getMonth() + 1;
          let mes_fim = new Date(item.fim).getMonth() + 1;
          item.data_inicio = data_inicio;
          item.data_fim = data_fim;
          item.mes_inicio = mes_inicio;
          item.mes_fim = mes_fim;
        });
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterEscalas_apos_delete(celulas) {
    this.loading = true;
    this.apiService.Get('Escalas?setor_uuid=' + this.filtro_selecionado.setor.uuid + '&cargo_uuid=' + this.filtro_selecionado.cargo.uuid + '&turno_uuid'+ this.filtro_selecionado.turno.uuid + '&ano=' + this.filtro_selecionado.ano + '&mes=' + this.filtro_selecionado.mes).then(
      result => {
        this.listaEscalas = result;
        for (let i = 0; i < this.listaEscalas.length; i++) {
          let vet_banco_hora = this.listaEscalas[i].banco_hora_total.split(':');
          if (vet_banco_hora.length == 3) {
            this.listaEscalas[i].banco_hora_total = vet_banco_hora[0] + ':' + vet_banco_hora[1];
          }
          this.listaEscalas[i].escalas = this.lista_escala_default(this.listaEscalas[i].escalas);
          this.listaEscalas[i].hora_cadastrada = this.hora_cadastrada;
          let horario_ = this.global.minusHora(this.listaEscalas[i].funcionario_carga_horaria + ':00:00', this.hora_cadastrada);
          if (parseInt(horario_.split(':')[0]) < 0) {
            this.listaEscalas[i].hora_a_cadastrar = '00:00';
          } else {
            this.listaEscalas[i].hora_a_cadastrar = horario_;
          }
        }
        this.obterTotais();
        this.selecionarCelulas(celulas);
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterTotais() {
    this.total.hora_cadastrada = '00:00:00';
    this.total.hora_a_cadastrar = '00:00:00';
    this.total.banco_de_horas = '00:00:00';
    this.total.funcionario_carga_horaria = 0;
    for (let i = 0; i < this.listaEscalas.length; i++) {
      this.total.hora_cadastrada = this.global.addHora(this.listaEscalas[i].hora_cadastrada, this.total.hora_cadastrada);
      this.total.hora_a_cadastrar = this.global.addHora(this.listaEscalas[i].hora_a_cadastrar, this.total.hora_a_cadastrar);
      this.total.banco_de_horas = this.global.addHora(this.listaEscalas[i].banco_hora_total, this.total.banco_de_horas);
      this.total.funcionario_carga_horaria += this.listaEscalas[i].funcionario_carga_horaria;

      let tam_atual = this.listaEscalas[i].escalas.length;
      if (this.total.escalas == null) {
        this.total.escalas = [];
      }
      //var total_turno2 = "00:00:00";
      //this.listaEscalas[i].escalas[i];
      ////for (var j = 0; j < tam_atual; j++) {
      //for (var j = 0; j < this.diasMes.length; j++) {
      //  this.listaEscalas[i].escalas[0];
      //  var turno_horas = this.listaEscalas[i].escalas[0].turno_horas;
      //  if (turno_horas == null || turno_horas == 0) {
      //    this.listaEscalas[i].escalas[j].turno_horas = "00:00:00";
      //  }
      //  total_turno2 = this.global.addHora(total_turno2, this.listaEscalas[i].escalas[j].turno_horas);
      //}
      //this.total.escalas.push(total_turno2);


    }
    this.total.escalas = [];
    let val_dia = '00:00:00';
    for (let dia = 0; dia < this.diasMes.length; dia++) {
      for (let i = 0; i < this.listaEscalas.length; i++) {
        val_dia = this.global.addHora(this.listaEscalas[i].escalas[dia].turno_horas, val_dia);
      }
      this.total.escalas.push(val_dia);
      val_dia = '00:00:00';
    }
  }

  obterCargos(setor_uuid) {
    this.listaCargos = null;
    this.filtro.cargo = null;
    if (setor_uuid == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get('Setor/' + setor_uuid + '/Cargos?ativo=true&gerente=true').then(
      result => {
        this.listaCargos = result;
        this.loading = false;
        this.filtro.cargo = 0;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterMeses() {
    this.loading = true;
    this.listaMeses = [
      { id: 1, nome: 'Janeiro' },
      { id: 2, nome: 'Fevereiro' },
      { id: 3, nome: 'Março' },
      { id: 4, nome: 'Abril' },
      { id: 5, nome: 'Maio' },
      { id: 6, nome: 'Junho' },
      { id: 7, nome: 'Julho' },
      { id: 8, nome: 'Agosto' },
      { id: 9, nome: 'Setembro' },
      { id: 10, nome: 'Outubro' },
      { id: 11, nome: 'Novembro' },
      { id: 12, nome: 'Dezembro' }
    ];
    this.filtro.mes = (new Date()).getMonth() + 1;
  }

  obterCargosETurno(setor_uuid) {
    this.obterCargos(setor_uuid);
    this.obterTurnos(setor_uuid)
  }

  obterTurnos(setor_uuid) {
    this.loading = true;
    // this.apiService.Get('Setor/' + this.filtro_selecionado.setor.uuid + '/Turnos').then(
    this.apiService.Get('Setor/' + setor_uuid + '/Turnos?ativo=true').then(
      result => {
        this.listaTurnos = result;
        this.filtro.turno = 0;
        // this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  valida_filtro() {
    if (this.filtro.unidade == null || this.filtro.unidade == 0 || this.filtro.unidade == '0') {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira a unidade'
      });
      return false;
    }
    if (this.filtro.setor == null || this.filtro.setor == 0 || this.filtro.setor == '0') {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira a unidade'
      });
      return false;
    }
    //  if (this.filtro.cargo == null || this.filtro.cargo == 0 || this.filtro.cargo == '0') {
    //   Swal.fire({
    //     type: 'error',
    //     title: 'oops...',
    //     text: 'Insira o cargo'
    //   });
    //   return false;
    //  }

    if (this.filtro.turno == null || this.filtro.turno == 0 || this.filtro.turno == '0') {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira o turno'
      });
      return false;
    }

    if (this.filtro.ano == null || this.filtro.ano == '' || this.filtro.ano == 0 || this.filtro.ano.length < 4) {

      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira o ano!'
      });
      return false;
    }
    if (this.filtro.mes == null) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira o mes'
      });
      return false;
    }
    return true;
  }

  listar() {
    if (this.valida_filtro()) {
      this.listaEscalas = [];
      this.showPaginas = false;
      this.turnoPesquisado = {};
      if (this.filtro.cargo == 0) {
        this.filtro_selecionado = {};
        this.filtro_selecionado.ano = this.filtro.ano;
        this.filtro_selecionado.mes = this.filtro.mes;
        this.filtro_selecionado.setor = this.filtro.setor;
        this.filtro_selecionado.unidade = this.filtro.unidade;
        this.filtro_selecionado.turno = this.filtro.turno;
        this.obterFeriados();
        if(this.filtro.turno.exibir_sabado_domingo != null && this.filtro.turno.exibir_sabado_domingo == true){
          this.contadorMesRetirarFinaisSemana();
        }else{
          this.contadorMes();
        }
        // this.obterTurnos();
        this.obterEscalas(1);
      }
      else {
        this.filtro_selecionado = this.filtro;
        this.obterFeriados();
        if(this.filtro_selecionado.turno.exibir_sabado_domingo != null && this.filtro_selecionado.turno.exibir_sabado_domingo == true){
          this.contadorMesRetirarFinaisSemana();
        }else{
          this.contadorMes();
        };
        // this.contadorMesRetirarFinaisSemana();
        // this.obterTurnos();
        this.obterEscalas(1);
      }
    } else {
      this.loading = false;
      return;
    }
  }

  finalizarEscala(filtro) {
    Swal.fire({
      title:'Atenção',
      text: 'Ao finalizar a escala, não será possível modificá-la depois. Deseja continuar?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        let escala = {
          grupo_escala_uuid: this.listaGrupoEscalas,
        };
        this.loading = true;
        this.apiService.Post("GrupoEscala/Finalizar", escala).then(
          (result) => {
            this.loading = false;
            Swal.fire({
              type: "success",
              title: "Sucesso!",
              text: "Escala salva com sucesso!",
            });
          },
          (err) => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            this.loading = false;
            Swal.fire({
              type: "error",
              title: "Oops...",
              text: err.error.mensagem,
            });
          }
        );
      } else {
        return
      }
    })
  }

  selecionarColuna(dia) {
    for (let i = 0; i < this.listaEscalas.length; i++) {
      for (let j = 0; j < this.listaEscalas[i].escalas.length; j++) {
        if (this.listaEscalas[i].escalas[j].escala_dia == dia) {
          this.listaEscalas[i].escalas[j].selecionado = !this.listaEscalas[i].escalas[j].selecionado;
        }
      }
    }
  }

  selecionarLinha(index) {
    for (let i = 0; i < this.listaEscalas.length; i++) {
      for (let j = 0; j < this.listaEscalas[i].escalas.length; j++) {
        if (i == index) {
          this.listaEscalas[i].escalas[j].selecionado = !this.listaEscalas[i].escalas[j].selecionado;
        }
      }
    }
  }

  validarDia(item) {
    const dia = this.diasMes.filter(x => x.day == item.escala_dia);
    if (dia.length == 0) {
      return;
    }
    if (dia[0].daySemana == 'D') {
      return true;
    } if (dia[0].daySemana == 'S') {
      const dia_anterior = item.escala_dia - 1;
      const dia_proximo = item.escala_dia + 1;
      if (dia_anterior != 0) {
        if(this.diasMes.filter(x => x.day == dia_anterior)[0] != null){
          const check_day1 = this.diasMes.filter(x => x.day == dia_anterior)[0].daySemana == 'S';
          if (check_day1) {
            return true;
          }
        }else{
          return false;
        }
      } else {
        if(this.diasMes.filter(x => x.day == dia_proximo)[0] != null){
          const check_day2 = this.diasMes.filter(x => x.day == dia_proximo)[0].daySemana == 'D';
          if (check_day2) {
            return true;
          }
        }else{
          return false;
        }
      }
    } else {
      return false;
    }
  }

  checkFeriado(item) {
    // tslint:disable-next-line: triple-equals
    const dia = this.diasMes.filter(x => x.day == item.escala_dia);
    // tslint:disable-next-line: triple-equals
    if (dia.length == 0) {
      return false;
    }
    // tslint:disable-next-line: triple-equals
    if (this.listaFeriados.length == 0) {
      return false;
    }
    // tslint:disable-next-line: max-line-length
    const data = new Date(`${this.filtro.ano}-${this.filtro.mes < 10 ? '0' + this.filtro.mes : this.filtro.mes}-${dia[0].day < 10 ? '0' + dia[0].day : dia[0].day}T00:00:00`);
    // let feriado = this.listaFeriados.filter(x => x.data_inicio >= data && data <= x.data_fim);
    let marcar = false;
    //console.log(data.toLocaleDateString());
    this.listaFeriados.forEach(function (item) {
      //debugger
      if (data > item.data_inicio && data < item.data_fim) {
        //console.log('esta dentro da data');
        marcar = true;
      } else {
        if (data.toLocaleDateString() == item.data_inicio.toLocaleDateString() || data.toLocaleDateString() == item.data_fim.toLocaleDateString()) {
          //console.log('a data é a mesma');
          marcar = true;
        }
      }
    });

    return marcar;
  }

  openDialogModalCopiaEscala(esc): void {
    if (this.filtro_selecionado.cargo == undefined){
      var cargo = {
        nome: esc.cargo_nome,
        uuid: esc.cargo_uuid
      };
      this.filtro_selecionado.cargo =  cargo;
    }   
    var obj = {
      filtro: this.filtro_selecionado,
      colaborador: esc
    }
    // var obj = {
    //     filtro: this.filtro_selecionado,
    //     colaborador: esc
    //   }
    let dialogRef = this.dialog.open(ModalCopiaEscalaComponent, { data: obj });
    dialogRef.afterClosed().subscribe(result => {
      this.listar();
    });
  }

  listarGrupoEscalas = () => {
    var grupo: any = [];
    var i = this.listaEscalas.length;
    for(var x = 0; x < i; x++) {
      var j = this.listaEscalas[x].escalas.length;
      for (var y = 0; y < j; y++){
        if (this.listaEscalas[x].escalas[y].grupo_escala_uuid != undefined){
          grupo[x] = this.listaEscalas[x].escalas[y].grupo_escala_uuid;
        }
      }
    }
    this.listaGrupoEscalas = grupo.filter(this.separaGrupoEscalas)
  }

  separaGrupoEscalas = (value, index, self) =>{
    return self.indexOf(value) === index;
  }

  //paginação  
  primeira(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.obterEscalas(this.indice_selecionado);
    }
  }

  anterior(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
      this.obterEscalas(this.indice_selecionado);
    }
  }

  atual(indice, pesquisa) {
    if (this.indice_selecionado != indice) {
      this.indice_selecionado = indice;
      this.obterEscalas(this.indice_selecionado);
    } else {
      this.indice_selecionado = indice;
      this.obterEscalas(this.indice_selecionado);
    }
  }

  proxima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.obterEscalas(this.indice_selecionado);
    }
  }

  ultima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
      this.obterEscalas(this.indice_selecionado);
    }
  }

  //fim paginação

  contadorMesRetirarFinaisSemana() {
    this.diasMes = [];
    for (let month = 0; month < this.diasNoMes(this.filtro_selecionado.ano, this.filtro_selecionado.mes); month++) {
      if(this.retornarValorSemana(this.filtro_selecionado.ano, this.filtro_selecionado.mes, month + 1) != 6 &&
         this.retornarValorSemana(this.filtro_selecionado.ano, this.filtro_selecionado.mes, month + 1) != 0){
        let day = month + 1;
        this.diasMes.push({ day: day, daySemana: this.diaDaSemana(this.filtro_selecionado.ano, this.filtro_selecionado.mes, day) });
      }
    }
  }

  retornarValorSemana(ano, mes, dia) {
    let dataMes = new Date(ano, mes - 1, dia);
    let diaSemana = dataMes.getDay();
    return diaSemana;
  }
}
