import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ModalFrequenciaComponent } from '../../components/modal-frequencia/modal-frequencia.component';

import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { ApiService } from '../../services/api.service';
import { Global } from '../../global';
import { Router } from '@angular/router';




@Component({
  selector: 'app-diario-frequencia',
  templateUrl: './diario-frequencia.component.html',
  styleUrls: ['./diario-frequencia.component.css']
})
export class DiarioFrequenciaComponent implements OnInit {
  novoDiarioFrequencia: boolean = false;
  diarioFrequencia: any = {};

  filtro: any = {};
  filtro_selecionado: any = {};
  carga_horaria: any = null;

  loading = false;
  visualizando: any = false;

  listaUnidades: any = [];
  listaSetores: any = [];
  listaCargos: any = [];
  listaTurnos: any = [];
  listaTolerancias: any = [];

  listaDiarioFrequencia: any = [];
  listaFuncionarios: any = [];

  buscaVazia: boolean = false;
  srcImgPerfilDefault: any = '../../../../assets/img/img_perfil_default.jpg';

  currentUser: any = {};
  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    public global: Global,
    private router: Router,

  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.filtro.unidade = 0;
      this.filtro.tolerancia = 0;
      this.filtro.turno = 0;
      this.filtro.data = new Date().toLocaleDateString();
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterUnidades();
    this.obterTurnos();
    this.obterTolerancias();
  }

  openDialogVerFreq(funcionario_uuid): void {
    let dialogRef = this.dialog.open(ModalFrequenciaComponent, {
      data: {
        funcionario_uuid: funcionario_uuid,
        data_filtrada: this.filtro_selecionado.data_busca
      }
    });
  }

  obterCargos(setor_id) {
    this.listaCargos = null;
    this.filtro.cargo = null;
    if (setor_id == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get("Setor/" + setor_id + "/Cargos?ativo=true").then(
      result => {
        this.listaCargos = result;
        this.loading = false;
        this.filtro.cargo = 0;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterTurnos() {
    this.loading = true;
    this.apiService.Get("Turno?ativo=true").then(
      result => {
        this.listaTurnos = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterTolerancias() {
    this.loading = true;
    this.listaTolerancias = [];
    for (var i = 5; i <= 30; i = i + 5) {
      this.listaTolerancias.push(i);
    }
    this.loading = false;
  }


  obterDiarioFrequencia() {
    if (this.filtro.unidade == 0 || this.filtro.unidade == "0") {
      Swal.fire({
        type: 'error',
        title: 'Erro',
        text: 'Favor inserir uma unidade válida'
      });
      return;
    }

    if (this.filtro.setor == 0 || this.filtro.setor == "0") {
      Swal.fire({
        type: 'error',
        title: 'Erro',
        text: 'Favor inserir um setor válido'
      });
      return;
    }

    if (this.filtro.tolerancia == 0 || this.filtro.tolerancia == "0") {
      Swal.fire({
        type: 'error',
        title: 'Erro',
        text: 'Favor inserir uma tolerância válido'
      });
      return;
    }
    if (this.filtro.tolerancia == 0 || this.filtro.tolerancia == "0") {
      Swal.fire({
        type: 'error',
        title: 'Erro',
        text: 'Favor inserir uma tolerância válido'
      });
      return;
    }

    this.loading = true;
    if (this.filtroValido()) {

      if (this.filtro.cargo == 0 && this.filtro.turno == 0) {
        var txt = "setor_uuid=" + this.filtro.setor + "&tolerancia=" + this.filtro.tolerancia + "&data=" + this.filtro.data_busca
      }
      else if (this.filtro.cargo == 0 && this.filtro.turno != 0) {
        var txt = "setor_uuid=" + this.filtro.setor + "&turno_uuid=" + this.filtro.turno + "&tolerancia=" + this.filtro.tolerancia + "&data=" + this.filtro.data_busca
      }
      else if (this.filtro.cargo != 0 && this.filtro.turno == 0) {
        var txt = "setor_uuid=" + this.filtro.setor + "&cargo_uuid=" + this.filtro.cargo + "&tolerancia=" + this.filtro.tolerancia + "&data=" + this.filtro.data_busca
      }
      else {
        var txt = "setor_uuid=" + this.filtro.setor + "&cargo_uuid=" + this.filtro.cargo + "&turno_uuid=" + this.filtro.turno + "&tolerancia=" + this.filtro.tolerancia + "&data=" + this.filtro.data_busca
      }
      this.filtro_selecionado = this.filtro;

      this.apiService.Get("DiarioFrequencias?" + txt).then(
        result => {
          this.listaDiarioFrequencia = result;
          this.validarFotoPerfil(1)
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
    }
    else {
      this.loading = false;
    }

  }

  validarFotoPerfil = (index: any) => {
    if (index == this.listaDiarioFrequencia.length) {
      this.loading = false
      return
    }
    if (this.listaDiarioFrequencia.every(el => el.foto_perfil)) {
      this.loading = false
      return
    }
    else {
      if (!this.listaDiarioFrequencia[index].foto_perfil) {
        this.obterFotoPerfil(this.listaDiarioFrequencia[index]['uuid']).then(
          result => {
            if (!result) {
              this.listaDiarioFrequencia[index].link_perfil = this.srcImgPerfilDefault;
              this.validarFotoPerfil(index + 1);
              return
            } else {
              this.listaDiarioFrequencia[index].link_perfil = result['foto_perfil_link']
              if (!(this.listaDiarioFrequencia[index].link_perfil.includes(".jpg")
                && this.listaDiarioFrequencia[index].link_perfil.includes(".png")))
                this.listaDiarioFrequencia[index].link_perfil = this.srcImgPerfilDefault;
              this.validarFotoPerfil(index + 1);
            }
          },
          error => {
            this.listaDiarioFrequencia[index].link_perfil = this.srcImgPerfilDefault;
            this.validarFotoPerfil(index + 1);
          }
        )
      } else this.validarFotoPerfil(index + 1);
    }
  }

  obterFotoPerfil = (funcionario: any) => {
    return new Promise((resolve, reject) => {
      this.apiService.Get(`Funcionarios/${funcionario}/Perfil`).then(
        result => {
          resolve(result)
        },
        error => {
          reject(error)
        }
      )
    }
    )
  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get("Unidades?ativo=true&gerente=true").then(
      result => {
        this.listaUnidades = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterSetores(unidade_id) {
    this.listaSetores = null;
    this.listaCargos = null;
    this.filtro.setor = null;
    this.filtro.cargo = null;
    if (unidade_id == 0) {
      return null;
    }
    this.apiService.Get("Unidades/" + unidade_id + "/Setores?ativo=true&gerente=true").then(
      result => {
        this.listaSetores = result;
        this.filtro.setor = 0;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  filtroValido() {
    if (this.filtro.unidade == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a unidade!'
      });
      return false;
    }
    if (this.filtro.setor == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o setor!'
      });
      return false;
    }

    if (this.filtro.tolerancia == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a tolerância!'
      });
      return false;
    }
    if (this.filtro.data == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a data!'
      });
      return false;
    }

    if (this.filtro.data != null) {
      this.filtro.data_busca = this.filtro.data.split('/')[2] + "-" + this.filtro.data.split('/')[1] + "-" + this.filtro.data.split('/')[0]
    }

    return true;
  }



}
