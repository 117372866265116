import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import Swal from 'sweetalert2';


import { Router, Event as NavigationEvent } from "@angular/router";
import { Global } from '../../global';
import { ApiService } from '../../services/api.service';
import { ModalAcompanhamentoComponent } from '../../components/modal-acompanhamento/modal-acompanhamento.component';
import { ModalAprovaTrocaComponent } from '../../components/modal-aprova-troca/modal-aprova-troca.component';

@Component({
  selector: 'app-escala_acompanhamento',
  templateUrl: './escala_acompanhamento.component.html',
  styleUrls: ['./escala_acompanhamento.component.css']
})
export class EscalaAcompanhamentoComponent implements OnInit {
  loading: any = false;

  filtro: any = { unidade: 0 };
  filtro_selecionado: any = {};

  diasMes = [];
  listaCargos: any = [];
  listaUnidades: any = [];
  listaSetores: any = [];
  listaMeses: any = [];
  listaFuncionarios: any = [];

  objEscalas: any = {};
  listaEscalas: any = [];
  total: any = {};

  hora_cadastrada: any = "00:00:00";
  hora_a_cadastrar: any = "00:00:00";
  banco_de_horas: any = "00:00:00";

  dia_atual: any;
  mes_atual: any;
  ano_atual: any;


  func = [];
  elem: any = 0;

  currentUser: any = {};

    //paginação
    cabecalho: any = {};
    indice_selecionado: any = 0;
    pesquisa: any = '';
    showPaginas: boolean = false;
    indice = 1;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  add() { }

  ngOnInit() {
    this.dia_atual = (new Date()).getDate();
    this.mes_atual = (new Date()).getMonth() + 1;
    this.ano_atual = (new Date()).getFullYear();
    this.obterUnidades();
    this.obterMeses();
    this.filtro.ano = (new Date()).getFullYear();
  }

  diasNoMes(ano, mes) {
    var data = new Date(ano, mes, 0);
    return data.getDate();
  }

  diaDaSemana(ano, mes, dia) {
    var semana = ["D", "S", "T", "Q", "Q", "S", "S"];
    var teste = new Date(ano, mes - 1, dia);
    var diaSemana = teste.getDay();
    return semana[diaSemana];
  }

  contadorMes() {
    this.diasMes = [];
    for (var month = 0; month < this.diasNoMes(this.filtro_selecionado.ano, this.filtro_selecionado.mes); month++) {
      var day = month + 1;
      this.diasMes.push({ day: day, daySemana: this.diaDaSemana(this.filtro_selecionado.ano, this.filtro_selecionado.mes, day) });
    }
  }

  obterFuncionarios() {
    this.loading = true;
    this.apiService.Get("Funcionarios").then(
      result => {
        this.listaFuncionarios = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get("Unidades?ativo=true&gerente=true").then(
      result => {
        this.listaUnidades = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterSetores(unidade_id) {
    localStorage.setItem('unidadeEscolhida', unidade_id)
    this.listaSetores = null;
    this.listaCargos = null;
    this.filtro.setor = null;
    this.filtro.cargo = null;
    if (unidade_id == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get("Unidades/" + unidade_id + "/Setores?ativo=true&gerente=true").then(
      result => {
        this.listaSetores = result;
        this.filtro.setor = 0;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  lista_escala_default(escalas) {
    var lista_escala = [];
    for (var j = 0; j < this.diasMes.length; j++) {
      lista_escala.push(
        {
          turno_nome: "",
          turno_sigla: "",
          turno_horas: "00:00:00",
          escala_mes: this.filtro_selecionado.mes,
          escala_ano: this.filtro_selecionado.ano
          //escala_dia: j+1
        }
      );
    }
    this.hora_cadastrada = "00:00";
    for (var i = 0; i < escalas.length; i++) {
      lista_escala.splice(escalas[i].escala_dia - 1, 0, (escalas[i]));
      lista_escala.pop();
      this.hora_cadastrada = this.global.addHora(this.hora_cadastrada, escalas[i].turno_horas);
    }
    for (var j = 0; j < this.diasMes.length; j++) {
      lista_escala[j].escala_dia = j + 1;
    }
    return lista_escala;
  }

  obterEscalas(indice) {
    this.loading = true;
    if (this.filtro_selecionado.cargo == undefined) {
      var txt = `setor_uuid=${this.filtro_selecionado.setor.uuid}&ano=${this.filtro_selecionado.ano}&mes=${this.filtro_selecionado.mes}&pagina=${indice}`
    }
    else {
      var txt = `setor_uuid=${this.filtro_selecionado.setor.uuid}&cargo_uuid=${this.filtro_selecionado.cargo.uuid}&ano=${this.filtro_selecionado.ano}&mes=${this.filtro_selecionado.mes}&pagina=${indice}`
    }
    this.apiService.Get('Escalas?'+ txt).then(
      result => {
        this.objEscalas = result['conteudo'];
        this.listaEscalas = this.objEscalas.escalas;

        for (var i = 0; i < this.listaEscalas.length; i++) {
          var vet_banco_hora = this.listaEscalas[i].banco_hora_total.split(":");
          if (vet_banco_hora.length == 3) {
            this.listaEscalas[i].banco_hora_total = vet_banco_hora[0] + ":" + vet_banco_hora[1];
          }
          this.listaEscalas[i].escalas = this.lista_escala_default(this.listaEscalas[i].escalas);
          this.listaEscalas[i].hora_cadastrada = this.hora_cadastrada;
          var horario_ = this.global.minusHora(this.listaEscalas[i].funcionario_carga_horaria + ":00:00", this.hora_cadastrada);
          if (parseInt(horario_.split(":")[0]) < 0) {
            this.listaEscalas[i].hora_a_cadastrar = "00:00";
          }
          else {
            this.listaEscalas[i].hora_a_cadastrar = horario_;
          }
        }
        this.obterTotais();

        if (result['total_paginas'] > 1){
          this.showPaginas = true;
          this.indice_selecionado = result['pagina_atual'];
          this.cabecalho.total_paginas = result['total_paginas'];
        }
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterEscalas_apos_delete(celulas) {
    this.loading = true;
    let txt = "setor_uuid=" + this.filtro_selecionado.setor.uuid + "&cargo_uuid=" + this.filtro_selecionado.cargo.uuid + "&ano=" + this.filtro_selecionado.ano + "&mes=" + this.filtro_selecionado.mes + '&pagina=1';
      this.apiService.Get('Escalas?'+ txt).then(
      result => {
        this.objEscalas = result['conteudo'];
        this.listaEscalas = this.objEscalas.escalas;
        
        for (var i = 0; i < this.listaEscalas.length; i++) {
          var vet_banco_hora = this.listaEscalas[i].banco_hora_total.split(":");
          if (vet_banco_hora.length == 3) {
            this.listaEscalas[i].banco_hora_total = vet_banco_hora[0] + ":" + vet_banco_hora[1];
          }
          this.listaEscalas[i].escalas = this.lista_escala_default(this.listaEscalas[i].escalas);
          this.listaEscalas[i].hora_cadastrada = this.hora_cadastrada;
          var horario_ = this.global.minusHora(this.listaEscalas[i].funcionario_carga_horaria + ":00:00", this.hora_cadastrada);
          if (parseInt(horario_.split(":")[0]) < 0) {
            this.listaEscalas[i].hora_a_cadastrar = "00:00";
          }
          else {
            this.listaEscalas[i].hora_a_cadastrar = horario_;
          }
        }
        this.obterTotais();
        if (result['total_paginas'] > 1){
          this.showPaginas = true;
          this.indice_selecionado = result['pagina_atual'];
          this.cabecalho.total_paginas = result['total_paginas'];
        }
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterTotais() {
    this.loading = true;
    this.total.hora_cadastrada = "00:00:00";
    this.total.hora_a_cadastrar = "00:00:00";
    this.total.banco_de_horas = "00:00:00";
    this.total.funcionario_carga_horaria = 0;
    for (var i = 0; i < this.listaEscalas.length; i++) {
      this.total.hora_cadastrada = this.global.addHora(this.listaEscalas[i].hora_cadastrada, this.total.hora_cadastrada);
      this.total.hora_a_cadastrar = this.global.addHora(this.listaEscalas[i].hora_a_cadastrar, this.total.hora_a_cadastrar);
      this.total.banco_de_horas = this.global.addHora(this.listaEscalas[i].banco_hora_total, this.total.banco_de_horas);
      this.total.funcionario_carga_horaria += this.listaEscalas[i].funcionario_carga_horaria;

      var tam_atual = this.listaEscalas[i].escalas.length;
      if (this.total.escalas == null) {
        this.total.escalas = [];
      }

    }
    this.total.escalas = [];
    var val_dia = "00:00:00";
    for (var dia = 0; dia < this.diasMes.length; dia++) {
      for (var i = 0; i < this.listaEscalas.length; i++) {
        val_dia = this.global.addHora(this.listaEscalas[i].escalas[dia].turno_horas, val_dia);
      }
      this.total.escalas.push(val_dia);
      val_dia = "00:00:00";
    }
    this.loading = false;
  }

  obterCargos(setor_uuid) {
    localStorage.setItem('setorEscolhido', setor_uuid)
    this.listaCargos = null;
    this.filtro.cargo = null;
    if (setor_uuid == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get("Setor/" + setor_uuid + "/Cargos?ativo=true&gerente=true").then(
      result => {
        this.listaCargos = result;
        this.loading = false;
        this.filtro.cargo = 0;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterMeses() {
    this.loading = true;
    this.listaMeses = [
      { id: 1, nome: 'Janeiro' },
      { id: 2, nome: 'Fevereiro' },
      { id: 3, nome: 'Março' },
      { id: 4, nome: 'Abril' },
      { id: 5, nome: 'Maio' },
      { id: 6, nome: 'Junho' },
      { id: 7, nome: 'Julho' },
      { id: 8, nome: 'Agosto' },
      { id: 9, nome: 'Setembro' },
      { id: 10, nome: 'Outubro' },
      { id: 11, nome: 'Novembro' },
      { id: 12, nome: 'Dezembro' }
    ];
    this.filtro.mes = (new Date()).getMonth() + 1;
  }

  valida_filtro() {
    if (this.filtro.unidade == null || this.filtro.unidade == 0 || this.filtro.unidade == "0") {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira a unidade'
      });
      return false;
    }
    if (this.filtro.setor == null || this.filtro.setor == 0 || this.filtro.setor == "0") {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira a unidade'
      });
      return false;
    }
    if (this.filtro.ano == null || this.filtro.ano == "" || this.filtro.ano == 0 || this.filtro.ano.length < 4) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira o ano!'
      });
      return false;
    }
    if (this.filtro.mes == null) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira o mes'
      });
      return false;
    }
    return true;
  }


  listar(indice) {
    if (this.valida_filtro()) {

      if (this.filtro.cargo == 0) {
        this.filtro_selecionado = {};
        this.filtro_selecionado.ano = this.filtro.ano;
        this.filtro_selecionado.mes = this.filtro.mes;
        this.filtro_selecionado.setor = this.filtro.setor;
        this.filtro_selecionado.unidade = this.filtro.unidade;
        this.contadorMes();
        this.obterEscalas(indice);
      }
      else {
        this.filtro_selecionado = this.filtro;   
        this.contadorMes();
        this.obterEscalas(indice);
      }

      // this.filtro_selecionado = this.filtro;
      // this.contadorMes();
      // this.obterEscalas();
    }
    else {
      this.loading = false;
      return;
    }
  }


  openDialog(funcionario_id, escala): void {
    localStorage.setItem('funcionarioEscolhido', funcionario_id);
    if (escala.consistencia == 'INCONSISTENTE' || escala.consistencia == 'CONSISTENTE') {
      if (escala.turno_sigla != null && escala.turno_sigla != "") {
        let dialogRef = this.dialog.open(ModalAcompanhamentoComponent, {
          data: {
            funcionario_id: funcionario_id,
            data:  escala.escala_ano + "-" + (('0'+escala.escala_mes).slice(-2)) + "-" + (('0'+escala.escala_dia).slice(-2)),
            escala: {
              dia: (('0'+escala.escala_dia).slice(-2)) + "/" + (('0'+escala.escala_mes).slice(-2)) + "/" + escala.escala_ano,
              turno: escala.turno_sigla,
              turno_nome: escala.turno_nome
            },
            executado: {
              lista_pontos: [{ ordem_ponto: 1, hora: "07:05" }, { ordem_ponto: 2, hora: "12:05" }, { ordem_ponto: 3, hora: "13:05" }, { ordem_ponto: 4, hora: "18:05" }],
              total_horas: "12:05",
              atraso: "00:07"
            },
            justificativa_cadastrada: "perdi a vontade de viver"
          }
        });
      }
    }
    else if (escala.consistencia == 'TROCA') {
      this.openAprovaTroca(escala.troca_uuid);
    }
    else {
      return;
    }
  }

  openAprovaTroca(escala_uuid): void {
    this.apiService.GetOne("TrocaEscalas", escala_uuid).then(
      result => {
        var escala = result;
        this.loading = false;
        let dialogRef = this.dialog.open(ModalAprovaTrocaComponent, { data: escala });
        dialogRef.afterClosed().subscribe(result => {
          this.obterEscalas(this.indice);
          this.loading = false;
        });
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
    this.loading = false;
  }

  validarDia(item) {
    let dia = this.diasMes.filter(x => x.day == item.escala_dia);
    if (dia.length == 0) {
      return
    }
    if (dia[0].daySemana == "D") {
      return true;
    } if (dia[0].daySemana == "S") {
      let dia_anterior = item.escala_dia - 1;
      let dia_proximo = item.escala_dia + 1;
      if (dia_anterior != 0) {
        let check_day1 = this.diasMes.filter(x => x.day == dia_anterior)[0].daySemana == "S";
        if (check_day1) {
          return true;
        }
      } else {
        let check_day2 = this.diasMes.filter(x => x.day == dia_proximo)[0].daySemana == "D";
        if (check_day2) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  //paginação  
  primeira(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.listar(this.indice_selecionado);
    }
  }

  anterior(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
      this.listar(this.indice_selecionado);
    }
  }

  atual(indice, pesquisa) {
    if (this.indice_selecionado != indice) {
      this.indice_selecionado = indice;
      this.listar(this.indice_selecionado);
    } else {
      this.indice_selecionado = indice;
      this.listar(this.indice_selecionado);
    }
  }

  proxima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.listar(this.indice_selecionado);
    }
  }

  ultima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
      this.listar(this.indice_selecionado);
    }
  }

}
