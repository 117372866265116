import { Component, OnInit, NgModule } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { Global } from '../../global';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usuarioId: any;
  login: any = {};
  auth: any = {};
  User: any = {};
  desativar_botao: any = false;
  loading: any = false;
  currentUser: any;
  versaoSistema: any;

  returnUrl: string;
  passo2: boolean = false;
  constructor(
    private apiService: ApiService,
    public router: Router,
    public route: ActivatedRoute,
    public global: Global
  ) {
    //this.usuarioId = data.usuario_id;
  }

  valida(login) {
    if (login.senha == null || login.senha == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Senha Inválida!'
      });
      this.loading = false;
      this.desativar_botao = false;
      return false;
    }
    if (login.login == null || login.login == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Login Inválido!'
      });
      this.loading = false;
      this.desativar_botao = false;
      return false;
    }
    return true;
  }

  submit(login) {
    this.desativar_botao = true;
    this.loading = true;
    if (this.valida(login)) {
      let usuario = {
        login: login.login,
        senha: this.global.md5Normal(login.senha)
      }
      this.apiService.PostAuths("Auths", usuario)
        .then(
          result => {
            this.User = result;
            this.currentUser = result;
            localStorage.setItem("currentUser", JSON.stringify(result));
            this.router.navigate(['/']);
            this.loading = false;
            //this.auth = result;
            //this.login.uuid_auth = this.auth.uuid;
          },
          err => {
            this.loading = false;
            this.desativar_botao = false;
            this.login.senha = "";
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
          }
        );
    };
  }

  logout() {
    localStorage.setItem("currentUser", null);
    localStorage.removeItem("currentUser");
    //this.global.currentUser = {};
  }

  ngOnInit() {
    localStorage.clear();
    this.logout();
    this.obterVersao();
    //this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/dash";
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  obterVersao() {
    this.apiService.GetNoAuthorization("Versao")
      .then(
        result => {
          this.versaoSistema = result["versao"];
        },
        err => {
          this.loading = false;
           if (err.status == 401) {
             this.router.navigate(["/login"]);
             return;
           }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }


}
