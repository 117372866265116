import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import Swal from 'sweetalert2';

import { Router, Event as NavigationEvent } from '@angular/router';
import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-modal-copia-escala',
  templateUrl: './modal-copia-escala.component.html',
  styleUrls: ['./modal-copia-escala.component.css']
})
export class ModalCopiaEscalaComponent implements OnInit {
  loading: any = false;

  filtro: any = { unidade: 0 };
  filtro_selecionado: any = {};

  listaUnidades: any = [];
  listaUnidadesDestino: any = [];
  listaSetores: any = [];
  listaSetoresDestino: any = [];
  listaCargos: any = [];
  listaCargosDestino: any = [];
  listaFuncionarios: any = [];
  listaFuncionariosDestino: any = [];
  nome_unidade: any;
  checked: any;
  funcionarios: any = [];
  funcionarios_dados: any = [];

  listaMeses: any = [];
  ano_atual: any;
  diasMes = [];
  listaReq: any = {};

  funcio: any;
  vinc: any;
  evento: any;


  currentUser: any = {};

  constructor(
    public dialogRef: MatDialogRef<ModalCopiaEscalaComponent>,
    private apiService: ApiService,
    public global: Global,
    private router: Router,
  ) {
    try {
      if (localStorage.getItem('currentUser') == null) {
        this.router.navigate(["/login"]);
      }
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.filtro.mes = 0;
    } catch (err) {
      this.router.navigate(['/login']);
    }
   }

  ngOnInit() {
    this.obterUnidades();
    this.obterUnidadesDestino();
    this.obterMeses();
    this.obterDadosFiltro();
  }

  obterDadosFiltro() {
    let mes = this.dialogRef._containerInstance._config.data.filtro.mes;
    switch (mes) {
      case 1: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Janeiro";
        break;
      }
      case 2: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Fevereiro";
        break;
      }

      case 3: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Março";
        break;
      }
      case 4: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Abril";
        break;
      }
      case 5: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Maio";
        break;
      }
      case 6: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Junho";
        break;
      }
      case 7: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Julho";
        break;
      }
      case 8: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Agosto"
        break;
      }
      case 9: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Setembro";
        break;
      }
      case 10: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Outubro";
        break;
      }
      case 11: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Novembro";
        break;
      }
      case 12: {
        this.dialogRef._containerInstance._config.data.filtro.mes = "Dezembro";
        break;
      }
      default: {
        break;
      }
    }

    this.listaReq = this.dialogRef._containerInstance._config.data;
  }

  fechaModal() {
    let mes = this.dialogRef._containerInstance._config.data.filtro.mes;
    switch (mes) {
      case "Janeiro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 1;
        break;
      }
      case "Fevereiro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 2;
        break;
      }

      case "Março": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 3;
        break;
      }
      case "Abril": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 4;
        break;
      }
      case "Maio": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 5;
        break;
      }
      case "Junho": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 6;
        break;
      }
      case "Julho": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 7;
        break;
      }
      case "Agosto": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 8;
        break;
      }
      case "Setembro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 9;
        break;
      }
      case "Outubro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 10;
        break;
      }
      case "Novembro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 11;
        break;
      }
      case "Dezembro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 12;
        break;
      }
      default: {
        break;
      }
    }
    this.dialogRef.close();
  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get('Unidades?ativo=true&gerente=true').then(
      result => {
        this.listaUnidades = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterSetores(unidade_id) {
    this.listaSetores = null;
    this.listaCargos = null;
    this.filtro.setor = null;
    this.filtro.cargo = null;
    if (unidade_id == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get('Unidades/' + unidade_id + '/Setores?ativo=true&gerente=true').then(
      result => {
        this.listaSetores = result;
        this.filtro.setor = 0;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterCargos(setor_uuid) {
    this.listaCargos = null;
    this.filtro.cargo = null;
    if (setor_uuid == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get('Setor/' + setor_uuid + '/Cargos?ativo=true&gerente=true').then(
      result => {
        this.listaCargos = result;
        this.loading = false;
        this.filtro.cargo = 0;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterFuncionarios(setorUuid, cargoUuid) {
    this.listaFuncionarios = null;
    this.filtro.funcionario = null;
    this.loading = true;
    this.apiService.Get("Funcionarios?setor=" + setorUuid + "&cargo=" + cargoUuid).then(
      result => {
        this.listaFuncionarios = result;
        //console.log(this.listaFuncionarios);
        this.filtro.funcionario = 0;
        this.loading = false;
      },
      err => {
        console.log(err);
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }      
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterMeses() {
    this.loading = true;
    this.listaMeses = [
      { id: 1, nome: 'Janeiro' },
      { id: 2, nome: 'Fevereiro' },
      { id: 3, nome: 'Março' },
      { id: 4, nome: 'Abril' },
      { id: 5, nome: 'Maio' },
      { id: 6, nome: 'Junho' },
      { id: 7, nome: 'Julho' },
      { id: 8, nome: 'Agosto' },
      { id: 9, nome: 'Setembro' },
      { id: 10, nome: 'Outubro' },
      { id: 11, nome: 'Novembro' },
      { id: 12, nome: 'Dezembro' }
    ];
    this.filtro.mes = (new Date()).getMonth() + 1;
  }

  obterUnidadesDestino() {
    this.loading = true;
    this.apiService.Get('Unidades?ativo=true&gerente=true').then(
      result => {
        this.listaUnidadesDestino = result;/*console.log(this.listaUnidadesDestino);*/
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterSetoresDestino(unidade_destino_id) {
    this.listaSetoresDestino = null;
    this.listaCargosDestino = null;
    this.filtro.setor_destino = null;
    this.filtro.cargo_destino = null;
    if (unidade_destino_id == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get('Unidades/' + unidade_destino_id + '/Setores?ativo=true&gerente=true').then(
      result => {
        this.listaSetoresDestino = result;
        this.filtro.setor_destino = 0;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  obterCargosDestino(setor_destino_uuid) {
    this.listaCargosDestino = null;
    this.filtro.cargo_destino = null;
    if (setor_destino_uuid == 0) {
      return null;
    }
    this.loading = true;
    this.apiService.Get('Setor/' + setor_destino_uuid + '/Cargos?ativo=true&gerente=true').then(
      result => {
        this.listaCargosDestino = result;
        this.loading = false;
        this.filtro.cargo_destino = 0;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(['/login']);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterFuncionariosDestino(setor_destinoUuid) {
    this.listaFuncionariosDestino = null;
    this.filtro.funcionario_destino = null;
    this.loading = true;
    this.apiService.Get("Funcionarios?setor=" + setor_destinoUuid).then(
      result => {
        this.listaFuncionariosDestino = [];
        for (var x = 0; x < result['length']; x++){
          if (result[x]['uuid'] != this.listaReq.colaborador.funcionario_uuid){
            if (result[x]['ativo']){
              this.listaFuncionariosDestino.push(result[x]);
            }  
          }
        }
        //console.log(this.listaFuncionariosDestino);
        this.filtro.funcionario_destino = 0;
        this.loading = false;
      },
      err => {
        console.log(err);
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }      
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }
  
  funcionarioDestinoSelecionado(func, cargo, event) {
    this.funcio = func;
    this.vinc = cargo;
    this.evento = event;

    if (event) {
      const dados = { 
        "funcionario_uuid": func.uuid, 
        "vinculo_uuid": cargo.vinculo_uuid, 
        "cargo_uuid": cargo.cargo_uuid,
        "checked":true
      };
      this.funcionarios_dados.push(dados);
      for (let f of this.listaFuncionariosDestino){
        if (f.uuid === func.uuid){
          for (let c of f.cargos){
            if(c.cargo_uuid === cargo.cargo_uuid){
              if(!c.checked){
                c.checked=true;
                var j = this.listaFuncionariosDestino.findIndex(x => x.uuid === f.uuid);
              }
            }
          }
        }
      }
    } else {
      let retorno =  this.funcionarios_dados.findIndex(x => x.uuid === func.uuid);
      this.funcionarios_dados.splice(retorno, 1);
      for (let f of this.listaFuncionariosDestino){
        if (f.uuid === func.uuid){
          for (let c of f.cargos){
            if(c.cargo_uuid === cargo.cargo_uuid){
              if(c.checked){
                c.checked=false;
                var j = this.listaFuncionariosDestino.findIndex(x => x.uuid === f.uuid);
              }
            }
          }
        }
      }
    }     
  }

  selecionarTodosFuncionario(event) {
    this.funcionarios_dados = []; 
    if (event) {
      for (let f of this.listaFuncionariosDestino) {
        for (let cargo of f.cargos) { 
          this.funcionarioDestinoSelecionado(f, cargo, true); 
        }
      } return;
    }
  }

  enviarDados(filtro) {
    this.loading = true;
    // let setor_uuid = this.listaReq.filtro.setor.uuid;
    let setor_uuid = filtro.setor_destino.uuid;
    let escalas = this.listaReq.colaborador.escalas.filter(escala => escala.turno_nome).map(escala => ({ uuid: escala.escala_uuid }));
    let escala = escalas;
    let funcionarios = this.funcionarios_dados;

    this.filtro.unidade_destino = filtro.unidade_destino.uuid;
    this.filtro.setor_destino = filtro.setor_destino.uuid;
    this.listaSetoresDestino = null;
    this.listaCargosDestino = null;
    //this.listaFuncionariosDestino = null;
    this.evento = false;    
    
    this.apiService.Post("Escalas/Copiar", {
      "setor_uuid": setor_uuid,
      "escalas": escala,
      "funcionarios": funcionarios
    }).then(
      retornoSucesso => {
        console.log(retornoSucesso);
        this.loading = false;
        Swal.fire({
          type: 'success',
          title: 'Sucesso',
          text: 'Escala copiada com sucesso'
        }).then(result =>{
          if(result['value']==true){
            this.funcionarios_dados = [];
            this.dialogRef.close();
          }
        });
      },
      error => {
        this.loading = false;
        if(error.error.message){
          Swal.fire({
            type: 'error',
            title: 'Oops',
            text: error.error.message
          })
        }
        if(error.message){
          Swal.fire({
            type: 'error',
            title: 'Oops',
            text: error.message
          })
        }
        Swal.fire({
          type: 'error',
          title: 'Oops',
          text: 'Falha ao copiar a escala'
        })
      }
    ).catch(err => {
      this.loading = false;
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
        }
      }
    );
    
    //this.funcionarioDestinoSelecionado(this.funcio, this.vinc, this.evento);

    let mes = this.dialogRef._containerInstance._config.data.filtro.mes;
    switch (mes) {
      case "Janeiro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 1;
        break;
      }
      case "Fevereiro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 2;
        break;
      }

      case "Março": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 3;
        break;
      }
      case "Abril": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 4 ;
        break;
      }
      case "Maio": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 5 ;
        break;
      }
      case "Junho": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 6;
        break;
      }
      case "Julho": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 7 ;
        break;
      }
      case "Agosto": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 8;
        break;
      }
      case "Setembro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 9;
        break;
      }
      case "Outubro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 10;
        break;
      }
      case "Novembro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 11;
        break;
      }
      case "Dezembro": {
        this.dialogRef._containerInstance._config.data.filtro.mes = 12;
        break;
      }
      default: {
        break;
      }
    }

  }

}
