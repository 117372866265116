import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import Swal from 'sweetalert2';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";

import { ApiService } from '../../services/api.service';
import { Global } from '../../global';

@Component({
  selector: 'app-configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.css']
})
export class ConfiguracaoComponent implements OnInit {
  loading = false;
  alerta: any = {};
  configuracao: any = [];
  currentUser: any = {};

  constructor(
    private apiService: ApiService,
    public global: Global,
    private router: Router,
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
    //this.onResize();
  }

  ngOnInit() {
    this.obterConfiguracoes();
  }

  @Output() escondeConfig = new EventEmitter<string>();

  fechaConfig = () => {
    this.escondeConfig.emit('true');
  }

  obterConfiguracoes = () => {
    this.loading = true;
    this.apiService.Get("Configuracao").then(
      result => {
        this.alerta = result;
        setTimeout(() => {
          this.loading = false;
          if (this.alerta.length === 0) {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: 'Não há configurações para serem carregadas'
            });
          }
          else {
            // Swal.fire({
            //   type: 'success',
            //   title: 'Sucesso!',
            //   text: 'Configurações obtidas com sucesso!'
            // });
          }
        },50)    
      }
    );
  }

  salvarConfiguracao = (alerta) => {
    this.alerta = alerta;
    this.alerta.ativo = true;

    this.loading = true;

    this.apiService.Post("Configuracao", this.alerta).then(
      result => {
        this.loading = false;
        Swal.fire({
          type: 'success',
          title: 'Sucesso!',
          text: 'Configuração salva com sucesso!'
        });
      },
      err => {
        if (err.status == 401) {
          this.loading = false;
          this.router.navigate(["/login"]);
          return;
        }
        else {
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      }
    );
  }


}
