import { Component, OnInit, NgModule } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';
import { NgxViacepService, Endereco, CEPError } from '@brunoc/ngx-viacep';
import { Global } from '../../../global';
import { ApiService } from '../../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";

import { ModalCadSetorComponent } from '../../../components/modal-cad-setor/modal-cad-setor.component';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-unidade',
  templateUrl: './unidade.component.html',
  styleUrls: ['./unidade.component.css']
})
export class UnidadeComponent implements OnInit {

  novaUnidade: boolean = false;
  unidade: any = {};
  loading = false;
  visualizando: any = false;
  listaUnidades: any = [];
  listaEstados: any = [];
  listaCidades: any = [];
  filtroStatus: boolean = true;

  currentUser: any = {};

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    public viaCEP: NgxViacepService,
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      //this.instituicaoID = this.currentUser.instituicao_logada.instituicao_codigo;
      //this.obtercargos();
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  openDialogCadSetor(unidade): void {
    let dialogRef = this.dialog.open(ModalCadSetorComponent, {
      data: {
        unidade_id: unidade.uuid
      }
    });
  }

  ngOnInit() {
    this.obterListaEstados();
    this.obterUnidades();
  }

  add() {
    this.novaUnidade = true;
    this.unidade = {};
    this.unidade.ativo = true;
  }

  back() {
    this.obterUnidades();
    this.novaUnidade = false;
    this.unidade = {};
  }

  removeUnidades(usuario) {
    var index = this.getIndex(usuario);
    if (index != null) {
      this.listaUnidades.splice(index, 1);
    }
  }

  getIndex(dependencia) {
    for (var aux in this.listaUnidades) {
      if (this.listaUnidades[aux] == dependencia) {
        return aux;
      }
    }
    return null;
  }

  valida(unidade) {
    if (unidade.ativo == null) {
      unidade.ativo = false;
    }
    if (unidade.nome == null || unidade.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome fantasia!'
      });
      return false;
    }

    if (unidade.municipio_uuid == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o municipio!'
      });
      return false;
    }
    if (unidade.estado_uuid == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o Estado!'
      });
      return false;
    }
    if ((unidade.telefone1_ddd != null && unidade.telefone1_ddd != "") && (unidade.telefone1_ddd < 10 || unidade.telefone1_ddd > 99)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'DDD Inválido do telefone!'
      });
      return false;
    }

    if ((unidade.telefone2_ddd != null && unidade.telefone2_ddd != "") && (unidade.telefone2_ddd < 10 || unidade.telefone2_ddd > 99)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'DDD Inválido do celular!'
      });
      return false;
    }
    return true;
  }

  edit(unidade) {
    this.novaUnidade = true;
    this.unidade = unidade;
    this.obterListaCidades2(unidade.estado_uuid, unidade.municipio_nome);
    this.visualizando = false;
  }

  view(unidade) {
    this.edit(unidade);
    this.visualizando = true;
  }
       
  obterUnidades() {
    this.loading = true;
    this.apiService.Get("Unidades").then(
      result => {
        this.listaUnidades = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }


  desativar(usuario) {
    this.loading = true;
    this.apiService.desativar("Unidades", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterUnidades();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  ativar(usuario) {
    this.loading = true;
    this.apiService.ativar("Unidades", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterUnidades();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  //BUCANDO CEP
  pesquisacep(valor) {
    //Nova variável "cep" somente com dígitos.
    var cep = valor.replace(/\D/g, '');
    //Verifica se campo cep possui valor informado.
    if (cep != "") {
      //Expressão regular para validar o CEP.
      var validacep = /^[0-9]{8}$/;
      //Valida o formato do CEP.
      if (validacep.test(cep)) {
        this.unidade.estado_uuid = null;
        this.unidade.municipio_uuid = null;
        this.unidade.endereco_bairro = "";
        this.unidade.endereco_rua = "";
        this.viaCEP.buscarPorCep(cep).pipe(
          catchError((error: CEPError) => {
            Swal.fire('Oops', 'CEP não encontrado', 'warning')
            return this.listaCidades = [];
          })
        ).subscribe(
          result => {
            this.meu_callback(result);
          },
          error => {
            Swal.fire('Oops', 'CEP não encontrado', 'warning')
          }
        );
      }
      else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'CEP Inválido!'
        });
      }
    }
  }

  meu_callback(conteudo) {

    if (!("erro" in conteudo)) {
      //Atualiza os campos com os valores.
      this.unidade.estado_uuid = this.getIdEstado(conteudo.uf);
      this.obterListaCidades2(this.unidade.estado_uuid, conteudo.localidade);

      this.unidade.endereco_bairro = conteudo.bairro;
      this.unidade.endereco_rua = conteudo.logradouro;
    }
    else {

    }
  }

  obterListaCidades(estado_id) {
    if (estado_id != null) {
      this.loading = true;
      this.apiService.Get("Estados/" + estado_id + "/Municipios").then(
        result => {
          this.listaCidades = result;
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
    }
    else {
      this.unidade.municipio_residencia_id = null;
      this.listaCidades = [];
    }
  }

  obterListaCidades2(estado_id, cidade_nome) {
    if (estado_id != null) {
      this.loading = true;
      this.apiService.Get("Estados/" + estado_id + "/Municipios").then(
        result => {
          this.listaCidades = result;
          this.unidade.municipio_uuid = this.getIdCidade(cidade_nome);
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
    }
    else {
      this.unidade.municipio_uuid = null;
      this.listaCidades = [];
    }
  }

  obterListaEstados() {
    this.loading = true;
    this.apiService.Get("Estados").then(
      result => {
        this.listaEstados = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  getIdEstado(estado_sigla) {
    for (var estado in this.listaEstados) {
      if (this.listaEstados[estado].sigla == estado_sigla) {
        return this.listaEstados[estado].uuid;
      }
    }
  }

  getIdCidade(cidade_nome) {
    for (var cidade in this.listaCidades) {
      if (this.listaCidades[cidade].nome == cidade_nome.toUpperCase()) {
        return this.listaCidades[cidade].uuid;
      }
    }
  }

  submit() {
    if (this.valida(this.unidade)) {
      this.unidade.endereco_cep = this.unidade.endereco_cep.replace(/\D/g, '');
      this.loading = true;
      //this.unidade = this.prepararSubmit(this.unidade);
      if (this.unidade.uuid != null) {
        ///this.global.alunoId = this.alunoID;
        this.apiService.Put("Unidades", this.unidade).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Unidade salva com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      } else {
        this.apiService.Post("Unidades", this.unidade).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Unidade salva com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      }
    }
    else {
      return;
    }
  }

  obterUnidade(unidade) {
    this.loading = true;
    this.apiService.GetOne("Unidades", unidade.uuid).then(
      result => {
        this.unidade = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  filtrarListaUnidades(lista_unidades) {
    if(this.listaUnidades.length == 0 || this.filtroStatus === undefined){
      return this.listaUnidades;
    }

    return lista_unidades.filter((unidade) => {
      if(unidade.ativo == this.filtroStatus){
        return true
      }
      return false
    });
  }

}
