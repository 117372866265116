import { Component, OnInit, NgModule } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';
import { Global } from '../../../global';
import { ApiService } from '../../../services/api.service';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";
import { ModalTrocaSenhaComponent } from '../../../components/modal-troca-senha/modal-troca-senha.component';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  novoUsuario: boolean = false;
  usuario: any = {};
  loading = false;
  visualizando: any = false;
  listaUsuario: any = [];
  perfis: any = [];
  listaUnidades: any = [];
  currentUser: any = {};
  unidadesLista: any = [];
  unidadeId: any = 0;
  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterUsuarios();
    this.obterPerfis();
  }


  obterUnidades() {
    this.apiService.Get("Unidades?ativo=true&cadastro=true").then(
      result => {
        this.listaUnidades = result;
        this.listaUnidades.unshift({
          "uuid": null,
          "nome": "Selecionar",
        });
        this.unidadeId = null;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }


  addUnidadeUsuario(unidade_id) {

    if (unidade_id) {
      var lista = this.listaUnidades.filter(x => x.uuid == unidade_id);
      if (lista.length > 0) {

        if (this.unidadesLista.length == 0) {
          this.unidadesLista.push(lista[0]);
          this.unidadeId = null;
        } else {
          var verif = this.unidadesLista.filter(x => x.uuid == lista[0].uuid);
          if (verif.length > 0) {
            Swal.fire({
              type: 'error',
              title: 'Alerta',
              text: `Unidade já adicionada`
            });
          } else {
            this.unidadesLista.push(lista[0]);
            this.unidadeId = null;
          }
        }

      } else {
        Swal.fire({
          type: 'error',
          title: 'Alerta',
          text: `Favor selecionar uma unidade`
        });
      }
    }


  }


  removeUnidadeUsuario(unidade_uuid) {
    if (this.unidadesLista.length > 0) {
      for (var i = 0; i < this.unidadesLista.length; i++)
        if (this.unidadesLista[i].uuid === unidade_uuid) {
          this.unidadesLista.splice(i, 1);
          break;
        }
    }
  }


  add() {
    this.novoUsuario = true;
    this.usuario = {};
    this.usuario.ativo = true;
    this.unidadesLista =[];
    this.obterUnidades();
  }

  back() {
    this.obterUsuarios();
    this.novoUsuario = false;
    this.usuario = {};
  }

  AlunoChange(aluno) {
    this.usuario.aluno_codigo = aluno.value.uuid;
  }

  valida(usuario) {
    if (usuario.ativo == null) {
      usuario.ativo = false;
    }
    if (usuario.nome == null || usuario.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome!'
      });
      return false;
    }
    if (usuario.sobrenome == null || usuario.sobrenome == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o sobrenome!'
      });
      return false;
    }
    if ((usuario.telefone_ddd != null && usuario.telefone_ddd != "") && (usuario.telefone_ddd < 10 || usuario.telefone_ddd > 99)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'DDD Inválido do telefone!'
      });
      return false;
    }
    if (usuario.cpf == null || usuario.cpf == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o CPF!'
      });
      return false;
    }
    if (usuario.email == null || usuario.email == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o e-mail!'
      });
      return false;
    }
    if (usuario.login == null || usuario.login == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o login!'
      });
      return false;
    }
    if (usuario.perfil_cliente_perfil_uuid == null || usuario.perfil_cliente_perfil_uuid == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o perfil!'
      });
      return false;
    }

    if (this.unidadesLista == null || this.unidadesLista.length == 0) {
      Swal.fire({
        type: 'error',
        title: 'Alerta',
        text: 'Insira uma unidade!'
      });
      return false;
    }
    else {
      usuario.perfil_uuid = usuario.perfil_cliente_perfil_uuid;
    }
    return true;
  }

  edit(usuario) {
    this.unidadesLista =[];
    this.novoUsuario = true;
    this.obterUsuario(usuario);
    //this.usuario = usuario;
    this.visualizando = false;
  }

  view(matricula) {
    this.edit(matricula);
    this.visualizando = true;
  }

  submit() {
    if (this.valida(this.usuario)) {
      this.loading = true;
      if (this.usuario.uuid != null) {
        let unidade = [];
        this.unidadesLista.forEach((item) => {
          unidade.push(item.uuid)
        });

        this.usuario.unidades = [];
        this.usuario.unidades = unidade;

        this.apiService.Put("Usuarios", this.usuario).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Usuário salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      } else {
        if (this.usuario.senha == null || this.usuario.senha == "") {
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: 'Insira a senha!'
          });
          this.loading = false;
          return;
        }
        this.usuario.senha = this.global.md5Normal(this.usuario.senha);

        let unidade = [];
        this.unidadesLista.forEach((item) => {
          unidade.push(item.uuid)
        });

        this.usuario.unidades = [];
        this.usuario.unidades = unidade;


        this.apiService.Post("Usuarios", this.usuario).then(
          result => {
            this.back();
            this.loading = false;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Usuário salvo com sucesso!'
            });
          },
          err => {
            if (err.status == 401) {
              this.router.navigate(["/login"]);
              return;
            }
            else {
              this.loading = false;
              Swal.fire({
                type: 'error',
                title: 'Oops...',
                text: err.error.mensagem
              });
            }
          }
        );
      }
    }
    else {
      return;
    }
  }

  AutoCompleteDisplay(aluno) {
    if (aluno == null) {
      return;
    }
    if (aluno.aluno_nome != null) {
      return aluno ? aluno.aluno_nome : undefined;
    }
    return aluno ? aluno.nome : undefined;
  }

  obterUsuarios() {
    this.loading = true;
    this.apiService.Get("Usuarios").then(
      result => {
        this.listaUsuario = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  desativar(usuario) {

    this.loading = true;
    this.apiService.desativar("Usuarios", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterUsuarios();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  ativar(usuario) {

    this.loading = true;
    this.apiService.ativar("Usuarios", usuario.uuid)
      .then(
        result => {
          this.loading = false;
          this.obterUsuarios();
        },
        err => {
          this.loading = false;
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            //title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
  }

  obterUsuario(usuario) {
    this.loading = true;
    this.apiService.GetOne("Usuarios", usuario.uuid).then(
      result => {
        this.usuario = result;
        this.obterUnidades();
        this.loading = false;
        this.unidadesLista = this.usuario.unidades;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  formata_CPF(cpf) {
    return this.global.formataCPF(cpf);
  }

  obterPerfis() {
    this.loading = true;
    this.apiService.Get("Perfis?ativo=true").then(
      result => {
        this.perfis = result;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  adicionaPerfilUsuario(perfilUuid) {
    this.usuario.perfil_uuid = perfilUuid;
  }

  openDialog(req): void {
    let dialogRef = this.dialog.open(ModalTrocaSenhaComponent, { data: req });
    dialogRef.afterClosed().subscribe(result => {
      //this.router.navigate(["/login"]);
    });
  }


}
