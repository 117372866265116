import { Component, OnInit } from '@angular/core';
import { Global } from '../../../global';
import Swal from 'sweetalert2';
import { ApiService } from '../../../services/api.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-feriado',
  templateUrl: './feriado.component.html',
  styleUrls: ['./feriado.component.css'],
})
export class FeriadoComponent implements OnInit {
  nome: any;
  data_inicio_: any;
  data_fim_: any;
  loading = false;
  ativo = false;
  dataInicio: any;
  dataFim: any;
  listaFeriados: any = [];
  feriado_ativo_: any;
  feriado: any = {};
  editou = false;
  feriadoId: any
  
  constructor(
    public global: Global,
    private apiService: ApiService,
    private router: Router,
    ) { }
    
    ngOnInit() {
    this.obterFeriados();
  }
  //OBTER TODOS OS FERIADOS CADASTRADOS.  
  obterFeriados() {
    this.loading = true;
    this.apiService.Get("Feriados").then(
      result => {
        this.listaFeriados = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  obterFeriado(feriado) {
    this.loading = true;
    this.apiService.GetOne("Feriados", feriado.uuid).then(
      result => {
        this.feriado = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }

  edit(feriado) {
    this.obterFeriado(feriado);
    this.nome = feriado.nome;
    feriado.inicio = feriado.inicio.replace(/T.*$/, "");
    feriado.fim = feriado.fim.replace(/T.*$/, "");
    let dataInicio = feriado.inicio.split("-");
    this.data_inicio_ = `${dataInicio[2]}-${dataInicio[1]}-${dataInicio[0]}`;
    let dataFim = feriado.fim.split("-");
    this.data_fim_ = `${dataFim[2]}-${dataFim[1]}-${dataFim[0]}`;
    this.feriado_ativo_ = feriado.ativo;
    this.editou = true;
  }

  delete(feriado) {
    this.apiService.Delete("Feriados", feriado.uuid ).then(retornoSucesso => {
      this.obterFeriados();
      Swal.fire({
        type: 'success',
        title: 'Sucesso',
        text: 'Feriado deletado com sucesso'
      });
    }).catch(err => {
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    });

  }

  submit() {
    this.loading = true
    // VALIDAR NOME
    if (this.nome == null || this.nome == "") {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira um nome'
      });
      return false;
    }

  //VALIDAR A DATA 

    if (this.data_inicio_ == null || this.data_inicio_ == "" || this.data_inicio_ == null == undefined) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira uma data Inicial'
      });
      return false;
    }

    if (this.data_fim_ == null || this.data_fim_ == "" || this.data_fim_ == null == undefined) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira uma data Final'
      });
      return false;
    }

    let dataInicio = this.data_inicio_.split("/");
    let novaData = `${dataInicio[2]}-${dataInicio[1]}-${dataInicio[0]}`;
    if (isNaN(new Date(novaData).getTime())) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira uma data Inicial válida'
      });
      return false;
    } 

    let dataFim = this.data_fim_.split("/");
    let novaData2 = `${dataFim[2]}-${dataFim[1]}-${dataFim[0]}`;
    if (isNaN(new Date(novaData2).getTime())) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Insira uma data Fim válida'
      });
      return false;
    }

    if (new Date(novaData).getTime() > new Date(novaData2).getTime()) {
      Swal.fire({
        type: 'error',
        title: 'oops...',
        text: 'Data Inical maior que fim'
      });
      return false;
    }

    if (this.feriado_ativo_ == true) {
      this.ativo = true;
    }
    else { this.ativo = false}


    if (this.editou == true) {

      this.apiService.Put("Feriados", {
        "uuid": this.feriado.uuid,
        "ativo": this.ativo,
        "nome": this.nome,
        "Inicio": novaData,
        "Fim": novaData2
      }).then(retornoSucesso => {
        this.obterFeriados();
        this.loading = false;
        Swal.fire({
          type: 'success',
          title: 'Sucesso',
          text: 'Feriado editado com sucesso'
        });
      }).catch(err => {
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
        });
      this.editou = false;
      this.nome = "";
      this.data_inicio_ = "";
      this.data_fim_ = "";
      this.feriado_ativo_ = "";
      return;
    }


    //MANDAR DADOS PARA API PARA CADASTRAR
      this.apiService.Post("Feriados", {
        "ativo": this.ativo,
        "nome": this.nome,
        "Inicio": novaData,
        "Fim": novaData2
      }).then(retornoSucesso => {
        this.obterFeriados();
        this.loading = false;
        Swal.fire({
          type: 'success',
          title: 'Sucesso',
          text: 'Feriado cadastrado com sucesso'
        });
      }).catch(err => {
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      });
    this.nome = "";
    this.data_inicio_ = "";
    this.data_fim_ = "";
    this.feriado_ativo_ = "";

  }
}
