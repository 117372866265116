import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { Global } from '../../global';
import { Router } from '@angular/router';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-pontos-reprovados',
  templateUrl: './pontos-reprovados.component.html',
  styleUrls: ['./pontos-reprovados.component.css']
})
export class PontosReprovadosComponent implements OnInit {
  loading = false;

  filtro: any = [];
  listaClientes: any = [];
  listaUnidades: any = [];
  listaPontos: any = [];
  hoje: any = '';

  //paginação
  cabecalho: any = {};
  listaPaginas: any = [];
  indice_selecionado: any = 0;
  pesquisa: any = '';
  showPaginas: boolean = false;
  indice = 1;

  constructor(
    private apiService: ApiService,
    public global: Global,
    private router: Router
  ) { }

  ngOnInit() {
    var today = new Date();
    this.hoje = 
    (('0'+today.getDate().toString()).slice(-2))+'/'+
    ((('0'+(today.getMonth()+1)).toString()).slice(-2))+'/'+today.getFullYear();
    this.getUnidades();
    console.log(this.hoje)
  }

  getUnidades = () => {
    this.loading = true;
    this.filtro.unidade = '';
    this.apiService.Get(`Unidades?ativo=true`).then(result =>{
      this.listaUnidades = result;
      this.filtro.data_inicio = this.hoje;
      this.filtro.data_fim = this.hoje;
      this.loading = false;
    })
  }

  dateMask = (data) => {
    var id = data.srcElement.id;
    var input = this.filtro[id];
    this.filtro[id] = input.replace(/\D/g, "");
    this.filtro[id] = this.filtro[id].replace(/(\d{2})(\d)/, "$1/$2");
    this.filtro[id] = this.filtro[id].replace(/(\d{2})(\d{1})/, "$1/$2");
    this.filtro[id] = this.filtro[id].replace(/(\d{2})(\d{4})$/, "$1/$2");
  }

  getPontos = () => {
    this.loading = true;    
    if (this.filtro.unidade == '' || this.filtro.unidade == null){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Selecione uma unidade'
      });
      return
    }
    if (this.filtro.data_inicio == '' || this.filtro.data_inicio == null){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'É preciso informar uma data de início.'
      });
      return
    }
    if (this.filtro.data_fim == '' || this.filtro.data_fim == null){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'É preciso informar uma data de fim.'
      });
      return
    }
    var dia_inicio = this.filtro.data_inicio.split('/')[0];
    var mes_inicio = this.filtro.data_inicio.split('/')[1];
    var ano_inicio = this.filtro.data_inicio.split('/')[2];
    if (dia_inicio < 1 || dia_inicio > 31 || mes_inicio < 1 || mes_inicio > 12){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma data de início válida'
      });
      return
    }    
    var dia_fim = this.filtro.data_fim.split('/')[0];
    var mes_fim = this.filtro.data_fim.split('/')[1];
    var ano_fim = this.filtro.data_fim.split('/')[2];
    if (dia_fim < 1 || dia_fim > 31 || mes_fim < 1 || mes_fim > 12){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma data de fim válida'
      });
      return
    } 
    if (this.filtro.data_fim > this.hoje)   {
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'A data de fim não pode ser maior que a data de hoje'
      });
      return
    }
    var txt = `unidade_uuid=${this.filtro.unidade}&data_inicio=${ano_inicio}-${mes_inicio}-${dia_inicio}&data_fim=${ano_fim}-${mes_fim}-${dia_fim}&pagina=1`;
    this.listaPontos = [];
    this.apiService.Get(`Pontos/Reprovado?${txt}`).then(result => {
      
      this.listaPontos = result['conteudo'];
      this.loading = false;
      console.log(this.listaPontos);

      for (let item of this.listaPontos){
        var data = item.presenca_em.split("T")[0];
        var hora = item.presenca_em.split("T")[1]
        data = data.split("-")[2]+'/'+data.split("-")[1]+'/'+data.split("-")[0]
        hora = hora.split(":")[0]+':'+hora.split(":")[1]
        item.reprovado_em = data+' '+hora;
      }

      if (result['total_paginas'] > 1){
        this.showPaginas = true;
        this.indice_selecionado = result['pagina_atual'];
        this.cabecalho.total_paginas = result['total_paginas'];
      }
    },
    err => {
      if (err.status == 401) {
        this.router.navigate(["/login"]);
        return;
      }
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: err.error.mensagem
      });
    })
  }

  //paginação  
  primeira(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  anterior(pesquisa) {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  atual(indice, pesquisa) {
    if (this.indice_selecionado != indice) {
      this.indice_selecionado = indice;
      this.outraPagina(this.indice_selecionado);
    } else {
      this.indice_selecionado = indice;
      this.outraPagina(this.indice_selecionado);
    }
  }

  proxima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.outraPagina(this.indice_selecionado);
    }
  }

  ultima(pesquisa) {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
      this.outraPagina(this.indice_selecionado);
    }
  }

  outraPagina = (pagina) => {
    this.loading = true;
    if (this.filtro.data_inicio == '' || this.filtro.data_inicio == null ||
        this.filtro.data_fim == '' || this.filtro.data_fim == null){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'As datas informadas não são válidas.'
      });
      return
    }
    var dia_inicio = this.filtro.data_inicio.split('/')[0];
    var mes_inicio = this.filtro.data_inicio.split('/')[1];
    var ano_inicio = this.filtro.data_inicio.split('/')[2];
    if (dia_inicio < 1 || dia_inicio > 31 || mes_inicio < 1 || mes_inicio > 12){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma data de início válida'
      });
      return
    }    
    var dia_fim = this.filtro.data_fim.split('/')[0];
    var mes_fim = this.filtro.data_fim.split('/')[1];
    var ano_fim = this.filtro.data_fim.split('/')[2];
    if (dia_fim < 1 || dia_fim > 31 || mes_fim < 1 || mes_fim > 12){
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe uma data de fim válida'
      });
      return
    } 
    if (this.filtro.data_fim > this.hoje)   {
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'A data de fim não pode ser maior que a data de hoje'
      });
      return
    }
    var txt = `unidade_uuid=${this.filtro.unidade}&data_inicio=${ano_inicio}-${mes_inicio}-${dia_inicio}&data_fim=${ano_fim}-${mes_fim}-${dia_fim}&pagina=${pagina}`;
    this.listaPontos = [];
    this.apiService.Get(`Pontos/Reprovado?${txt}`).then(result => {
      
      this.listaPontos = result['conteudo'];
      this.loading = false;

      if (result['total_paginas'] > 1){
        this.showPaginas = true;
        this.indice_selecionado = result['pagina_atual'];
        this.cabecalho.total_paginas = result['total_paginas'];
      }
      this.loading = false;
    },
    err => {
      if (err.status == 401) {
        this.router.navigate(["/login"]);
        return;
      }
      this.loading = false;
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: err.error.mensagem
      });
    })
  }
  //fim paginação
}
