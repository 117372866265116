import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { UsuarioComponent } from './pages/cadastros/usuario/usuario.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { UnidadeComponent } from './pages/cadastros/unidade/unidade.component';
import { FuncionarioComponent } from './pages/cadastros/funcionario/funcionario.component';
import { CargoComponent } from './pages/cadastros/cargo/cargo.component';
import { TurnoComponent } from './pages/cadastros/turno/turno.component';
import { VinculoComponent } from './pages/cadastros/vinculo/vinculo.component';
import { EscalaComponent } from './pages/escala/escala.component';
import { PermissoesComponent } from './pages/permissoes/permissoes.component';
import { DiarioFrequenciaComponent } from './pages/diario-frequencia/diario-frequencia.component';
import { EscalaAcompanhamentoComponent } from './pages/escala_acompanhamento/escala_acompanhamento.component';
import { MotivoAlteracaoComponent } from './pages/cadastros/motivo-alteracao/motivo-alteracao.component';
import { AlteracaoEscalaComponent } from './pages/alteracao-escala/alteracao-escala.component';
import { EspelhoPontoComponent } from './pages/espelho-ponto/espelho-ponto.component';
import { FeriadoComponent } from './pages/cadastros/feriado/feriado.component';
import { NovoClienteComponent } from './pages/novo-cliente/novo-cliente.component'
import { ExportacaoPontoComponent } from './pages/exportacao-ponto/exportacao-ponto.component';
import { AprovarFotosComponent } from './pages/aprovar-fotos/aprovar-fotos.component';
import { PontosReprovadosComponent } from './pages/pontos-reprovados/pontos-reprovados.component'
import { DashboardRegistrosComponent } from './pages/dashboard-registros/dashboard-registros.component';
import { EditarFuncionarioComponent } from './pages/funcionarios/editar-funcionario/editar-funcionario.component'
import { ListarFuncionariosComponent } from './pages/funcionarios/listar-funcionarios/listar-funcionarios.component'
import { EspelhoPontoConsolidadoComponent } from './pages/espelho-ponto-consolidado/espelho-ponto-consolidado.component';
import { ApontamentoMassaComponent } from './pages/apontamento-massa/apontamento-massa.component';
import { AcompanhamentoPontosComponent } from './pages/acompanhamento-pontos/acompanhamento-pontos.component';
import { TemplateEscalaComponent } from './pages/template-escala/template-escala.component';

export const appRoutes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      { path: 'perfil', component: PerfilComponent },
      {
        path: 'cadastros', children: [
          { path: 'usuario', component: UsuarioComponent },
          { path: 'unidade', component: UnidadeComponent },
          //{ path: 'funcionario', component: FuncionarioComponent },
          { path: 'funcionario', component: ListarFuncionariosComponent },
          { path: 'criar-funcionario', component: EditarFuncionarioComponent },
          { path: 'editar-funcionario/:uuid', component: EditarFuncionarioComponent },
          { path: 'cargo', component: CargoComponent },
          { path: 'turno', component: TurnoComponent },
          { path: 'vinculo', component: VinculoComponent },
          { path: 'motivo-alteracao', component: MotivoAlteracaoComponent },
          { path: 'feriado', component: FeriadoComponent },
        ]
      },
      { path: 'escala', component: EscalaComponent },
      { path: 'escala-acompanhamento', component: EscalaAcompanhamentoComponent },
      { path: 'permissoes', component: PermissoesComponent },
      { path: 'diario-frequencia', component: DiarioFrequenciaComponent },
      { path: 'alteracao-escala', component: AlteracaoEscalaComponent },
      { path: 'espelho-ponto', component: EspelhoPontoComponent },
      { path: 'exportacao-ponto', component: ExportacaoPontoComponent },
      { path: 'pontos-reprovados', component: PontosReprovadosComponent },
      { path: 'dashboard-registros', component: DashboardRegistrosComponent },
      { path: 'espelho-consolidado', component: EspelhoPontoConsolidadoComponent },
      { path: 'apontamento-massa', component: ApontamentoMassaComponent },
      { path: 'acompanhamento-pontos', component: AcompanhamentoPontosComponent },
      { path: 'template-escala', component: TemplateEscalaComponent },
      // { path: 'listar-funcionarios', component: ListarFuncionariosComponent },
      // { path: 'criar-funcionario', component: EditarFuncionarioComponent },
      // { path: 'editar-funcionario/:uuid', component: EditarFuncionarioComponent }
    ]
  },
  // { path: 'erro', component: ErroComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'novo-cliente', component: NovoClienteComponent, pathMatch: 'full' },
  { path: 'aprovar-fotos', component: AprovarFotosComponent, pathMatch: 'full' }
];
