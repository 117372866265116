import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";


@Component({
  selector: 'app-modal-biometria',
  templateUrl: './modal-biometria.component.html',
  styleUrls: ['./modal-biometria.component.css']
})


export class ModalBiometriaComponent implements OnInit {



  currentUser: any = {};
  funcionario_uuid: any;
  funcionario: any = {};

  loading = false;
  imgSrc: any = '../../../../assets/img/default.jpg';
  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
    public dialogRef: MatDialogRef<ModalBiometriaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.funcionario = data;    
      this.loading = true;  
      setTimeout(() => {
        this.imgSrc = this.funcionario.func.foto_perfil_link;
        let img = document.getElementById('imgFuncionario');
        img.onerror = function () {
          document.getElementById('imgFuncionario').setAttribute('src', '../../../../assets/img/default.jpg');
        }
        this.loading = false;
      }, 1000);
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {

  }

  fechaModal() {
    this.dialogRef.close();
  }





}
