import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import { Global } from '../../global';
import * as moment from "moment";

@Component({
  selector: 'app-acompanhamento-pontos',
  templateUrl: './acompanhamento-pontos.component.html',
  styleUrls: ['./acompanhamento-pontos.component.css']
})
export class AcompanhamentoPontosComponent implements OnInit {
  loading: any = false;

  filtro: any = { unidade: 0 };

  listaUnidades: any = [];
  listaSetores: any = [];
  listaCargos: any = [];
  listaFuncionarios: any = [];
  listaPontos: any = [];

  currentUser: any = {};

  //paginação
  cabecalho: any = {};
  indice_selecionado: any = 0;
  showPaginacao: boolean = false;

  imgSrc: any = '../../assets/img/default.jpg';

  constructor(private apiService: ApiService, private router: Router, public global: Global) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    let dia_mes = (new Date()).getDate();
    let dia_atual = ("0"+dia_mes.toString()).slice(-2);
    let mes_atual = ('0'+((new Date()).getMonth() + 1).toString()).slice(-2);
    let ano_atual = (new Date()).getFullYear();
    this.obterUnidades();
    this.filtro.data_inicio = `${dia_atual}/${mes_atual}/${ano_atual}`;
    this.filtro.data_fim = `${dia_atual}/${mes_atual}/${ano_atual}`;
  }

  obterUnidades() {
    this.loading = true;
    this.apiService.Get("Unidades?ativo=true&gerente=true").then(
      (result) => {
        this.listaUnidades = result;
        this.loading = false;
      },
      (err) => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: err.error.mensagem,
        });
      }
    );
  };

  obterSetores(unidade_id) {
    this.listaSetores = null;
    this.listaCargos = null;
    this.listaFuncionarios = null;
    this.filtro.setor = null;
    this.filtro.cargo = null;
    this.filtro.funcionario = null;
    if (unidade_id == 0) {
      return null;
    }
    this.loading = true;
    this.apiService
      .Get("Unidades/" + unidade_id + "/Setores?ativo=true&gerente=true")
      .then(
        (result) => {
          this.listaSetores = result;
          this.filtro.setor = 0;
          this.loading = false;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  };

  obterCargos(setor_uuid) {
    this.listaCargos = null;
    this.listaFuncionarios = null;
    this.filtro.cargo = null;
    this.filtro.funcionario = null;
    if (setor_uuid == 0) {
      return null;
    }
    this.loading = true;
    this.apiService
      .Get("Setor/" + setor_uuid + "/Cargos?ativo=true&gerente=true")
      .then(
        (result) => {
          this.listaCargos = result;
          this.loading = false;
          this.filtro.cargo = 0;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  };

  obterFuncionarios(setorUuid, cargoUuid) {
    this.listaFuncionarios = null;
    this.filtro.funcionario = null;
    this.loading = true;
    if (!cargoUuid) {
      this.loading = false;
      return
    }
    if(this.filtro.data_inicio == null || this.filtro.data_inicio == ""){
      this.loading = false;
      Swal.fire('Erro', 'É preciso informar a data início', 'error')
      return
    }

    let dia_inicio = this.filtro.data_inicio.split('/')[0];
    let mes_inicio = this.filtro.data_inicio.split('/')[1];
    let ano_inicio = this.filtro.data_inicio.split('/')[2];
    if (dia_inicio < 1 || dia_inicio > 31 || mes_inicio < 1 || mes_inicio > 12){
      this.loading = false;
      Swal.fire('Erro', 'Informe uma data de início válida', 'error')
      return
    }   
    
    var txt = `setor=${setorUuid}&cargo=${cargoUuid}&ano=${ano_inicio}&mes=${mes_inicio}`
    this.apiService.Get(`Funcionarios?${txt}`)
      .then((result) => {
        this.listaFuncionarios = result;
        this.filtro.funcionario = 0;
        this.loading = false;
      },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  };

  getFormatterDate(item) {
    return this.global.dateFormater(item);
  }

  getFormatterDateHour(item) {
    return this.global.dateTimeFormater(item);
  }

  formatarParaDataPadrao(data) {
    let data_temp = data.split('/');
    let dataFormatada = `${data_temp[2]}-${data_temp[1]}-${data_temp[0]}`;
    return dataFormatada;
  }

  validarFoto(registro) {
    // if(registro.registro_link.includes('.png') || registro.registro_link.includes('.jpg')){
    if(registro.registro != null && registro.registro != ''){
      return true
    } else {
      return false;
    }
  }

  listarPontos(pagina) {
    this.loading = true;

    let data_hoje = new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate());

    let data_inicio = this.formatarParaDataPadrao(this.filtro.data_inicio)
    let data_fim = this.formatarParaDataPadrao(this.filtro.data_fim);
    
    let dia_inicio = this.filtro.data_inicio.split('/')[0];
    let mes_inicio = this.filtro.data_inicio.split('/')[1];
    let ano_inicio = this.filtro.data_inicio.split('/')[2];
    if (dia_inicio < 1 || dia_inicio > 31 || mes_inicio < 1 || mes_inicio > 12){
      this.loading = false;
      Swal.fire('Erro', 'Informe uma data de início válida', 'error');
      return
    }
    
    var dia_fim = this.filtro.data_fim.split('/')[0];
    var mes_fim = this.filtro.data_fim.split('/')[1];
    var ano_fim = this.filtro.data_fim.split('/')[2];
    if (dia_fim < 1 || dia_fim > 31 || mes_fim < 1 || mes_fim > 12){
      this.loading = false;
      Swal.fire('Erro', 'Informe uma data de fim válida', 'error');
      return
    }

    let data_inicio_validacao = new Date(parseInt(ano_inicio), parseInt(mes_inicio) - 1, parseInt(dia_inicio));
    let data_fim_validacao = new Date(parseInt(ano_fim), parseInt(mes_fim) - 1, parseInt(dia_fim));
    
    if(data_inicio_validacao > data_hoje ){
      this.loading = false;
      Swal.fire('Erro', 'A data de início não pode ser maior que a data de hoje', 'error');
      return
    }

    if(data_fim_validacao > data_hoje ){
      this.loading = false;
      Swal.fire('Erro', 'A data de fim não pode ser maior que a data de hoje', 'error');
      return
    }

    if(data_inicio_validacao > data_fim_validacao){
      this.loading = false;
      Swal.fire('Erro', 'A data de início não pode ser maior que a data de fim', 'error');
      return
    }

    this.apiService.Get(`Pontos?setor_uuid=${this.filtro.setor.uuid}&cargo_uuid=&${this.filtro.cargo.uuid}&funcionario_uuid=${this.filtro.funcionario.uuid}&data_inicio=${data_inicio}&data_fim=${data_fim}&pagina=${pagina}`)
      .then(result => {
        this.listaPontos = result['conteudo'];

        this.indice_selecionado = result['pagina_atual']
        this.cabecalho.total_paginas = result['total_paginas']
        this.cabecalho.quantidade_pagina = result['quantidade_pagina']
        this.cabecalho.quantidade_total = result['quantidade_total']

        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  };

   //paginação  
   primeira() {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = 1;
      this.listarPontos(this.indice_selecionado);
    }
  }

  anterior() {
    if (this.indice_selecionado != 1) {
      this.indice_selecionado = this.indice_selecionado - 1;
        this.listarPontos(this.indice_selecionado);
    }
  }

  proxima() {
    // console.log(this.filtro)
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.indice_selecionado + 1;
      this.listarPontos(this.indice_selecionado);
    }
  }

  ultima() {
    if (this.indice_selecionado != this.cabecalho.total_paginas) {
      this.indice_selecionado = this.cabecalho.total_paginas;
        this.listarPontos(this.indice_selecionado);
    }
  }

}
