import { Component, OnInit, NgModule, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';

import { Global } from '../../global';
import { ApiService } from '../../services/api.service';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";


@Component({
  selector: 'app-modal-troca-senha',
  templateUrl: './modal-troca-senha.component.html',
  styleUrls: ['./modal-troca-senha.component.css']
})


export class ModalTrocaSenhaComponent implements OnInit {

  troca: any = {};
  acao_selecionada: any;
  obs: any;

  currentUser: any = {};
  funcionario_uuid: any;
  funcionario: any = {};

  loading = false;
  redefinindo_senha = false;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    
    public dialogRef: MatDialogRef<ModalTrocaSenhaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      this.funcionario_uuid = data.uuid;
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    
  }

  fechaModal() {
    this.dialogRef.close();
  }

  mudar_senha() {
    this.redefinindo_senha = true;
  }

  voltar() {
    this.redefinindo_senha = false;
  }

  dataFormat(data) {
    if (data == "0001-01-01T00:00:00") {
      return "--/--/----";
    }
    else {
      return this.global.dateFormater(data);
    }

  }

  valido() {
    if (this.troca.senha_nova == null || this.troca.senha_nova == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Informe a nova senha'
      });
      return false;
    }
    if (this.troca.repete == null || this.troca.repete == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Confirme sua nova senha'
      });
      return false;
    }
    if (this.troca.senha_nova != this.troca.repete) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Senhas informadas não são iguais. Confirme sua nova senha'
      });
      return false;
    }
    this.troca.senha_nova = this.global.md5Normal(this.troca.senha_nova);
    this.troca.repete = this.global.md5Normal(this.troca.repete);
    return true;
  }

  submit() {
    this.loading = true;
    if (this.valido()) {
      this.troca.uuid = this.funcionario_uuid;
      this.apiService.Patch_body("Usuarios/RedefinirSenha", this.troca).then(
        result => {
          this.global.token_acesso = result;
          Swal.fire({
            type: 'success',
            title: 'Sucesso!',
            text: 'Sua senha foi atualizada com sucesso'
          });
          this.fechaModal();
        },
        err => {
          if (err.status == 200 && err.error.text != null && err.error.text != "") {
            this.global.token_acesso = err.error.text;
            Swal.fire({
              type: 'success',
              title: 'Sucesso!',
              text: 'Sua senha foi atualizada com sucesso'
            });
            this.fechaModal();
          }
          else if (err.status == 401) {
            this.router.navigate(["/login"]);
            this.loading = false;
            return;
          }
          else {
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
          }
          this.troca = {};
          this.loading = false;
        }
      );
    }
    else {
      this.loading = false;
    }
  }

  


}
