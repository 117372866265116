import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Global } from '../../global';
declare var $: any;
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent implements OnInit {

  permissoes: any = [];
  nomeMenu: string = '';
  showSubMenu: boolean = false;
  atividade: boolean = true;

  @Input() toggleMenu: boolean;

  constructor(
    public global: Global,
  ) {
    if (localStorage.getItem("currentUser") != null) {
      if (JSON.parse(localStorage.getItem("currentUser")).permissoes != null)
        this.permissoes = JSON.parse(localStorage.getItem("currentUser")).permissoes;
    }
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.feedback();
  }

  abrirMenu(menuNome) {
    console.log(menuNome)
    if (this.nomeMenu == menuNome)
      this.nomeMenu = '';
    else
      this.nomeMenu = menuNome;
    this.toggleSubMenu();
    //this.feedback()
  }

  
  feedback() {
    this.atividade = !this.atividade;
    console.log(this.atividade)
  }

  toggleSubMenu = () => {
    this.showSubMenu = !this.showSubMenu;
  }

}
