import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { ModalFrequenciaComponent } from "../../components/modal-frequencia/modal-frequencia.component";

import { ReactiveFormsModule, FormControl, FormGroup } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { startWith } from "rxjs/operators/startWith";
import Swal from "sweetalert2";

import { ApiService } from "../../services/api.service";
import { Global } from "../../global";
import { Router } from "@angular/router";
import { saveAs } from "file-saver";

@Component({
  selector: "app-exportacao-ponto",
  templateUrl: "./exportacao-ponto.component.html",
  styleUrls: ["./exportacao-ponto.component.css"],
})
export class ExportacaoPontoComponent implements OnInit {
  loading = false;
  listaUnidades: any = [];
  listaSetores: any = [];
  listaMeses: any = [];
  currentUser: any = {};
  filtro: any = {};

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    public global: Global,
    private router: Router
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterMeses();
    this.obterUnidades();
    this.filtro.ano = new Date().getFullYear();
  }

  obterUnidades = () => {
    this.loading = true;
    this.apiService.Get("Unidades?ativo=true&gerente=true").then(
      (result) => {
        this.listaUnidades = result;
        console.log(this.listaUnidades);
        this.loading = false;
      },
      (err) => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: "error",
          title: "Oops...",
          text: err.error.mensagem,
        });
      }
    );
  };

  obterSetores = (unidade) => {
    this.loading = true;
    this.listaSetores = null;
    if (unidade == 0) {
      return null;
    }
    this.apiService
      .Get("Unidades/" + unidade + "/Setores?ativo=true&gerente=true")
      .then(
        (result) => {
          this.listaSetores = result;
          this.loading = false;
        },
        (err) => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: "error",
            title: "Oops...",
            text: err.error.mensagem,
          });
        }
      );
  };

  obterMeses = () => {
    this.loading = true;
    this.listaMeses = [
      { id: 1, nome: "Janeiro" },
      { id: 2, nome: "Fevereiro" },
      { id: 3, nome: "Março" },
      { id: 4, nome: "Abril" },
      { id: 5, nome: "Maio" },
      { id: 6, nome: "Junho" },
      { id: 7, nome: "Julho" },
      { id: 8, nome: "Agosto" },
      { id: 9, nome: "Setembro" },
      { id: 10, nome: "Outubro" },
      { id: 11, nome: "Novembro" },
      { id: 12, nome: "Dezembro" },
    ];
    this.filtro.mes = new Date().getMonth() + 1;
    this.loading = false;
  };

  gerarRelatorio = () => {
    this.loading = true;
    if (this.filtro.mes == "" || this.filtro.mes == null) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor informar um mês válido para concluir a solicitação",
      });
      return;
    }
    if (this.filtro.ano == "" || this.filtro.ano == null) {
      Swal.fire({
        type: "error",
        title: "Oops...",
        text: "Favor informar um ano válido para concluir a solicitação",
      });
      return;
    }
    if (this.filtro.setor == undefined) {
      this.filtro.setor = "";
    }
    if (this.filtro.unidade == undefined) {
      this.filtro.unidade = "";
    }
    var obj = {
      ano: this.filtro.ano,
      mes: this.filtro.mes,
      setor_uuid: this.filtro.setor,
      unidade_uuid: this.filtro.unidade,
    };
    var url = "";
    if (obj.setor_uuid == "" || obj.unidade_uuid == "") {
      if (obj.setor_uuid == "") {
        url = `Pontos/Exportar/?mes=${obj.mes}&ano=${obj.ano}&unidade_uuid=${obj.unidade_uuid}`;
      }
      if (obj.unidade_uuid == "" && obj.setor_uuid == "") {
        url = `Pontos/Exportar/?mes=${obj.mes}&ano=${obj.ano}`;
      }
      for (var propName in obj) {
        if (obj[propName] === "" || obj[propName] === undefined) {
          delete obj[propName];
        }
      }
    } else {
      url = `Pontos/Exportar/?mes=${obj.mes}&ano=${obj.ano}&unidade_uuid=${obj.unidade_uuid}&setor_uuid=${obj.setor_uuid}`;
    }
    this.apiService.Get(url).then(
      (result) => {
        this.loading = false;
      },
      (err) => {
        if (err.status == 200) {
          console.log(err);
          var nome = err.error.text;
          var partes = nome.split("/");
          var nomeArquivo = partes.at(-1);
          var result = saveAs(err["error"]["text"], `${nomeArquivo}`);
          this.loading = false;
          return;
        }
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          this.loading = false;
          return;
        } else {
          if (err.error.mensagem != undefined) {
            Swal.fire({
              type: "error",
              title: "Oops...",
              text: err.error.mensagem,
            });
            this.loading = false;
            return;
          } else {
            Swal.fire({
              type: "error",
              title: "Oops...",
              text: "Não foi possível concluir a solicitação. Favor verificar os filtros utilizados e tentar novamente",
            });
            this.loading = false;
            return;
          }
        }
      }
    );
  };
}
