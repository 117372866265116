import { Component, OnInit, NgModule } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import Swal from 'sweetalert2';
import { Global } from '../../global';
import { ApiService } from '../../services/api.service';
import { NgxViacepService, Endereco, CEPError } from '@brunoc/ngx-viacep';

import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
  Event as NavigationEvent
} from "@angular/router";
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {

  perfil: any = {};
  loading = false;
  visualizando: any = false;
  listaEstados: any = [];
  listaCidades: any = [];

  currentUser: any = {};

  verPerfil: boolean = true;

  constructor(
    private apiService: ApiService,
    public global: Global,
    public dialog: MatDialog,
    private router: Router,
    public viaCEP: NgxViacepService
  ) {
    try {
      if (localStorage.getItem("currentUser") == null)
        this.router.navigate(["/login"]);
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    } catch (err) {
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    this.obterListaEstados();
    this.obterPerfil();
  }

  valida(perfil) {

    if (perfil.ativo == null) {
      perfil.ativo = false;
    }
    if (perfil.nome_fantasia == null || perfil.nome_fantasia == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o nome fantasia!'
      });
      return false;
    }
    if (perfil.cnpj == null || perfil.cnpj == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o CNPJ!'
      });
      return false;
    }
    if (perfil.razao_social == null || perfil.razao_social == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o razão social!'
      });
      return false;
    }
    if (perfil.endereco_rua == null || perfil.endereco_rua == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira a rua!'
      });
      return false;
    }
    if (perfil.endereco_numero == null || perfil.endereco_numero <= 0) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o numero!'
      });
      return false;
    }
    if (perfil.endereco_bairro == null || perfil.endereco_bairro == "") {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o bairro!'
      });
      return false;
    }
    //if (perfil.endereco_complemento == null || perfil.endereco_complemento == "") {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira o complemento!'
    //  });
    //  return false;
    //}
    if (perfil.municipio_uuid == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o municipio!'
      });
      return false;
    }
    if (perfil.estado_uuid == null) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Insira o Estado!'
      });
      return false;
    }
    if ((perfil.telefone1_ddd != null && perfil.telefone1_ddd != "") && (perfil.telefone1_ddd < 10 || perfil.telefone1_ddd > 99)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'DDD Inválido do telefone!'
      });
      return false;
    }
    //if (perfil.telefone1_ddd == null || perfil.telefone1_ddd <= 0) {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira o ddd do telefone!'
    //  });
    //  return false;
    //}
    //if (perfil.telefone1_numero == null || perfil.telefone1_numero <= 0) {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira o telefone!'
    //  });
    //  return false;
    //}
    if ((perfil.telefone2_ddd != null && perfil.telefone2_ddd != "") && (perfil.telefone2_ddd < 10 || perfil.telefone2_ddd > 99)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'DDD Inválido do celular!'
      });
      return false;
    }
    //if (perfil.telefone2_ddd == null || perfil.telefone1_ddd <= 0) {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira o ddd do celular!'
    //  });
    //  return false;
    //}
    //if (perfil.telefone2_numero == null || perfil.telefone2_numero <= 0) {
    //  Swal.fire({
    //    type: 'error',
    //    title: 'Oops...',
    //    text: 'Insira o celular!'
    //  });
    //  return false;
    //}

    if ((perfil.inicio_competencia == null || perfil.inicio_competencia == "") && (perfil.fim_competencia == null || perfil.fim_competencia == "")) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Favor informar a competência!'
      });
      return false;
    } else if ((parseInt(perfil.inicio_competencia) < 1 && parseInt(perfil.inicio_competencia) > 31) && (parseInt(perfil.fim_competencia) < 1 && parseInt(perfil.fim_competencia) > 31)) {
      Swal.fire({
        type: 'error',
        title: 'Oops...',
        text: 'Competência inválida !'
      });
      return false;
    }

    return true;
  }

  submit() {

    if (this.valida(this.perfil)) {
      this.loading = true;
      this.apiService.PutCliente("Clientes", this.perfil).then(
        result => {
          this.perfil = result;
          this.loading = false;
          Swal.fire({
            type: 'success',
            title: 'Sucesso!',
            text: 'Perfil salvo com sucesso!'
          });
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          else {
            this.loading = false;
            Swal.fire({
              type: 'error',
              title: 'Oops...',
              text: err.error.mensagem
            });
          }
        }
      );

    }
    else {
      return;
    }
  }

  obterPerfil() {
    this.loading = true;
    this.apiService.Get("Clientes").then(
      result => {
        this.perfil = result;
        this.obterListaCidades2(this.perfil.estado_uuid, this.perfil.municipio_nome);
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );

  }




  //BUCANDO CEP
  pesquisacep(valor) {
    //Nova variável "cep" somente com dígitos.
    var cep = valor.replace(/\D/g, '');

    //Verifica se campo cep possui valor informado.
    if (cep != "") {

      //Expressão regular para validar o CEP.
      var validacep = /^[0-9]{8}$/;

      //Valida o formato do CEP.
      if (validacep.test(cep)) {

        //Preenche os campos com "..." enquanto consulta webservice.
        this.perfil.estado_uuid = null;
        this.perfil.municipio_uuid = null;
        this.perfil.endereco_bairro = "";
        this.perfil.endereco_rua = "";
        this.viaCEP
          .buscarPorCep(cep)
          .pipe(
            catchError((error: CEPError) => {
              return this.listaCidades = [];
            })
          )
          .subscribe((endereco: Endereco) => {
            this.meu_callback(endereco);
          });

        // this.viaCEP.buscarPorCep(cep).then((endereco: Endereco) => {
        //   // Endereço retornado :)
        //   this.meu_callback(endereco);
        // }).catch((error: ErroCep) => {
        //   this.listaCidades = [];
        //   // Alguma coisa deu errado :/
        // });
      }
      else {
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: 'CEP Inválido!'
        });
      }
    }
  }

  meu_callback(conteudo) {

    if (!("erro" in conteudo)) {
      //Atualiza os campos com os valores.
      this.perfil.estado_uuid = this.getIdEstado(conteudo.uf);
      this.obterListaCidades2(this.perfil.estado_uuid, conteudo.localidade);

      this.perfil.endereco_bairro = conteudo.bairro;
      this.perfil.endereco_rua = conteudo.logradouro;
    }
    else {
      //CEP não Encontrado.
      //this.limpa_formulário_cep();
      //alert("CEP não encontrado.");
    }
  }

  obterListaCidades(estado_id) {
    if (estado_id != null) {
      this.loading = true;
      this.apiService.Get("Estados/" + estado_id + "/Municipios").then(
        result => {
          this.listaCidades = result;
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
    }
    else {
      this.perfil.municipio_uuid = null;
      this.listaCidades = [];
    }
  }

  obterListaCidades2(estado_id, cidade_nome) {
    if (estado_id != null) {
      this.loading = true;
      this.apiService.Get("Estados/" + estado_id + "/Municipios").then(
        result => {
          this.listaCidades = result;
          this.perfil.municipio_uuid = this.getIdCidade(cidade_nome);
          this.loading = false;
        },
        err => {
          if (err.status == 401) {
            this.router.navigate(["/login"]);
            return;
          }
          this.loading = false;
          Swal.fire({
            type: 'error',
            title: 'Oops...',
            text: err.error.mensagem
          });
        }
      );
    }
    else {
      this.perfil.municipio_uuid = null;
      this.listaCidades = [];
    }
  }


  obterListaEstados() {
    this.loading = true;
    this.apiService.Get("Estados").then(
      result => {
        this.listaEstados = result;
        this.loading = false;
      },
      err => {
        if (err.status == 401) {
          this.router.navigate(["/login"]);
          return;
        }
        this.loading = false;
        Swal.fire({
          type: 'error',
          title: 'Oops...',
          text: err.error.mensagem
        });
      }
    );
  }

  getIdEstado(estado_sigla) {
    for (var estado in this.listaEstados) {
      if (this.listaEstados[estado].sigla == estado_sigla) {
        return this.listaEstados[estado].uuid;
      }
    }
  }

  getIdCidade(cidade_nome) {
    for (var cidade in this.listaCidades) {
      if (this.listaCidades[cidade].nome == cidade_nome.toUpperCase()) {
        return this.listaCidades[cidade].uuid;
      }
    }
  }

  abrirConfigAlertas = () => {
    this.verPerfil = !this.verPerfil;
  }

  retornaPerfil = () => {
    this.verPerfil = false;
  }

}
